import { useContext, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { ActionSetContentEntity, ActionSetPreplannedPractice, ActionShowPracticeProgram, OperationMode, PreplannedPractice, TeamplanAgeGroup, TeamplanDuration, TeamplanSkillLevel, TypeContext } from "../misc/Types";
import { DataGrid, GridActionsColDef, GridColDef, GridEventListener, } from "@mui/x-data-grid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BACKEND, ICB_USER_ID } from "../misc/Constants";
import { deleteActionForDataGrid, getActionSetConfirm, getDD } from "../misc/Functions";
import AddBUttonAboveLists from "./AddButtonAboveList";

export default function PreplannedPractices() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('TeamplanPractices');
    const navigate = useNavigate();
    const theme = useTheme();

    const [disabled, setDisabled] = useState(false)

    const options = Object.keys(TeamplanAgeGroup).map(x => t('EnumKey' + x))
    console.log(options)

    // Data columns
    const columns: (GridColDef | GridActionsColDef)[] = [
        { field: 'id', headerName: '', maxWidth: 10, sortable: false, },
        { field: 'note', headerName: t('PreplannedPractices05'), flex: 1, sortable: false },
        { field: 'teamplanAgeGroup', headerName: t('PreplannedPractices06'), flex: 1, sortable: false, type: 'singleSelect', valueOptions: Object.keys(TeamplanAgeGroup).map(x => t('EnumKey' + x)) },
        { field: 'teamplanSkillLevel', headerName: t('PreplannedPractices07'), flex: 1, sortable: false, type: 'singleSelect', valueOptions: Object.keys(TeamplanSkillLevel).map(x => t('EnumKey' + x)) },
        { field: 'teamplanDuration', headerName: t('PreplannedPractices08'), flex: 1, sortable: false, type: 'singleSelect', valueOptions: Object.keys(TeamplanDuration).map(x => t('EnumKey' + x)) },
        deleteActionForDataGrid(handleDelete)
    ]

    const columnsICBUser: (GridColDef | GridActionsColDef)[] = [
        { field: 'numOfExercises', headerName: 'Exercises', maxWidth: 10, sortable: false, },
    ]

    // row clicked. Show DialogDownloadSendBoth so user can work with practice
    const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
        dispatch({ type: 'setOperationMode', operationMode: OperationMode.preplannedPractice });
        dispatch({ type: 'showPracticeProgram', show: true });
        // find preplanned practice based on row id
        const preplannedPractice = state.allPreplannedPractices.find(x => x.id === params.row.id)
        const action: ActionSetPreplannedPractice = {
            type: 'setPreplannedPractice',
            preplannedPractice: {
                ...JSON.parse(preplannedPractice!.attributes.body.value),
                uuid: preplannedPractice?.id
            }
        }
        dispatch(action);
        navigate('/icbpreplannedpractices')
    };

    // Delete preplanned practice
    function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('PreplannedPractices03'), '', () => {
            if (disabled)
                return
            setDisabled(true)
            getDD(state, dispatch, `${BACKEND}/icb-node/delete/${state.allPreplannedPractices.find(x => x.id === id)?.attributes.drupal_internal__nid}`)
                .then((resp) => {
                    if (resp.ok) {
                        // delete the preplanned practice from the global state
                        const action: ActionSetContentEntity = {
                            type: 'setContentEntity',
                            contentType: 'node--preplanned_practice',
                            data: state.allPreplannedPractices.filter(x => x.id !== id),
                            initialLoad: true,
                        }
                        dispatch(action)
                    } else {
                        dispatch(getActionSetConfirm(resp.error))
                    }
                })
                .finally(() => {
                    setDisabled(false)
                })
        }))
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('PreplannedPractices00')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* Button to add preplanned practice */}
            <AddBUttonAboveLists
                buttonText={t('PreplannedPractices02')}
                addCode={() => {
                    const actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true }
                    dispatch(actionShowPracticeProgram);
                    navigate('/icbpreplannedpractices')
                }}
                tooltip={t('PreplannedPractices01')}
            />

            <DataGrid
                sx={{ marginTop: 3 }}
                columns={state.user.data.id === ICB_USER_ID ? columns.concat(columnsICBUser) : columns}
                rows={
                    state.allPreplannedPractices
                        // only ICB and clubadmins can create preplanned practices. List only users own preplanned practices
                        .filter(x => x.relationships.uid.data.id === state.user.data.id)
                        .map(x => {
                            const preplannedPracticeParsed: PreplannedPractice = JSON.parse(x.attributes.body.value)
                            return {
                                note: preplannedPracticeParsed.note,
                                teamplanAgeGroup: t('EnumKey' + preplannedPracticeParsed.teamplanAgeGroup),
                                teamplanSkillLevel: t('EnumKey' + preplannedPracticeParsed.teamplanSkillLevel),
                                teamplanDuration: t('EnumKey' + preplannedPracticeParsed.teamplanDuration),
                                selectedExercises: preplannedPracticeParsed.selectedExercises,
                                id: x.id, // uuid for preplaned practice 
                                numOfExercises: preplannedPracticeParsed.selectedExercises.length
                            }
                        })
                }
                columnVisibilityModel={{ id: false }}
                // disableColumnMenu
                onRowClick={handleRowClick}
                rowHeight={30}
            />
        </Box >
    )
}