import { PlayDetails } from "./DialogPlayBuilder";
import { Fragment, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import DialogPlayAnimation from "./DialogPlayAnimation";
import { DrupalEntity } from "../misc/Types";
import { courtDimensions, renderFrameSVG } from "../misc/Functions_Board_Graphics";

interface PropsPlayIllustrate {
    play: DrupalEntity;
    playDetails: PlayDetails;
}

export default function PlayIllustrate(props: PropsPlayIllustrate) {
    log.debug('PlayIllustrate');
    const { t } = useTranslation();

    const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
    const [openDialogPlayAnimation, setOpenDialogPlayAnimation] = useState(false);
    const { width: courtWidth, height: courtHeight } = courtDimensions[props.playDetails.courtType];

    const handlePrev = () => {
        log.debug('handlePrev: ', Math.max(currentFrameIndex - 1, 0));
        setCurrentFrameIndex((prev) => Math.max(prev - 1, 0));
    };

    const handleNext = () => {
        log.debug('handleNext: ', Math.min(currentFrameIndex + 1, props.playDetails.frames.length - 1));
        setCurrentFrameIndex((prev) => Math.min(prev + 1, props.playDetails.frames.length - 1));
    };

    const currentFrame = props.playDetails.frames[currentFrameIndex];

    return (
        <Fragment>
            <div style={{ position: 'relative' }}>
                <svg
                    viewBox={`0 0 ${courtWidth} ${courtHeight}`}
                    style={{ width: '100%', height: '100%', display: 'block', borderRadius: 6 }}
                    onClick={() => setOpenDialogPlayAnimation(true)}
                >
                    {renderFrameSVG(
                        currentFrame,
                        props.playDetails.courtType,
                        props.play.attributes.drupal_internal__nid,
                        {
                            playerFontSize: undefined, // Use defaults (20 for defenders, 23 for others)
                            playerBallRadius: 15,
                            lineStrokeWidth: 2,
                            lineMarkerSize: 25,
                            coneScale: 1.5,
                            courtRenderMode: 'woodBackground', // Use React components
                        }
                    ).props.children}
                </svg>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ position: "relative", paddingTop: 0.5 }}
                >
                    <IconButton
                        onClick={handlePrev}
                        disabled={currentFrameIndex === 0}
                        sx={{
                            marginRight: 1,
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                        }}
                    >
                        <ArrowBackIos style={{ fontSize: 12 }} />
                    </IconButton>

                    <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1 }}>
                        {t('PlayCard00')} {currentFrameIndex + 1} {t('PlayCard01')} {props.playDetails.frames.length}
                    </Typography>

                    <IconButton
                        onClick={handleNext}
                        disabled={currentFrameIndex === props.playDetails.frames.length - 1}
                        sx={{
                            marginLeft: 1,
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                        }}
                    >
                        <ArrowForwardIos style={{ fontSize: 12 }} />
                    </IconButton>
                </Box>
            </div>

            <DialogPlayAnimation
                play={props.play}
                isOpen={openDialogPlayAnimation}
                onClose={() => setOpenDialogPlayAnimation(false)}
                frames={props.playDetails.frames}
                courtType={props.playDetails.courtType}
            />
        </Fragment>
    );
}