import { useContext } from 'react';
import { Box, Fab, Badge } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../App';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ActionShowPracticeProgram, OperationMode, TypeContext } from '../misc/Types';
import { Tooltip } from '@mui/material';
import { ROUTESWITHCARDSANDFORM } from '../misc/Constants';

export default function OpenPracticeProgramButton() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    // Función para calcular la distancia desde la barra inferior
    const sxDistanceFromLowBar = () => state.nativeApp ? { xs: 90, sm: 100 } : { xs: 70, sm: 80 };

    // Calcular la duración total de los drills
    const totalDuration = state.curPractice.selectedExercises.reduce((total, exercise) => {
        return total + (exercise.durationMinutes || 0);
    }, 0);

    function getBadgeContent() {
        if (state.operationMode === OperationMode.exercise)
            return `${Math.round(totalDuration)}\u00A0min`
        if (state.curPlaybook.relationships.field_plays)
            return state.curPlaybook.relationships.field_plays.data.length
        return 0
    }

    const backgroundColors: { [key in OperationMode]: string } = {
        [OperationMode.exercise]: '#DD6F20',
        [OperationMode.play]: '#00398F',
        [OperationMode.preplannedPractice]: 'linear-gradient(45deg, #90CAF9 30%, #42A5F5 90%)',
    };

    const hoverBackgroundColors: { [key in OperationMode]: string } = {
        [OperationMode.exercise]: '#DD6F20',
        [OperationMode.play]: '#00398F',
        [OperationMode.preplannedPractice]: 'linear-gradient(45deg, #42A5F5 30%, #90CAF9 90%)',
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: state.portrait ? sxDistanceFromLowBar() : 23,
                right: state.portrait ? { xs: 13, sm: 21 } : 23,
                zIndex: 1100,
            }}>
            <Tooltip title={state.operationMode === OperationMode.play ? t('OpenPracticeProgramButton01') : t('OpenPracticeProgramButton00')} placement="top">
                <Fab
                    aria-label="toggleShowPracticePlanner"
                    sx={{
                        width: state.portrait ? '56px' : '65px',
                        height: state.portrait ? '56px' : '65px',
                        background: backgroundColors[state.operationMode],
                        '&:hover': {
                            background: hoverBackgroundColors[state.operationMode],
                        }
                    }}
                    onClick={() => {
                        if (ROUTESWITHCARDSANDFORM.includes(location.pathname)) {
                            let actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: !state.showPracticeProgram };
                            dispatch(actionShowPracticeProgram);
                        } else {
                            if (state.operationMode === OperationMode.exercise)
                                navigate('/icbdrills');
                            else if (state.operationMode === OperationMode.play)
                                navigate('/icbplays');
                            else
                                navigate('/icbpreplannedpractices');
                        }
                    }}
                >
                    <Badge
                        badgeContent={getBadgeContent()}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: state.operationMode === OperationMode.exercise ? '#fff3e0' : '#e1f5fe',
                                color: state.operationMode === OperationMode.exercise ? '#DD6F20' : '#15468f',
                                border: state.operationMode === OperationMode.exercise ? '1px solid #DD6F20' : '1px solid #15468f',
                                fontWeight: 'bold',
                                transform: state.operationMode === OperationMode.exercise ? 'translateX(24%) translateY(-210%)' : '',
                            }
                        }}>
                        <PendingActionsIcon style={{
                            fontSize: state.portrait ? 25 : 30,
                            color: 'white'
                        }}
                        />
                    </Badge>
                </Fab>
            </Tooltip>
        </Box>
    );
}