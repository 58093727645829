import React, { useState, useRef, useEffect, useContext } from 'react';
import PlayBuilderActionButtons from './PlayBuilderActionButtons';
import DialogPlayAnimation from './DialogPlayAnimation';
import PhasesSectionPlayBuilder from './PhasesSectionPlayBuilder';
import PlayAnimationLinesOrder from './PlayAnimationLinesOrder';
import { Button, Dialog, Grid, IconButton, Typography, Box, Divider, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SnackbarMessages from './SnackbarMessages';
import { Context } from '../App';
import { CRUD, DrupalEntity, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import log from '../misc/Logger';
import { DRAGGINGSTATE, DRUPALENTITYINIT, FRAMEINIT } from '../misc/Constants';
import BasketballHalfCourt from './BasketballHalfCourt';
import BasketballFullCourt from './BasketballFullCourt';
import { courtDimensions, getPlayerLinesInOrder, generatePathD, SvgDefs } from '../misc/Functions_Board_Graphics';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { BsCone } from 'react-icons/bs';
import { getActionSetConfirm, nodeCRUD } from '../misc/Functions';

// Interfaces
export interface Player {
  id: number;
  x: number;
  y: number;
  number: number;
  hasBall: boolean;
  isDefender?: boolean;
  isCoach?: boolean;
  color?: string;
}

export interface Cone {
  id: number;
  x: number;
  y: number;
  color: string;
}

export interface Frame {
  id: number;
  players: Player[];
  lines: Line[];
  cones: Cone[];
  description?: string;
}

export interface Line {
  uniqueId: string;
  id: number;
  fromId: number;
  toId?: number;
  type: 'straight' | 'zigzag' | 'dashed' | 'bar' | 'shot';
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  controlX: number;
  controlY: number;
  midX?: number;
  midY?: number;
  controlX2?: number;
  controlY2?: number;
  order?: number;
  sameMomentAsPrevious?: boolean;
  controlModified?: boolean;
  control2Modified?: boolean;
  color?: string;
}

export interface Play {
  frames: Frame[];
  currentFrameIndex: number;
  dragging: DraggingState;
  selectedPlayerId: number | null;
  isModalOpen: boolean;
  nextPlayerId: number;
  nextLineId: number;
  nextConeId: number; // Add for unique cone IDs
  courtType: 'halfcourt' | 'fullcourt';
}

interface DraggingState {
  isDragging: boolean;
  type: 'player' | 'end' | 'control' | 'control2' | 'mid' | 'cone' | null; // Add 'cone'
  id: number | null;
}

interface PropsDialogPlayBuilder {
  play: DrupalEntity,
  open: boolean;
  onClose: (play: PlayDetails | null) => void;
}

export interface PlayDetails {
  frames: Frame[];
  currentFrameIndex: number;
  dragging: DraggingState;
  selectedPlayerId: number | null;
  selectedLineId: number | null;
  courtType: 'halfcourt' | 'fullcourt';
}

// Main Component
export default function DialogPlayBuilder({ play, open, onClose }: PropsDialogPlayBuilder) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const { t } = useTranslation();
  log.debug('DialogPlayBuilder');

  const [snackbar, setSnackbar] = useState({ open: false, message: '', color: '#00398F', icon: <FeedbackIcon /> });
  const showSnackbar = (msgKey: string) => {
    setSnackbar({ open: true, message: t(msgKey), color: '#00398F', icon: <FeedbackIcon /> });
    setTimeout(() => setSnackbar((s) => ({ ...s, open: false })), 3000);
  };

  const [courtType, setCourtType] = useState<'halfcourt' | 'fullcourt'>('halfcourt');
  const [initialPointerPosition, setInitialPointerPosition] = useState<{ x: number; y: number } | null>(null);
  const { width: courtWidth, height: courtHeight } = courtDimensions[courtType];
  const MAX_SVG_WIDTH = courtType === 'halfcourt' ? 600 : 800;
  const [frames, setFrames] = useState<Frame[]>([FRAMEINIT]);
  const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(0);
  const [dragging, setDragging] = useState<DraggingState>(DRAGGINGSTATE);
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nextLineId, setNextLineId] = useState<number>(1);
  const [nextConeId, setNextConeId] = useState<number>(1);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [selectedLineId, setSelectedLineId] = useState<number | null>(null);
  const [selectedConeId, setSelectedConeId] = useState<number | null>(null);
  const [isDraggingLineEnd, setIsDraggingLineEnd] = useState<boolean>(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const colorOptions = [
    { name: 'Black', value: 'black' },
    { name: 'Blue', value: 'blue' },
    { name: 'Orange', value: '#f57c00' },
    { name: 'Red', value: 'red' },
    { name: 'Green', value: 'green' },
    { name: 'Purple', value: 'purple' },
  ];

  const toggleShape = () => {
    if (selectedLineId === null) return showSnackbar('PlayBuilder04');
    if (isDoubleBezier) {
      convertToSingleBezier();
    } else {
      convertToDoubleBezier();
    }
  };

  // it keeps content within the court boundaries
  const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max));

  // it gets the event coordinates from the mouse or touch event
  const getEventCoordinates = (e: MouseEvent | TouchEvent) => {
    const event = e instanceof MouseEvent ? e : e.touches[0] || e.changedTouches[0];
    return { x: event.clientX, y: event.clientY };
  };

  // it calculates the point on the bezier curve
  const bezierPoint = (t: number, fromX: number, fromY: number, controlX: number, controlY: number, toX: number, toY: number) => {
    const x = (1 - t) ** 2 * fromX + 2 * (1 - t) * t * controlX + t ** 2 * toX;
    const y = (1 - t) ** 2 * fromY + 2 * (1 - t) * t * controlY + t ** 2 * toY;
    return { x, y };
  };

  // it calculates the control point for the bezier curve
  const calculateControlPoint = (
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    pointX: number,
    pointY: number,
    t: number = 0.5
  ) => {
    if (t === 0 || t === 1) return { x: fromX, y: fromY };
    const denom = 2 * (1 - t) * t;
    const controlX = (pointX - (1 - t) ** 2 * fromX - t ** 2 * toX) / denom;
    const controlY = (pointY - (1 - t) ** 2 * fromY - t ** 2 * toY) / denom;
    return { x: controlX, y: controlY };
  };

  useEffect(() => {
    const playStr = play.attributes?.field_play_details || play.attributes?.field_exercise_details; // play or exercise?
    if (playStr) {
      const play: Play = JSON.parse(playStr);
      const correctedFrames = (play.frames || frames).map((frame) => ({
        ...frame,
        description: frame.description || '',
        lines: frame.lines.map((line) => ({
          ...line,
          id: line.id || line.fromId,
          color: line.color || '#000000',
          controlX: line.controlX || (line.fromX + line.toX) / 2,
          controlY: line.controlY || (line.fromY + line.toY) / 2,
        })),
        cones: frame.cones || [], // Include cones
      }));
      setFrames(correctedFrames);
      setCurrentFrameIndex(play.currentFrameIndex || 0);
      setDragging(play.dragging || DRAGGINGSTATE);
      setSelectedPlayerId(play.selectedPlayerId || null);
      setIsModalOpen(play.isModalOpen || false);
      setNextLineId(play.nextLineId || 1);
      setNextConeId(play.nextConeId || 1); // Set nextConeId
      setCourtType(play.courtType || 'halfcourt');
    }
  }, []);

  const handleCourtChange = (_: React.MouseEvent<HTMLElement>, newType: 'halfcourt' | 'fullcourt' | null) => {
    if (newType) setCourtType(newType);
  };

  const handleAddCone = (color: string) => {
    const newCone: Cone = {
      id: nextConeId,
      x: courtWidth / 2,
      y: courtHeight / 2,
      color,
    };
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.cones = [...(current.cones ?? []), newCone]; // Ensure cones is initialized
      updated[currentFrameIndex] = current;
      return updated;
    });
    setNextConeId(nextConeId + 1);
    setSelectedConeId(newCone.id);
    setSelectedPlayerId(null);
    setSelectedLineId(null);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDescription = e.target.value;
    setFrames((prev) => {
      const updated = [...prev];
      updated[currentFrameIndex] = { ...updated[currentFrameIndex], description: newDescription };
      return updated;
    });
  };

  // it calculates where the next line starts from. for example, is !dashed, it will start from the end of the previous line
  // if it is dashed, it will start from the player
  const adjustLineConnections = (lines: Line[], updatedLine: Line, direction: 'forward' | 'backward', lineType?: Line['type']) => {
    const playerLines = getPlayerLinesInOrder(lines, updatedLine.fromId);
    const index = playerLines.findIndex((l) => l.id === updatedLine.id);
    if (direction === 'forward' && index < playerLines.length - 1) {
      const nextLine = playerLines[index + 1];
      if (lineType !== 'dashed') {
        nextLine.fromX = Math.round(updatedLine.toX); // No decimals
        nextLine.fromY = Math.round(updatedLine.toY); // No decimals
        nextLine.controlX = Math.round((nextLine.fromX + nextLine.toX) / 2);
        nextLine.controlY = Math.round((nextLine.fromY + nextLine.toY) / 2);
        adjustLineConnections(lines, nextLine, 'forward', nextLine.type);
      }
    } else if (direction === 'backward' && index > 0) {
      const prevLine = playerLines[index - 1];
      prevLine.toX = Math.round(updatedLine.fromX); // No decimals
      prevLine.toY = Math.round(updatedLine.fromY); // No decimals
      prevLine.controlX = Math.round((prevLine.fromX + prevLine.toX) / 2);
      prevLine.controlY = Math.round((prevLine.fromY + prevLine.toY) / 2);
      adjustLineConnections(lines, prevLine, 'backward', prevLine.type);
    }
  };

  const handleAddOffensivePlayer = (playerNumber: number) => {
    const newPlayer: Player = {
      id: Math.max(...currentFrame.players.map((p) => p.id), 0) + 1,
      x: courtWidth / 2,
      y: courtHeight / 2,
      number: playerNumber, // 1-6 for players, 0 for coach (ignored in display)
      hasBall: false,
      isDefender: false,
      isCoach: playerNumber === 0, // Mark as coach if number is 0
      color: '#000000',
    };

    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.players = [...current.players, newPlayer];
      updated[currentFrameIndex] = current;
      return updated;
    });

    setSelectedPlayerId(newPlayer.id);
  };

  const handleAddDefender = (defenderNumber: number) => {
    const newDefender: Player = {
      id: Math.max(...currentFrame.players.map((p) => p.id), 0) + 1,
      x: courtWidth / 2, // Place in the center initially
      y: courtHeight / 2,
      number: defenderNumber,
      hasBall: false,
      isDefender: true,
      color: 'red',
    };

    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.players = [...current.players, newDefender];
      updated[currentFrameIndex] = current;
      return updated;
    });

    setSelectedPlayerId(newDefender.id);
    setSelectedConeId(null);
  };

  const handleDeletePlayer = () => {
    if (selectedPlayerId === null) return showSnackbar('PlayBuilder05');

    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.players = current.players.filter((p) => p.id !== selectedPlayerId);
      current.lines = current.lines.filter(
        (l) => l.fromId !== selectedPlayerId && l.toId !== selectedPlayerId
      );
      updated[currentFrameIndex] = current;
      return updated;
    });

    setSelectedPlayerId(null);
    setSelectedLineId(null);
  };

  // New function to handle cone deletion
  const handleDeleteCone = () => {
    if (selectedConeId === null) return showSnackbar('PlayBuilder15'); // Add a translation key like "Select a cone to delete"
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.cones = current.cones.filter((c) => c.id !== selectedConeId);
      updated[currentFrameIndex] = current;
      return updated;
    });
    setSelectedConeId(null); // Clear selection after deletion
  };

  // it handles the move event
  const handleMove = (e: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>) => {
    if (!dragging.type || dragging.id === null || !svgRef.current) {
      // console.log('handleMove blocked: ', { type: dragging.type, id: dragging.id, svgRef: svgRef.current });
      return;
    }

    // Get mouse/touch coordinates relative to SVG
    const { x: clientX, y: clientY } = getEventCoordinates(e.nativeEvent);
    const pt = svgRef.current.createSVGPoint();
    pt.x = clientX;
    pt.y = clientY;
    const cursor = pt.matrixTransform(svgRef.current.getScreenCTM()?.inverse());
    let mouseX = Math.round(clamp(cursor.x, 0, courtWidth));
    let mouseY = Math.round(clamp(cursor.y, 0, courtHeight));

    // Drag threshold check
    const DRAG_THRESHOLD = 5;
    if (!dragging.isDragging) {
      if (initialPointerPosition) {
        const distance = Math.hypot(mouseX - initialPointerPosition.x, mouseY - initialPointerPosition.y);
        // console.log(`Distance check: ${distance}`);
        if (distance > DRAG_THRESHOLD) setDragging((prev) => ({ ...prev, isDragging: true }));
        else return;
      } else {
        // console.log('No initial position set');
        return;
      }
    }

    // console.log(`handleMove: type=${dragging.type}, id=${dragging.id}, x=${mouseX}, y=${mouseY}`);

    // Define basket points
    const basketPoints = courtType === 'halfcourt'
      ? [{ x: 270, y: 85 }] // Half court: single basket
      : [
        { x: 80, y: 274 },  // Full court: left basket
        { x: 830, y: 274 }  // Full court: right basket
      ];

    // Update frames based on dragging type
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };

      switch (dragging.type) {
        case 'player': {
          // Move player and adjust connected lines
          current.players = current.players.map((p) =>
            p.id === dragging.id ? { ...p, x: mouseX, y: mouseY } : p
          );
          const playerLines = getPlayerLinesInOrder(current.lines, dragging.id!);
          current.lines = current.lines.map((line) => {
            const index = playerLines.findIndex((l) => l.id === line.id);
            if (index === 0) return { ...line, fromX: mouseX, fromY: mouseY };
            if (index > 0 && playerLines[index - 1].type === 'dashed')
              return { ...line, fromX: mouseX, fromY: mouseY };
            if (line.type === 'dashed' && line.toId === dragging.id) {
              return {
                ...line,
                toX: mouseX,
                toY: mouseY,
                controlX: line.controlModified ? line.controlX : Math.round((line.fromX + mouseX) / 2),
                controlY: line.controlModified ? line.controlY : Math.round((line.fromY + mouseY) / 2),
              };
            }
            return line;
          });
          break;
        }

        case 'end': {
          // Move line endpoint with snapping logic
          current.lines = current.lines.map((line) => {
            if (line.id !== dragging.id) return line;

            let toX = mouseX;
            let toY = mouseY;
            const SNAP_DISTANCE = 20; // Distance threshold for snapping

            if (line.type === 'shot') {
              // Check distance to both baskets and snap to the closer one
              let closestBasket = basketPoints[0];
              let minDist = Math.hypot(mouseX - closestBasket.x, mouseY - closestBasket.y);

              for (const basket of basketPoints) {
                const dist = Math.hypot(mouseX - basket.x, mouseY - basket.y);
                if (dist < minDist) {
                  minDist = dist;
                  closestBasket = basket;
                }
              }

              if (minDist <= SNAP_DISTANCE) {
                toX = closestBasket.x; // Snap to the closest basket
                toY = closestBasket.y;
              }
            } else if (line.type === 'dashed') {
              // Existing snapping logic for dashed lines
              const otherLines = current.lines.filter((l) => l.fromId !== line.fromId && ['straight', 'bar', 'zigzag'].includes(l.type));
              for (const otherLine of otherLines) {
                const dist = Math.hypot(mouseX - otherLine.toX, mouseY - otherLine.toY);
                if (dist <= SNAP_DISTANCE) {
                  toX = Math.round(otherLine.toX);
                  toY = Math.round(otherLine.toY);
                  const updatedLine = {
                    ...line,
                    toX,
                    toY,
                    toId: otherLine.fromId,
                    controlX: line.controlModified ? line.controlX : Math.round((line.fromX + toX) / 2),
                    controlY: line.controlModified ? line.controlY : Math.round((line.fromY + toY) / 2),
                  };
                  adjustLineConnections(current.lines, updatedLine, 'forward', line.type);
                  return updatedLine;
                }
              }
              const nearbyPlayer = findNearbyPlayer(mouseX, mouseY);
              if (nearbyPlayer !== null && nearbyPlayer !== line.fromId) {
                const target = current.players.find((p) => p.id === nearbyPlayer);
                if (target) {
                  toX = Math.round(target.x);
                  toY = Math.round(target.y);
                  const updatedLine = {
                    ...line,
                    toX,
                    toY,
                    toId: target.id,
                    controlX: line.controlModified ? line.controlX : Math.round((line.fromX + toX) / 2),
                    controlY: line.controlModified ? line.controlY : Math.round((line.fromY + toY) / 2),
                  };
                  adjustLineConnections(current.lines, updatedLine, 'forward', line.type);
                  return updatedLine;
                }
              }
            }

            // Update line endpoint and control points
            const isDuplicated = line.midX !== undefined && line.midY !== undefined && line.controlX2 !== undefined && line.controlY2 !== undefined;
            const updatedLine = {
              ...line,
              toX,
              toY,
              ...(isDuplicated
                ? {
                  controlX2: line.control2Modified ? line.controlX2 : Math.round((line.midX! + toX) / 2),
                  controlY2: line.control2Modified ? line.controlY2 : Math.round((line.midY! + toY) / 2),
                }
                : {
                  controlX: line.controlModified ? line.controlX : Math.round((line.fromX + toX) / 2),
                  controlY: line.controlModified ? line.controlY : Math.round((line.fromY + toY) / 2),
                }),
              toId: line.type === 'dashed' && toX === mouseX && toY === mouseY ? undefined : line.toId,
            };
            adjustLineConnections(current.lines, updatedLine, 'forward', line.type);
            return updatedLine;
          });
          break;
        }

        case 'control': {
          // Move first control point
          current.lines = current.lines.map((line) => {
            if (line.id === dragging.id) {
              const newControl = calculateControlPoint(
                line.fromX,
                line.fromY,
                line.midX ?? line.toX,
                line.midY ?? line.toY,
                mouseX,
                mouseY,
                0.5
              );
              return {
                ...line,
                controlX: Math.round(newControl.x),
                controlY: Math.round(newControl.y),
                controlModified: true,
              };
            }
            return line;
          });
          break;
        }

        case 'control2': {
          // Move second control point (double Bezier)
          current.lines = current.lines.map((line) => {
            if (line.id === dragging.id && line.midX && line.midY && line.controlX2 && line.controlY2) {
              const newControl = calculateControlPoint(
                line.midX,
                line.midY,
                line.toX,
                line.toY,
                mouseX,
                mouseY,
                0.5
              );
              return {
                ...line,
                controlX2: Math.round(newControl.x),
                controlY2: Math.round(newControl.y),
                control2Modified: true,
              };
            }
            return line;
          });
          break;
        }

        case 'mid': {
          // Move midpoint (double Bezier)
          current.lines = current.lines.map((line) => {
            if (line.id === dragging.id && line.midX && line.midY) {
              return {
                ...line,
                midX: mouseX,
                midY: mouseY,
                controlX: line.controlModified ? line.controlX : Math.round((line.fromX + mouseX) / 2),
                controlY: line.controlModified ? line.controlY : Math.round((line.fromY + mouseY) / 2),
                controlX2: line.control2Modified ? line.controlX2 : Math.round((mouseX + line.toX) / 2),
                controlY2: line.control2Modified ? line.controlY2 : Math.round((mouseY + line.toY) / 2),
              };
            }
            return line;
          });
          break;
        }

        case 'cone': {
          // Move cone
          current.cones = current.cones.map((cone) =>
            cone.id === dragging.id ? { ...cone, x: mouseX, y: mouseY } : cone
          );
          break;
        }

        default:
        // console.log(`Unhandled dragging type: ${dragging.type}`);
      }

      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handleStart = (
    e: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>,
    type: 'player' | 'end' | 'control' | 'control2' | 'mid' | 'cone',
    id: number
  ) => {
    e.stopPropagation();
    const { x, y } = getEventCoordinates(e.nativeEvent);
    if (!svgRef.current) return;
    const pt = svgRef.current.createSVGPoint();
    pt.x = x;
    pt.y = y;
    const cursor = pt.matrixTransform(svgRef.current.getScreenCTM()?.inverse());
    setInitialPointerPosition({ x: clamp(cursor.x, 0, courtWidth), y: clamp(cursor.y, 0, courtHeight) });

    // Only deselect line if dragging a player or cone
    if (type === 'player' || type === 'cone') {
      setSelectedLineId(null);
    }
    // Always deselect players and cones when starting a drag
    setSelectedPlayerId(null);
    setSelectedConeId(null);

    // Set selection based on type
    if (type === 'player') {
      setSelectedPlayerId(id);
    } else if (type === 'cone') {
      setSelectedConeId(id);
    }
    // For line-related types ('end', 'control', 'control2', 'mid'), preserve selectedLineId

    setDragging({ isDragging: false, type, id });

    if (type === 'end' && currentFrame.lines.find((line) => line.id === id)?.type === 'dashed') {
      setIsDraggingLineEnd(true);
    }
  };

  const handleEnd = () => {
    setDragging({ isDragging: false, type: null, id: null });
    setInitialPointerPosition(null);
    setIsDraggingLineEnd(false);
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      if (dragging.type === 'end' && current.lines) {
        current.lines = current.lines.map((line) => {
          if (line.id === dragging.id && line.type === 'dashed') {
            const nearbyPlayer = findNearbyPlayer(line.toX, line.toY);
            if (nearbyPlayer !== null && nearbyPlayer !== line.fromId) {
              const target = current.players.find((p) => p.id === nearbyPlayer);
              if (target) {
                const updatedLine = {
                  ...line,
                  toX: target.x,
                  toY: target.y,
                  toId: target.id,
                  controlX: (line.fromX + target.x) / 2,
                  controlY: (line.fromY + target.y) / 2,
                };
                adjustLineConnections(current.lines, updatedLine, 'forward', line.type);
                setSelectedPlayerId(target.id);
                setSelectedLineId(null);
                return updatedLine;
              }
            }
          }
          return line;
        });
      }
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handleDragOver = (e: React.DragEvent<SVGElement>) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e: React.DragEvent<SVGElement>) => {
    e.preventDefault();
    if (!svgRef.current) return;

    const type = e.dataTransfer.getData('type');
    const pt = svgRef.current.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const cursor = pt.matrixTransform(svgRef.current.getScreenCTM()?.inverse());
    const x = clamp(Math.round(cursor.x), 0, courtWidth);
    const y = clamp(Math.round(cursor.y), 0, courtHeight);

    if (type === 'offensive') {
      const number = parseInt(e.dataTransfer.getData('number'), 10);
      const newPlayer: Player = {
        id: Math.max(...currentFrame.players.map((p) => p.id), 0) + 1,
        x,
        y,
        number,
        hasBall: false,
        isDefender: false,
        isCoach: number === 0,
      };
      setFrames((prev) => {
        const updated = [...prev];
        updated[currentFrameIndex].players.push(newPlayer);
        return updated;
      });
      setSelectedPlayerId(newPlayer.id);
      setSelectedConeId(null);
    } else if (type === 'defender') {
      const number = parseInt(e.dataTransfer.getData('number'), 10);
      const newDefender: Player = {
        id: Math.max(...currentFrame.players.map((p) => p.id), 0) + 1,
        x,
        y,
        number,
        hasBall: false,
        isDefender: true,
      };
      setFrames((prev) => {
        const updated = [...prev];
        updated[currentFrameIndex].players.push(newDefender);
        return updated;
      });
      setSelectedPlayerId(newDefender.id);
      setSelectedConeId(null);
    } else if (type === 'cone') {
      const color = e.dataTransfer.getData('color');
      const newCone: Cone = {
        id: nextConeId,
        x,
        y,
        color,
      };
      setFrames((prev) => {
        const updated = [...prev];
        updated[currentFrameIndex].cones.push(newCone);
        return updated;
      });
      setNextConeId(nextConeId + 1);
      setSelectedConeId(newCone.id);
      setSelectedPlayerId(null);
      setSelectedLineId(null);
    }
  };

  const handleLineClick = (lineId: number) => {
    const selectedLine = currentFrame.lines.find((line) => line.id === lineId);
    if (selectedLine) {
      setSelectedLineId(lineId);
      setSelectedPlayerId(null); // Deselect player
    }
    setSelectedConeId(null);
  };

  const handlePlayerClick = (playerId: number) => {
    setSelectedPlayerId(playerId);
    setSelectedLineId(null);
    setSelectedConeId(null);
  };

  const handleConeClick = (coneId: number) => {
    setSelectedConeId(coneId);
    setSelectedPlayerId(null);
    setSelectedLineId(null);
  };

  // it finds the nearest player to the clicked point so we can connect the line to it
  const findNearbyPlayer = (x: number, y: number): number | null => {
    const proximity = 20;
    const current = frames[currentFrameIndex];
    let closest: number | null = null, minDist = proximity;
    current.players.forEach((p) => {
      const d = Math.hypot(p.x - x, p.y - y);
      if (d <= minDist) {
        minDist = d;
        closest = p.id;
      }
    });
    return closest;
  };

  const getNextLineOrder = (lines: Line[]) => (lines.length ? Math.max(...lines.map((l) => l.order ?? 1)) + 1 : 1);

  const getLineOrigin = (playerId: number, lines: Line[], players: Player[]) => {
    const playerLines = getPlayerLinesInOrder(lines, playerId);
    if (playerLines.length === 0) {
      const player = players.find((p) => p.id === playerId);
      return player ? { x: Math.round(player.x), y: Math.round(player.y) } : null;
    }

    // Find the last non-dashed, non-shot line to use its endpoint as the origin
    const lastNonDashedShotLine = playerLines
      .filter((line) => line.type !== 'dashed' && line.type !== 'shot')
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .pop();

    if (lastNonDashedShotLine) {
      return { x: Math.round(lastNonDashedShotLine.toX), y: Math.round(lastNonDashedShotLine.toY) };
    }

    // If no non-dashed, non-shot lines exist, use the player's current position
    const player = players.find((p) => p.id === playerId);
    return player ? { x: Math.round(player.x), y: Math.round(player.y) } : null;
  };

  const calculateEndPoint = (origin: { x: number; y: number }, playerLines: Line[]) => {
    const LINE_LENGTH = 125;
    const centerX = courtWidth / 2, centerY = courtHeight / 2;
    if (playerLines.length === 0 || playerLines[0].type === 'dashed') {
      const dx = centerX - origin.x, dy = centerY - origin.y, magnitude = Math.hypot(dx, dy) || 1;
      return {
        x: clamp(origin.x + (dx / magnitude) * LINE_LENGTH, 0, courtWidth),
        y: clamp(origin.y + (dy / magnitude) * LINE_LENGTH, 0, courtHeight),
      };
    }
    const firstLine = playerLines[0];
    const dxFirst = firstLine.toX - firstLine.fromX, dyFirst = firstLine.toY - firstLine.fromY;
    const magnitudeFirst = Math.hypot(dxFirst, dyFirst) || 1;
    const dxRotated = (dyFirst / magnitudeFirst) * LINE_LENGTH, dyRotated = (-dxFirst / magnitudeFirst) * LINE_LENGTH;
    return {
      x: clamp(origin.x + dxRotated, 0, courtWidth),
      y: clamp(origin.y + dyRotated, 0, courtHeight),
    };
  };

  const createLine = (type: 'straight' | 'zigzag' | 'dashed' | 'bar' | 'shot') => {
    // Determine the player to draw the line from
    let playerId: number | null = null;
    if (selectedLineId !== null) {
      const selectedLine = currentFrame.lines.find((line) => line.id === selectedLineId);
      playerId = selectedLine?.fromId ?? null;
    } else if (selectedPlayerId !== null) {
      playerId = selectedPlayerId;
    }

    if (playerId === null) {
      showSnackbar('PlayBuilder00'); // "Select a player or line first"
      return;
    }

    const current = frames[currentFrameIndex];
    const selectedPlayer = current.players.find((p) => p.id === playerId);
    if (!selectedPlayer) return;

    // Get player's lines and check ball possession
    const playerLines = getPlayerLinesInOrder(current.lines, playerId);
    const orderedPassShotLines = current.lines
      .filter((line) => (line.type === 'dashed' || line.type === 'shot') && (line.fromId === playerId || line.toId === playerId))
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    let hasBall = selectedPlayer.hasBall;
    for (const line of orderedPassShotLines) {
      if (line.fromId === playerId && (line.type === 'dashed' || line.type === 'shot')) hasBall = false; // Player passes or shoots
      else if (line.toId === playerId && line.type === 'dashed') hasBall = true; // Player receives pass
    }

    // Validate action based on ball possession
    const canDribble = hasBall && (type === 'straight' || type === 'bar' || type === 'zigzag');
    const canPass = hasBall && type === 'dashed';
    const canShoot = hasBall && type === 'shot';
    const canOnlyMoveOrScreen = !hasBall && (type === 'straight' || type === 'bar'); // Allow bar after shot

    if (hasBall && !canDribble && !canPass && !canShoot) {
      showSnackbar('PlayBuilder01'); // "Player with ball can only dribble, pass, or shoot"
      return;
    }
    if (!hasBall && !canOnlyMoveOrScreen) {
      showSnackbar('PlayBuilder01'); // "Player without ball can only move or screen"
      return;
    }

    // Determine starting point based on last non-dashed, non-shot line
    const origin = getLineOrigin(playerId, current.lines, current.players);
    if (!origin) return;

    // Define basket points
    const basketPoints = courtType === 'halfcourt'
      ? [{ x: 270, y: 85 }] // Half court: single basket
      : [
        { x: 80, y: 274 },  // Full court: left basket
        { x: 830, y: 274 }  // Full court: right basket
      ];

    // Determine endpoint
    let toX: number, toY: number;
    if (type === 'shot') {
      if (courtType === 'halfcourt') {
        toX = basketPoints[0].x; // Center of the hoop in half court
        toY = basketPoints[0].y;
      } else {
        // Full court: choose basket based on player's starting position
        const targetBasket = origin.x < courtWidth / 2 ? basketPoints[0] : basketPoints[1];
        toX = targetBasket.x;
        toY = targetBasket.y;
      }
    } else {
      const { x: endX, y: endY } = calculateEndPoint(origin, playerLines);
      toX = endX;
      toY = endY;
    }

    // Generate new line ID and default color
    const newLineId = current.lines.length > 0 ? Math.max(...current.lines.map((l) => l.id)) + 1 : 1;
    const defaultColor = type === 'shot' ? '#f57c00' : (selectedPlayer.isDefender ? 'red' : '#000000');

    // Create the new line
    const newLine: Line = {
      id: newLineId,
      fromId: playerId,
      toId: undefined,
      type,
      fromX: Math.round(origin.x),
      fromY: Math.round(origin.y),
      toX: Math.round(toX),
      toY: Math.round(toY),
      controlX: Math.round((origin.x + toX) / 2),
      controlY: Math.round((origin.y + toY) / 2),
      order: getNextLineOrder(current.lines),
      controlModified: false,
      color: defaultColor,
      uniqueId: `frame${currentFrameIndex}-line${newLineId}`,
    };

    // Update frames with the new line
    setFrames((prev) => {
      const updated = [...prev];
      updated[currentFrameIndex] = {
        ...updated[currentFrameIndex],
        lines: [...updated[currentFrameIndex].lines, newLine],
      };
      return updated;
    });

    // Update state
    setNextLineId(newLineId + 1);
    setSelectedLineId(newLineId);
    setSelectedPlayerId(null);
  };


  const convertToDoubleBezier = () => {
    if (selectedLineId === null) return showSnackbar('PlayBuilder04');
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.lines = current.lines.map((line) => {
        if (line.id === selectedLineId) {
          const t = 0.5;
          const midX = bezierPoint(t, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).x;
          const midY = bezierPoint(t, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).y;
          const tFirst = 0.25;
          const newControlX = bezierPoint(tFirst, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).x;
          const newControlY = bezierPoint(tFirst, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).y;
          const tSecond = 0.75;
          const controlX2 = bezierPoint(tSecond, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).x;
          const controlY2 = bezierPoint(tSecond, line.fromX, line.fromY, line.controlX, line.controlY, line.toX, line.toY).y;
          return {
            ...line,
            midX,
            midY,
            controlX: newControlX,
            controlY: newControlY,
            controlX2,
            controlY2,
            controlModified: false,
            control2Modified: false,
          };
        }
        return line;
      });
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const convertToSingleBezier = () => {
    if (selectedLineId === null) return showSnackbar('PlayBuilder04');
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.lines = current.lines.map((line) => {
        if (line.id === selectedLineId && line.midX !== undefined && line.midY !== undefined && line.controlX2 !== undefined && line.controlY2 !== undefined) {
          const newControlX = (line.midX - 0.25 * line.fromX - 0.25 * line.toX) / 0.5;
          const newControlY = (line.midY - 0.25 * line.fromY - 0.25 * line.toY) / 0.5;
          return {
            ...line,
            midX: undefined,
            midY: undefined,
            controlX: newControlX,
            controlY: newControlY,
            controlX2: undefined,
            controlY2: undefined,
            controlModified: true,
            control2Modified: undefined,
          };
        }
        return line;
      });
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handleDeleteLine = () => {
    if (selectedLineId === null) return showSnackbar('PlayBuilder04'); // "Select a line first"

    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      const selectedLine = current.lines.find((l) => l.id === selectedLineId);
      if (!selectedLine) return prev; // Line not found, no change

      const playerId = selectedLine.fromId; // Get the player from the selected line
      const playerLines = getPlayerLinesInOrder(current.lines, playerId);
      if (playerLines.length === 0) return prev;

      const lineToDelete = playerLines[playerLines.length - 1]; // Always delete the last line
      current.lines = current.lines.filter((l) => l.id !== lineToDelete.id);

      const remainingLines = getPlayerLinesInOrder(current.lines, playerId);
      setSelectedLineId(remainingLines[0]?.id || null); // Select the first remaining line or null

      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handleCreateNewFrame = () => {
    const current = frames[currentFrameIndex];
    let updatedPlayers = current.players.map((p) => ({ ...p }));
    const updatedCones = current.cones.map((c) => ({ ...c })); // Carry over cones

    // Identify all lines sorted by order to process shots and subsequent movements
    const orderedLines = current.lines.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    const shotLines = orderedLines.filter((line) => line.type === 'shot');

    // Update player positions and ball possession
    updatedPlayers = updatedPlayers.map((player) => {
      const playerLines = getPlayerLinesInOrder(current.lines, player.id); // Already sorted by order
      const lastShotLine = shotLines.find((line) => line.fromId === player.id);

      if (lastShotLine) {
        // Find the last movement line after the shot (if any)
        const shotIndex = playerLines.findIndex((line) => line.id === lastShotLine.id);
        const linesAfterShot = playerLines.slice(shotIndex + 1);
        const lastMovementLineAfterShot = linesAfterShot
          .filter((line) => ['straight', 'zigzag', 'bar'].includes(line.type)) // Movement lines only
          .pop();

        if (lastMovementLineAfterShot) {
          // If there's a movement line after the shot, use its endpoint
          return {
            ...player,
            x: Math.round(lastMovementLineAfterShot.toX),
            y: Math.round(lastMovementLineAfterShot.toY),
            hasBall: false, // Player loses the ball after shooting
          };
        } else {
          // If no movement after shot, use the shooting position
          return {
            ...player,
            x: Math.round(lastShotLine.fromX),
            y: Math.round(lastShotLine.fromY),
            hasBall: false, // Player loses the ball after shooting
          };
        }
      } else {
        // For non-shooting players, use the last movement line (excluding dashes and shots)
        const lastMovementLine = playerLines
          .filter((line) => line.type !== 'dashed' && line.type !== 'shot')
          .pop();
        if (lastMovementLine) {
          return {
            ...player,
            x: Math.round(lastMovementLine.toX),
            y: Math.round(lastMovementLine.toY),
            // hasBall remains unchanged unless explicitly modified below
          };
        }
        return { ...player }; // No change if no relevant lines
      }
    });

    // Handle ball possession after passes
    const passLines = orderedLines.filter((line) => line.type === 'dashed');

    // Process passes to update ball possession
    passLines.forEach((passLine) => {
      const fromPlayerIndex = updatedPlayers.findIndex((p) => p.id === passLine.fromId);
      const toPlayerIndex = updatedPlayers.findIndex((p) => p.id === passLine.toId);
      if (fromPlayerIndex !== -1 && toPlayerIndex !== -1 && updatedPlayers[fromPlayerIndex].hasBall) {
        updatedPlayers[fromPlayerIndex].hasBall = false;
        updatedPlayers[toPlayerIndex].hasBall = true;
      }
    });

    // Create the new frame
    const newFrame: Frame = { id: frames.length + 1, players: updatedPlayers, lines: [], cones: updatedCones };
    setFrames((prev) => [...prev, newFrame]);
    setCurrentFrameIndex(frames.length);

    // Select only one player with the ball and deselect everything else
    const playerWithBall = updatedPlayers.find((p) => p.hasBall) || updatedPlayers[0]; // Default to first player if no ball
    setSelectedPlayerId(playerWithBall ? playerWithBall.id : null);
    setSelectedLineId(null); // Deselect any selected line
    setSelectedConeId(null); // Deselect any selected cone
    setDragging({ isDragging: false, type: null, id: null }); // Reset dragging state
  };

  const handleDeleteFrame = (index: number) => {
    if (frames.length === 1) return showSnackbar('PlayBuilder03');
    const updated = frames.filter((_, i) => i !== index).map((frame, i) => ({ ...frame, id: i + 1 }));
    setFrames(updated);
    if (currentFrameIndex >= updated.length) setCurrentFrameIndex(updated.length - 1);
    else if (index < currentFrameIndex) setCurrentFrameIndex((prev) => prev - 1);
  };

  const handleSavePlay = async () => {
    const playLocal = {
      frames,
      currentFrameIndex,
      dragging,
      selectedPlayerId,
      isModalOpen,
      nextPlayerId: 1,
      nextLineId,
      nextConeId, // Include nextConeId
      courtType,
    };
    const playNode: DrupalEntity = {
      type: 'node--play',
      id: play.id,
      attributes: { field_play_details: JSON.stringify(playLocal) },
    };
    const resp = await nodeCRUD(state, dispatch, CRUD.Update, playNode);
    if (!resp.data) dispatch(getActionSetConfirm(t(resp)));
  };

  const handleToggleBall = () => {
    if (selectedPlayerId === null) return showSnackbar('PlayBuilder05');
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.players = current.players.map((p) =>
        p.id === selectedPlayerId ? { ...p, hasBall: !p.hasBall } : p
      );
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handleSelectFrame = (index: number) => {
    setCurrentFrameIndex(index);
    setSelectedPlayerId(null);
    setSelectedLineId(null);
    setDragging({ isDragging: false, type: null, id: null });
  };

  const handleColorChange = (color: string) => {
    if (selectedLineId === null) return;
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.lines = current.lines.map((line) =>
        line.id === selectedLineId ? { ...line, color } : line
      );
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const handlePlayerColorChange = (color: string) => {
    if (selectedPlayerId === null) return showSnackbar('PlayBuilder05');
    setFrames((prev) => {
      const updated = [...prev];
      const current = { ...updated[currentFrameIndex] };
      current.players = current.players.map((p) =>
        p.id === selectedPlayerId ? { ...p, color } : p
      );
      updated[currentFrameIndex] = current;
      return updated;
    });
  };

  const currentFrame = frames[currentFrameIndex];
  const selectedLine = currentFrame.lines.find((line) => line.id === selectedLineId);
  const isDoubleBezier = selectedLine?.midX !== undefined;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(null)}
        fullScreen
        PaperProps={{ style: { width: '98%', height: '98%', margin: 'auto', borderRadius: 8 } }}
      >
        <Box sx={{ p: 1, bgcolor: '#00398F', textAlign: 'center' }}>
          <Grid container alignItems="center">
            <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'left' }}>
              <Button
                variant="outlined"
                onClick={() => setIsModalOpen(true)}
                size={state.portrait ? "small" : "medium"}
                style={{ color: "white", textTransform: 'none', borderColor: 'white', fontWeight: 'bold' }}
              >
                <PlayArrowRoundedIcon />
                {t('DialogPlayBuilder00')}
              </Button>
            </Grid>
            {!state.portrait && (
              <Grid item xs={4}>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold', color: 'white' }} align="center">
                  {t('PlayBuilder06')}
                </Typography>
              </Grid>
            )}
            <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                size={state.portrait ? "small" : "medium"}
                style={{ color: '#00398F', textTransform: 'none', backgroundColor: 'white', fontWeight: 'bold' }}
                onClick={() => {
                  const playDetails: PlayDetails = {
                    frames, currentFrameIndex, dragging, selectedPlayerId, courtType,
                    selectedLineId: null
                  };
                  onClose(playDetails);
                }}
              >
                <SaveRoundedIcon /> {t('CreatePlay08')}
              </Button>
              <IconButton sx={{ color: 'white', ml: 1 }} onClick={() => onClose(null)}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {/* <Grid container style={{ height: state.portrait ? 'auto' : '100vh', backgroundColor: state.portrait ? 'white' : '#f1f1f1' }}> */}
        <Grid container style={{
          height: 'auto',
          backgroundColor: state.portrait ? 'white' : '#f1f1f1'
        }}>

          <Grid item xs={state.portrait ? 12 : 3} md={state.portrait ? 12 : 3} style={{ padding: state.portrait ? '0px' : '10px', borderRight: state.portrait ? 'none' : '1px solid #ccc', backgroundColor: state.portrait ? 'white' : 'white', overflowY: 'auto', paddingBottom: state.portrait ? '0px' : '300px' }}>
            <PhasesSectionPlayBuilder
              play={play}
              frames={frames}
              currentFrameIndex={currentFrameIndex}
              handleSelectFrame={handleSelectFrame}
              handleDeleteFrame={handleDeleteFrame}
              handleCreateNewFrame={handleCreateNewFrame}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ position: 'relative', maxWidth: `${MAX_SVG_WIDTH}px`, margin: '0 auto', paddingBottom: state.portrait ? '0px' : '300px' }}>
            {!state.portrait && (
              <Typography sx={{ fontWeight: 'bold', textAlign: 'center', pt: 1, pb: 1 }}>
                {play.attributes?.title || ''}
              </Typography>
            )}

            <svg
              ref={svgRef}
              onMouseMove={handleMove}
              onTouchMove={handleMove}
              onMouseUp={handleEnd}
              onTouchEnd={handleEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              viewBox={`0 0 ${courtWidth} ${courtHeight}`}
              style={{ width: '100%', height: 'auto', borderRadius: state.portrait ? 0 : 8, touchAction: 'none' }}
              preserveAspectRatio="xMidYMid meet"
            >
              {courtType === 'halfcourt' ? <BasketballHalfCourt /> : <BasketballFullCourt />}
              {currentFrame.lines.map((line) => (
                <SvgDefs key={line.id} frameId={currentFrame.id} lineId={line.id} color={line.color || '#000000'} />
              ))}
              {/* Render Cones */}
              {currentFrame.cones.map((cone) => (
                <g
                  key={cone.id}
                  onMouseDown={(e) => handleStart(e, 'cone', cone.id)}
                  onTouchStart={(e) => handleStart(e, 'cone', cone.id)}
                  onClick={() => handleConeClick(cone.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <foreignObject
                    x={cone.x - 12}
                    y={cone.y - 12}
                    width={24}
                    height={24}
                  >
                    <BsCone size={24} color={cone.color} />
                  </foreignObject>
                  {(dragging.type === 'cone' && dragging.id === cone.id) || selectedConeId === cone.id ? (
                    <rect
                      x={cone.x - 14}
                      y={cone.y - 14}
                      width={28}
                      height={28}
                      rx={2}
                      ry={2}
                      fill="none"
                      stroke="black"
                      strokeWidth={1}
                      strokeDasharray="4,2"
                    >
                      <animate attributeName="stroke-dashoffset" from="0" to="6" dur="2s" repeatCount="indefinite" />
                    </rect>
                  ) : null}
                </g>
              ))}

              {currentFrame.players.map((player) => (
                <g
                  key={player.id}
                  onMouseDown={(e) => handleStart(e, 'player', player.id)}
                  onTouchStart={(e) => handleStart(e, 'player', player.id)}
                  onClick={() => handlePlayerClick(player.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <circle
                    cx={player.x}
                    cy={player.y}
                    r={12}
                    fill={isDraggingLineEnd ? '#F0A85F' : 'transparent'}
                    stroke={isDraggingLineEnd ? '#DD7720' : 'transparent'}
                    strokeWidth={1}
                    strokeDasharray="4,2"
                  >
                    {isDraggingLineEnd && (
                      <>
                        <animate attributeName="fill" values="#F0A85F;#F6C28C;#F0A85F" dur="2s" repeatCount="indefinite" />
                        <animate attributeName="stroke-dashoffset" from="0" to="6" dur="2s" repeatCount="indefinite" />
                      </>
                    )}
                  </circle>

                  {selectedPlayerId === player.id && (
                    <rect
                      x={player.x - 16}
                      y={player.y - 16}
                      width={32}
                      height={32}
                      rx={2}
                      ry={2}
                      fill="#f1f1f1"
                      stroke="black"
                      strokeWidth={1}
                      strokeDasharray="4,2"
                    >
                      <animate attributeName="stroke-dashoffset" from="0" to="6" dur="2s" repeatCount="indefinite" />
                    </rect>
                  )}
                  {player.hasBall && (
                    <circle
                      cx={player.x}
                      cy={player.y}
                      r={14}
                      stroke={player.color || (player.isDefender ? 'red' : 'black')}
                      strokeWidth={2}
                      fill="none"
                    />
                  )}
                  <text
                    x={player.x}
                    y={player.y}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    fontSize={20}
                    fontWeight="bold"
                    fill={player.color || (player.isDefender ? 'red' : 'black')}
                    style={{ userSelect: 'none', pointerEvents: 'none' }}
                  >
                    {player.isCoach ? 'C' : player.isDefender ? `x${player.number}` : player.number}
                  </text>
                </g>
              ))}

              {currentFrame.lines.map((line) => {
                const adjustedFrom = { x: line.fromX, y: line.fromY };
                const adjustedTo = { x: line.toX, y: line.toY };
                const pathD = generatePathD(line, adjustedFrom, adjustedTo, currentFrame.lines);
                const showPoints = selectedLineId === line.id;

                return (
                  <g key={line.id}>
                    <path
                      d={pathD}
                      stroke="transparent"
                      strokeWidth={10}
                      fill="none"
                      pointerEvents="stroke"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLineClick(line.id);
                      }}
                    />
                    <path
                      d={pathD}
                      stroke={line.color || '#000000'}
                      strokeWidth={2}
                      fill="none"
                      strokeDasharray={line.type === 'dashed' || line.type === 'shot' ? '5,5' : 'none'}
                      markerEnd={
                        line.type === 'bar'
                          ? `url(#barEnd-${currentFrame.id}-${line.id})`
                          : (line.type === 'straight' || line.type === 'zigzag' || line.type === 'dashed')
                            ? `url(#arrowhead-${currentFrame.id}-${line.id})`
                            : undefined
                      }
                      pointerEvents="none"
                    />
                    {line.type === 'shot' && (
                      <>
                        {/* Visible Shot Circle */}
                        <circle
                          cx={line.toX}
                          cy={line.toY}
                          r={8}
                          fill="none"
                          stroke={line.color || '#000000'}
                          strokeWidth={2}
                        />
                        {/* Transparent Selection Circle */}
                        <circle
                          cx={line.toX}
                          cy={line.toY}
                          r={15}
                          fill="transparent"
                          style={{ cursor: 'pointer' }}
                          onMouseDown={(e) => handleStart(e, 'end', line.id)}
                          onTouchStart={(e) => handleStart(e, 'end', line.id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleLineClick(line.id);
                          }}
                        />
                      </>
                    )}
                    {showPoints && (
                      <>
                        <circle
                          cx={bezierPoint(0.5, line.fromX, line.fromY, line.controlX, line.controlY, line.midX ?? line.toX, line.midY ?? line.toY).x}
                          cy={bezierPoint(0.5, line.fromX, line.fromY, line.controlX, line.controlY, line.midX ?? line.toX, line.midY ?? line.toY).y}
                          r={6}
                          fill="#007BFF"
                          stroke="white"
                          strokeWidth={2}
                          style={{ cursor: 'pointer' }}
                          onMouseDown={(e) => handleStart(e, 'control', line.id)}
                          onTouchStart={(e) => handleStart(e, 'control', line.id)}
                        />
                        <circle
                          cx={bezierPoint(0.5, line.fromX, line.fromY, line.controlX, line.controlY, line.midX ?? line.toX, line.midY ?? line.toY).x}
                          cy={bezierPoint(0.5, line.fromX, line.fromY, line.controlX, line.controlY, line.midX ?? line.toX, line.midY ?? line.toY).y}
                          r={15}
                          fill="transparent"
                          style={{ cursor: 'pointer' }}
                          onMouseDown={(e) => handleStart(e, 'control', line.id)}
                          onTouchStart={(e) => handleStart(e, 'control', line.id)}
                        />
                        {line.midX && line.midY && line.controlX2 && line.controlY2 && (
                          <>
                            <circle
                              cx={line.midX}
                              cy={line.midY}
                              r={4}
                              fill="#FF7720"
                              stroke="white"
                              strokeWidth={2}
                              style={{ cursor: 'pointer' }}
                              onMouseDown={(e) => handleStart(e, 'mid', line.id)}
                              onTouchStart={(e) => handleStart(e, 'mid', line.id)}
                            />
                            <circle
                              cx={line.midX}
                              cy={line.midY}
                              r={15}
                              fill="transparent"
                              style={{ cursor: 'pointer' }}
                              onMouseDown={(e) => handleStart(e, 'mid', line.id)}
                              onTouchStart={(e) => handleStart(e, 'mid', line.id)}
                            />
                            <circle
                              cx={bezierPoint(0.5, line.midX, line.midY, line.controlX2, line.controlY2, line.toX, line.toY).x}
                              cy={bezierPoint(0.5, line.midX, line.midY, line.controlX2, line.controlY2, line.toX, line.toY).y}
                              r={6}
                              fill="#007BFF"
                              stroke="white"
                              strokeWidth={2}
                              style={{ cursor: 'pointer' }}
                              onMouseDown={(e) => handleStart(e, 'control2', line.id)}
                              onTouchStart={(e) => handleStart(e, 'control2', line.id)}
                            />
                            <circle
                              cx={bezierPoint(0.5, line.midX, line.midY, line.controlX2, line.controlY2, line.toX, line.toY).x}
                              cy={bezierPoint(0.5, line.midX, line.midY, line.controlX2, line.controlY2, line.toX, line.toY).y}
                              r={15}
                              fill="transparent"
                              style={{ cursor: 'pointer' }}
                              onMouseDown={(e) => handleStart(e, 'control2', line.id)}
                              onTouchStart={(e) => handleStart(e, 'control2', line.id)}
                            />
                          </>
                        )}
                        <circle
                          cx={line.toX}
                          cy={line.toY}
                          r={line.type === 'dashed' ? 12 : 6}
                          fill={line.type === 'dashed' ? 'rgba(229, 143, 71, 0.5)' : '#007BFF'}
                          stroke="white"
                          strokeWidth={2}
                          style={{ cursor: 'pointer' }}
                          onMouseDown={(e) => handleStart(e, 'end', line.id)}
                          onTouchStart={(e) => handleStart(e, 'end', line.id)}
                        />
                        <circle
                          cx={line.toX}
                          cy={line.toY}
                          r={15}
                          fill="transparent"
                          style={{ cursor: 'pointer' }}
                          onMouseDown={(e) => handleStart(e, 'end', line.id)}
                          onTouchStart={(e) => handleStart(e, 'end', line.id)}
                        />
                      </>
                    )}
                  </g>
                );
              })}

              {/* Connection point for shot lines (basket) */}
              {dragging.type === 'end' && currentFrame.lines.find((l) => l.id === dragging.id)?.type === 'shot' && (
                (() => {
                  const basketPoints = courtType === 'halfcourt'
                    ? [{ x: 270, y: 85 }] // Half court: single basket
                    : [
                      { x: 80, y: 274 },  // Full court: left basket
                      { x: 830, y: 274 }  // Full court: right basket
                    ];

                  return basketPoints.map((basket, index) => (
                    <g key={`basket-highlight-${index}`}>
                      <circle
                        cx={basket.x}
                        cy={basket.y}
                        r={8}
                        fill="#F0A85F"
                        stroke="#DD7720"
                        strokeWidth={1}
                        strokeDasharray="4,2"
                      >
                        <animate attributeName="fill" values="#F0A85F;#F6C28C;#F0A85F" dur="2s" repeatCount="indefinite" />
                        <animate attributeName="stroke-dashoffset" from="0" to="6" dur="2s" repeatCount="indefinite" />
                      </circle>
                    </g>
                  ));
                })()
              )}

              {/* Connection points for dashed lines (other players' last lines) */}
              {isDraggingLineEnd && currentFrame.lines.find((l) => l.id === dragging.id)?.type !== 'shot' &&
                currentFrame.lines
                  .filter((line) => {
                    const draggedLine = currentFrame.lines.find((l) => l.id === dragging.id && l.type === 'dashed');
                    return (
                      draggedLine &&
                      line.fromId !== draggedLine.fromId &&
                      ['straight', 'bar', 'zigzag'].includes(line.type) &&
                      getPlayerLinesInOrder(currentFrame.lines, line.fromId).slice(-1)[0]?.id === line.id
                    );
                  })
                  .map((line) => (
                    <g key={`highlight-${line.id}`}>
                      <circle
                        cx={line.toX}
                        cy={line.toY}
                        r={8}
                        fill="#F0A85F"
                        stroke="#DD7720"
                        strokeWidth={1}
                        strokeDasharray="4,2"
                      >
                        <animate attributeName="fill" values="#F0A85F;#F6C28C;#F0A85F" dur="2s" repeatCount="indefinite" />
                        <animate attributeName="stroke-dashoffset" from="0" to="6" dur="2s" repeatCount="indefinite" />
                      </circle>
                    </g>
                  ))}
            </svg>

            <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                <ToggleButtonGroup
                  value={courtType}
                  exclusive
                  onChange={handleCourtChange}
                  aria-label="Court Type"
                  size="small"
                  sx={{ backgroundColor: '#f1f1f1', borderRadius: 1, height: 30, border: '1px solid #ccc' }}
                >
                  <ToggleButton
                    value="halfcourt"
                    aria-label="Halfcourt"
                    sx={{
                      backgroundColor: 'white',
                      color: 'gray',
                      '&.Mui-selected': { backgroundColor: '#f6f6f6', color: 'black' },
                      border: 'none',
                      textTransform: 'none',
                    }}
                  >
                    {t('PlayBuilder09')}
                  </ToggleButton>
                  <ToggleButton
                    value="fullcourt"
                    aria-label="Fullcourt"
                    sx={{
                      backgroundColor: 'white',
                      color: 'gray',
                      '&.Mui-selected': { backgroundColor: '#f6f6f6', color: 'black' },
                      border: 'none',
                      textTransform: 'none',
                    }}
                  >
                    {t('PlayBuilder10')}
                  </ToggleButton>
                </ToggleButtonGroup>

                {state.portrait && (
                  <ToggleButton
                    value={showDescription}
                    selected={showDescription}
                    onChange={() => setShowDescription(!showDescription)}
                    aria-label="Toggle Description Visibility"
                    size="small"
                    sx={{
                      backgroundColor: '#f1f1f1',
                      borderRadius: 1,
                      height: 30,
                      border: '1px solid #ccc',
                      '&.Mui-selected': { backgroundColor: '#f6f6f6' },
                    }}
                  >
                    {showDescription ? <VisibilityIcon style={{ color: 'gray' }} /> : <VisibilityOffIcon style={{ color: 'gray' }} />}
                  </ToggleButton>
                )}
              </Box>

              {/* Frame Description Input - Only show in portrait if showDescription is true */}
              {(!state.portrait || (state.portrait && showDescription)) && (
                <Grid item xs={12} sx={{ mt: 2, px: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    {t('PlayBuilder13')}
                  </Typography>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={5}
                    maxLength={250}
                    value={currentFrame.description || ''}
                    onChange={handleDescriptionChange}
                    style={{
                      width: '100%',
                      padding: '8px',
                      fontSize: state.portrait ? '16px' : '12px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      resize: 'vertical',
                      whiteSpace: 'pre-wrap',
                      lineHeight: '1.5',
                    }}
                    placeholder={state.nativeApp ? '' : t('PlayBuilder14')}
                  />
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', textAlign: 'right', mt: 0.5, color: 'text.secondary' }}
                  >
                    {(currentFrame.description || '').length}/250
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Right Sidebar */}
          <Grid item xs={state.portrait ? 12 : 3} sx={{ p: state.portrait ? 1 : 2, borderLeft: state.portrait ? 'none' : '1px solid #ccc', backgroundColor: 'white' }}>
            <Box>
              {/* <Divider sx={{ my: state.portrait ? 1 : 3, display: selectedLineId !== null && !state.portrait ? 'none' : 'block' }} /> */}
              <PlayBuilderActionButtons
                selectedPlayer={currentFrame.players.find((p) => p.id === selectedPlayerId) || null}
                createLine={createLine}
                handleCreateNewFrame={handleCreateNewFrame}
                setIsModalOpen={setIsModalOpen}
                handleDeleteLine={handleDeleteLine}
                handleToggleBall={handleToggleBall}
                handleSavePlay={handleSavePlay}
                handleColorChange={handleColorChange}
                colorOptions={colorOptions}
                selectedLine={selectedLine}
                toggleShape={toggleShape}
                isDoubleBezier={isDoubleBezier}
                selectedLineId={selectedLineId}
                handleAddOffensivePlayer={handleAddOffensivePlayer}
                handleAddDefender={handleAddDefender}
                handleDeletePlayer={handleDeletePlayer}
                currentFrame={currentFrame}
                selectedPlayerId={selectedPlayerId}
                hasConnectedLines={
                  selectedPlayerId !== null &&
                  currentFrame.lines.some((l) => l.fromId === selectedPlayerId || l.toId === selectedPlayerId)
                }
                handleAddCone={handleAddCone}
                handleDeleteCone={handleDeleteCone}
                selectedConeId={selectedConeId}
                handlePlayerColorChange={handlePlayerColorChange}
              />
            </Box>

            {currentFrame.lines.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Divider sx={{ my: 1 }} />
                <PlayAnimationLinesOrder
                  lines={currentFrame.lines}
                  players={currentFrame.players}
                  onUpdateLines={(updatedLines) =>
                    setFrames((prev) => {
                      const newFrames = [...prev];
                      newFrames[currentFrameIndex] = { ...newFrames[currentFrameIndex], lines: updatedLines };
                      return newFrames;
                    })
                  }
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Dialog>
      <SnackbarMessages open={snackbar.open} message={snackbar.message} color={snackbar.color} icon={snackbar.icon} />
      {isModalOpen && (
        <DialogPlayAnimation play={DRUPALENTITYINIT} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} frames={frames} courtType={courtType} />
      )}
    </>
  );
}