import { GridRowId, GridRowModel, GridRowsProp } from "@mui/x-data-grid";
import { LogLevelDesc } from "loglevel";
import { Frame } from "../components/DialogPlayBuilder";

// Object saved in user profile field field_ui_settings. We store settings for ui
export interface UISettings {
    showAttendance: boolean,                    // show attendance on Home
    showDialogTeamPlanStepperButton: boolean,   // show button on the practice calendar to start dialog practice plan stepper
    // 3 flags to show/not show certain details when practice program is show in screen
    showDialogPracticeProgramPrintDescription: boolean,
    showDialogPracticeProgramPrintNote: boolean,
    showDialogPracticeProgramPrintTeachingPoints: boolean,
    showButtonToOpenInvitePromotionMar25: boolean,    
}
export type UISettingKeys = keyof UISettings;   // declare so I can find key by string value without typescript error

export interface PlaybookURLAndCredentials {
    url: string,
    htaccess_uid: string,
    htaccess_pwd: string,
}

// TO DO value of field_user_type should be tested agains a string ENUM. Only four valid values: free, pro, club and clubadmin
export enum UserType {
    free = "free",
    pro = "pro",
    clubadmin = "clubadmin",
    club = "club",
}

export enum OperationMode {
    exercise = "exercise",
    play = "play",
    preplannedPractice = "preplannedPractice",
}

// Cached info from one run of app to next run of app in same browser
// Don't save SENSITIVE data
// Some data is saved as key only, ie. PracticeBetter holds a list of
// selected practices with the full exercise object. When store in local
// storage we only save the "drupal_internal__nid" This is done to keep
// local storage small
export interface LocalStorageICB {
    uid?: number,                                   // drupal internal user id
    showPracticeProgram?: boolean,
    curPractice?: PracticeBetter,
}

export interface Team {
    teamName: string,       // team name
    playerIDs: string[],    // list of uid of players on the team
}

export interface Attend {
    playerID: string,
    present: boolean,
    name?: string,          // use only for display, not for storing
}

export interface JSONAPIResponse {
    jsonapi: any,
    data: Array<DrupalEntity>,
    included?: Array<DrupalEntity>,
    links: {
        next?: { href: string },
        first?: { href: string },
        prev?: { href: string },
        self: { href: string },
    }
}

export interface JSONAPIResponseError {
    detail: string,
    status: string,
    title: string,
}

export interface DrupalEntity {
    type: string,
    id?: string,
    attributes?: any,
    relationships?: any,
    links?: any,
}

export interface DrupalEntityData {
    attributes?: any,
    relationships?: any,
}

// JSONAPI
export interface JSONAPITypeId {
    type: string,
    id: string,
    meta?: {
        drupal_internal__target_id?: string,
        description?: string,
    },
}

// Element in JSONAPI relationships structure with ref to name of field and file
export interface FileRelationship {
    // relationship: JSONAPITypeId,
    field: string,                  // relationship field
    file: File | null,              // new file for field
    remove: boolean,                // remove existing relation
}

export interface ICBConfirm {
    text?: string,                  // What to confirm
    buttonText?: string,            // Text on confirm button
    code: () => any,                // What to do on click of optional code execution button
}

export interface Upload {
    file: File,                     // File selected through file selection dialog box or image created with Board component
    field: string,                  // Entity field that relates to uploaded file
    fid?: string,                   // fid given once the file has been uploaded
}

export type ExercisePool = "icb" | "club" | "mine";
export interface TypeState {
    user: UserInfo,                         // info about logged on user
    loggedIn: number,                       // -1: initial value 0: not logged in 1: logged in 2: guest TO DO check this list
    allGroups: Array<DrupalEntity>,         // all exercise groups
    allFiles: Array<DrupalEntity>,          // all files uploaded to Drupal
    allUsers: Array<DrupalEntity>,          // all Drupal users
    allTeams: Array<DrupalEntity>,          // current users teams
    allPractices: Array<DrupalEntity>,      // practices read from backend
    allPreplannedPractices: Array<DrupalEntity>, // practice drafts read from backend
    allPlayers: Array<DrupalEntity>,        // players read from backend
    allConcepts: Array<DrupalEntity>,       // concepts read from backend
    allPlays: Array<DrupalEntity>,          // plays read from backend
    allPlaybooks: Array<DrupalEntity>,      // playbooks read from backend
    allExercises: Array<DrupalEntity>,      // all exercises under Drupal access control
    allTeamPlayers: Array<DrupalEntity>,    // all team players under Drupal access control
    allAttendances: Array<DrupalEntity>,    // all attendances under Drupal access control
    selectedGroup: DrupalEntity,            // selected exercise group
    selectedExercisePool: ExercisePool,     // show "icb", "club" or "mine" exercises
    curPractice: PracticeBetter,            // pracitice we currently work with
    // curPlay: DrupalEntity,                  // play we currently work with
    curPlaybook: DrupalEntity,              // playbook we currently work with
    curPreplannedPractice: PreplannedPractice         // practice draft we currently work with
    configuration: Array<DrupalEntity>,     // configuration read from backend
    backdrop: number,                       // if true show spinner
    debug: boolean,                         // debug set in backend configuration for current user
    logLevel: LogLevelDesc,                 // https://www.npmjs.com/package/loglevel
    confirm: ICBConfirm,                    // if confirm text has a value then confirmation dialog is displayed
    showPracticeProgram: boolean,           // show practice program next to pool of exercises
    obj?: any                               // place holder for misc info stored on global state
    portrait: boolean,                      // TEST of the varialble can replace portraitBlock and landscapeBlock
    showWelcomePresentation: boolean,       // Don't show welcome presentation more than once per run of app
    showIntro: boolean,                     // Show full blue background while we wait for app to start up
    exerciseIDLastCRUD?: string,            // set when user creates an exercise so we can put a cache blocker on the image
    clubInvite?: string,                    // club admin generates link to invite coach. Coach saves this clubInvite and uses it for signing up to club
    nativeApp: boolean,                     // called on iOS or Android
    fromApp: string,                        // value from native app
    eventBeforeinstallprompt?: Event,       // capture beforeinstallprompt event
    // exercisesRetrivedInitially: boolean,    // flag to show that exercises have been retrieved after app start
    fetchController: AbortController,       // stop running fetches when user logs out
    fetchControllerSignal?: any,            // signal we put on fetches so running fetches can be aborted when user logs out
    formFields?: any,                       // value of form fields from one show of form to next show of form
    CRUDListSelectedValues?: Array<string>, // last set of selections in CRUDList - reset right after use
    awaitPaymentProcessing: boolean,        // true if we come back from Stripe Checkout and payment has not been registered
    uiSettings: UISettings,                 // JSON text string in user profile field_ui_settings converted to an object
    field_defaults: any,                    // JSON text string in user profile field_default converted to an object
    practicesRetrieved: boolean,            // set this flag when practices have been retrieved so we can update status text on the practices calendar
    operationMode: OperationMode,           // show exercises or plays
    appBarHeight: number,
    appBarShowTabs: boolean,
    // selectedExercisesForPracticeDraft: ExerciseSelected[]   // list of selected exercises for the practice draft we currently work with
    fetchData: boolean,                     // false -> true - start initial fetch of data
}

export interface TypeContext {
    state: TypeState,
    dispatch: React.Dispatch<any>,
}

// Exercise
export interface ExerciseSelected {
    exercise: DrupalEntity,         // exercise as Drupal Entity
    coachNote: string,              // user input for each selected exercise
    // uuidListElement: string,        // managed by app - random id to identify exercise in list of selected exercises
    durationMinutes: number,        // user input for each selected exercise
    drupal_internal__nid: number    // value to and from back end
}

// Type returned from backend on successfull login
export interface TypeLogin {
    "current_user": {
        "uid": number,
        "name": string,
    },
    "csrf_token": string,
    "logout_token": string,
}

// Current user. Could be Anonymous if nobody is logged in
export interface UserInfo {
    login: TypeLogin,               // return info from Drupal login
    data: DrupalEntity,             // user data from Drupal backend
    locale: string,                 // locale selected on the login page. Default to cookie value
}

export interface PropsExerciseCard {
    exercise: DrupalEntity,                // exercise on ICB, Club or My exercises
}

export interface PropsPlayCard {
    play: DrupalEntity,
    index?: number,     // number in selected list of plays - controls what buttons are displayed on PlayCard
}

// Control types on MUI datagrid
export enum DataGridActions {
    create,
    save,
    cancel,
    edit,
    delete,
    up,
    down,
    select,
    print,
    viewClubDocument,
    download,
    mailTo,
}

// Data to/from MUI datagrid and database update call. Update based on all rows, ie rows, or single row, ie. row
export interface DataGridUpdate {
    rows: GridRowsProp,        // rows in MUI datagrid
    newRow?: GridRowModel,     // row to update. Undef if we update based on the entire dataset, ie. rows
    id?: GridRowId,            // if of row to delete, if any!
    delete?: boolean           // true if we delete row
}

export interface PropsExerciseCardSelected {
    exerciseSelected: ExerciseSelected,    // exercise on practice program or printed forms
    startAndStopTime: string,              // show start and stop time on practice program
    index: number,                         // exercise number on printed program
    displayOnly: boolean,                  // on small screen set to true to illustrate practice program and not edit practice program
    upArrow?: boolean,                     // display up arrow to move exercise up
    dnArrow?: boolean,                     // display down arrow to move exercise down
    sourceList: ExerciseSelected[],        // list of selected practices or practice drafts depending on operation mode
}

export interface CollectBeforeStateChange {
    nodes: Array<DrupalEntity>,                 // push nodes to this array as we go trough paginations of nodes
    included: Array<DrupalEntity>,              // push related nodes to this array as we go trough paginations of nodes
}

export interface ActionSetLoggedIn { type: 'setLoggedIn', userType: number }
export interface ActionSetSelectedExercisePool { type: 'setSelectedExercisePool', pool: ExercisePool }
export interface ActionSetLocale { type: 'setLocale', locale: string, }
export interface ActionSetSelectedExercises { type: 'setSelectedExercises', selectedExercises: Array<ExerciseSelected>, }
export interface ActionDropExerciseOnExercise { type: 'dropExerciseOnExercise', exercise_src: ExerciseSelected, exercise_dst: ExerciseSelected }
export interface ActionSetUserData { type: 'setUserData', userData: DrupalEntity }
export interface ActionSetSelectedGroup { type: 'setSelectedGroup', group: DrupalEntity }
export interface ActionSetState { type: 'setState', state: TypeState }
export interface ActionSetBackdrop { type: 'setBackdrop', diff: number }
export interface ActionSetPractice { type: 'setPractice', practice: PracticeBetter }
export interface ActionSetPreplannedPractice { type: 'setPreplannedPractice', preplannedPractice: PreplannedPractice }
export interface ActionSetPlay { type: 'setPlay', play: DrupalEntity }
export interface ActionSetPlaybook { type: 'setPlaybook', playbook: DrupalEntity }
export interface ActionSetConfirm { type: 'setConfirm', confirm: ICBConfirm }
export interface ActionShowPracticeProgram { type: 'showPracticeProgram', show: boolean }
export interface ActionSaveOnGlobalState { type: 'setOnGlobalState', obj: any }
export interface ActionSortGroups { type: 'sortGroups' }
export interface ActionSortExercises { type: 'sortExercises' }
export interface ActionResizeOrRotate { type: 'resizeOrRotate', portrait: boolean }
export interface ActionShowWelcomePresentation { type: 'showWellcomePresentation', show: boolean }
export interface ActionSetLogin { type: 'setLogin', login: TypeLogin }
export interface ActionSetShowIntro { type: 'setShowIntro', show: boolean }
export interface ActionGoHome { type: 'goHome' }
export interface ActionSetConfiguration { type: 'setConfiguration', configuration: DrupalEntity[] }
export interface ActionSetExerciseIDLastCRUD { type: 'setExerciseIDLastCRUD', id: string }
export interface ActionSetClubInvite { type: 'setClubInvite', clubInvite: string }
export interface ActionSetPracticeProgramDirty { type: 'setPracticeProgramDirty', dirty: false }
export interface ActionSetFromApp { type: 'setFromApp', fromApp: string }
export interface ActionSetCRUDListSelectedValues { type: 'setCRUDListSelectedValues', selectedValues: Array<string> }
export interface ActionSetAwaitPaymentProcessing { type: 'setAwaitPaymentProcessing', await: boolean }
export interface ActionSetContentEntity { type: 'setContentEntity', contentType: string, data: DrupalEntity[], initialLoad: boolean }
export interface ActionDelContentEntity { type: 'delContentEntity', contentType: string, id: string }
export interface ActionSetPracticeID { type: 'setPracticeID', practiceID: string }
export interface ActionSetPracticesRetrieved { type: 'setPracticesRetrieved' }
export interface ActionSetOperationMode { type: 'setOperationMode', operationMode: OperationMode }
export interface ActionSetUISettings { type: 'setUISettings', uiSettings: UISettings }
export interface ActionSetAppBarHeight { type: 'setAppBarHeight', height: number }
export interface ActionSetAppBarShowTabs { type: 'setAppBarShowTabs', show: boolean }
export interface ActionSetFetchData { type: 'setFetchData', fetch: boolean }

export type Action = ActionSetLoggedIn
    | ActionSetLocale
    | ActionSetSelectedExercises
    | ActionSetSelectedExercisePool
    | ActionSetSelectedGroup
    | ActionDropExerciseOnExercise
    | ActionSetState
    | ActionSetUserData
    | ActionSetBackdrop
    | ActionSetPractice
    | ActionSetPreplannedPractice
    | ActionShowPracticeProgram
    | ActionSaveOnGlobalState
    | ActionSortGroups
    | ActionSortExercises
    | ActionResizeOrRotate
    | ActionShowWelcomePresentation
    | ActionSetLogin
    | ActionSetShowIntro
    | ActionSetConfirm
    | ActionGoHome
    | ActionSetConfiguration
    | ActionSetExerciseIDLastCRUD
    | ActionSetClubInvite
    | ActionSetPracticeProgramDirty
    | ActionSetFromApp
    | ActionSetCRUDListSelectedValues
    | ActionSetAwaitPaymentProcessing
    | ActionSetContentEntity
    | ActionDelContentEntity
    | ActionSetPracticeID
    | ActionSetPracticesRetrieved
    | ActionSetOperationMode
    | ActionSetUISettings
    | ActionSetPlay
    | ActionSetPlaybook
    | ActionSetAppBarHeight
    | ActionSetAppBarShowTabs
    | ActionSetFetchData

// See https://www.typescriptlang.org/docs/handbook/declaration-files/do-s-and-don-ts.html
export interface Fetcher {
    getObject(done: (data: unknown, elapsedTime: number) => void): void;
}

export enum AccountAction {
    login = 'login',
    signup = 'signup',
    getnewpassword = 'getnewpassword',
    resendemailverificationcode = 'resendemailverificationcode',
}

export enum GridRowActions {
    save = 'save',
    cancel = 'cancel',
    up = 'up',
    down = 'down',
}

export enum EnvironmentType {
    test = 'test',
    prod = 'prod',
    dev = 'dev',
    unknown = 'unknown',
}

// Type of button information we put in a list which we them map to show buttons
export interface buttonInList {
    startIcon?: React.ReactNode,
    label: string,
    noShow?: boolean,
    color?: 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | string,
    action?: () => void,
    exercisePool?: ExercisePool,
    toggleValue?: string,
}

export enum CRUD {
    Create = "Create",
    Update = "Update",
    Delete = "Delete",
}

export enum TeamplanAgeGroup {
    u8 = 'u8',
    u10 = 'u10',
    u12 = 'u12',
}

export enum TeamplanSkillLevel {
    beginner = "beginner",
    intermediate = "intermediate",
    advanced = "advanced",
}

export enum TeamplanDuration {
    m45 = "m45",
    m60 = "m60",
    m75 = "m75",
    m90 = "m90",
}

export interface PreplannedPractice {
    uuid?: string,                           // UUID (Universally Unique Identifier) - if set then existing PracticeDraft
    note: string,
    teamplanAgeGroup: TeamplanAgeGroup,
    teamplanSkillLevel: TeamplanSkillLevel,
    teamplanDuration: TeamplanDuration,
    selectedExercises: ExerciseSelected[],
}

/*
Try this one and not the two ones above.
Restructure now that node type team contains list of referenced practices
*/
export interface PracticeBetter {
    // Fields stored in the node type practice in JSON string
    date: Date,
    note: string,
    selectedExercises: ExerciseSelected[],
    attendance: Attend[],
    // Fields added when needed. Add on fields for reports etc
    practiceID?: string,        // If we have this value then we work on an existing practice. Otherwise, new practice. Practice Builder always handles new practices
    display_name?: string,
    team: DrupalEntity,         // Fast access to related team. We always have a related team
    dirty?: boolean,            // Practice program has been edited
    uid: string,                // uuid for user who created practice
}

// structure of data we sent to back end to create a sharable playbook
export interface PlaybookSharable {
    playbookTime: string,
    plays: Array<{
        nid: number,
        frames: Frame[],
        svgContent: string,
        animationScript: string,
    }>
}
