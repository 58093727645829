import { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, } from '@mui/material';
import PlayAnimationHTMLDoc from './PlayAnimationHTMLDoc';
import { DrupalEntity, TypeContext } from '../misc/Types';
import log from "../misc/Logger";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Context } from '../App';

interface Frame {
  id: number;
  players: any[];
  lines: any[];
  cones: any[];
}

interface PropsDialogPlayAnimation {
  play: DrupalEntity,
  isOpen: boolean,
  onClose: () => void,
  frames: Frame[],
  courtType: 'halfcourt' | 'fullcourt',
  autoDownload?: boolean,
}

export default function DialogPlayAnimation({ play, isOpen, onClose, frames, courtType, autoDownload }: PropsDialogPlayAnimation) {
  const { state } = useContext(Context) as TypeContext;
  log.debug('DialogPlayAnimation');

  const [modalContentUrl, setModalContentUrl] = useState('');

  useEffect(() => {
    if (isOpen) {

      // WE CALL THE HTML DOC FROM PLAYANIMATIONHTMLDOC.TSX, AND WE PASS THE FRAMES AND COURT TYPE
      const HTMLDoc = PlayAnimationHTMLDoc(frames, courtType, play, state.nativeApp, autoDownload);
      const blob = new Blob([HTMLDoc], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      setModalContentUrl(url);
    } else {
      if (modalContentUrl) {
        URL.revokeObjectURL(modalContentUrl);
        setModalContentUrl('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, frames]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="animation-modal-title"
      aria-describedby="animation-modal-description"
      PaperProps={{
        style: {
          borderRadius: 16,
          maxWidth: '600px',
          maxHeight: '650px',
          width: '99%',
          height: '99%',
          margin: 1
        },
      }}
    >

      <DialogTitle fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {play.attributes.title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          edge="end"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: '6px 12px' }}>
        <iframe
          src={modalContentUrl}
          style={{ width: '100%', height: '100%', border: 'none', padding: '0' }} // Asegurar que el iframe no tenga padding
          title="Animation"
        />
      </DialogContent>

    </Dialog >
  );
};
