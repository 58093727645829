import { Typography, Button, IconButton, Box, Divider, darken } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useContext } from 'react';
import { DrupalEntity, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { useTranslation } from 'react-i18next';
import log from "../misc/Logger";

interface PropsPhasesSectionPlayBuilder {
    play: DrupalEntity,
    frames: any[];
    currentFrameIndex: number;
    handleSelectFrame: (index: number) => void;
    handleDeleteFrame: (index: number) => void;
    handleCreateNewFrame: () => void;
}

export default function PhasesSectionPlayBuilder({ play, frames, currentFrameIndex, handleSelectFrame, handleDeleteFrame, handleCreateNewFrame }: PropsPhasesSectionPlayBuilder) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('PhasesSectionPlayBuilder');

    return (
        <div>
            {!state.portrait ? (
                <>
                    <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center', color: '#00398f' }}>
                        {t('DialogPlayBuilder01')}
                    </Typography>
                    {frames.map((frame, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => handleSelectFrame(index)}
                                sx={{
                                    flex: 1,
                                    textTransform: 'none',
                                    border: '1px solid #ccc',
                                    color: index === currentFrameIndex ? 'black' : 'gray',
                                    backgroundColor: index === currentFrameIndex ? '#f4f4f4' : 'white',
                                    '&:hover': {
                                        border: '1px solid #999',
                                        backgroundColor: '#f4f4f4',
                                    },
                                }}
                            >
                                {t('PlayCard00')} {frame.id}
                            </Button>

                            <IconButton
                                onClick={() => handleDeleteFrame(index)}
                                size="small"
                                sx={{
                                    color: '#b0bec5',
                                    '&:hover': {
                                        color: darken('#b0bec5', 0.3),
                                    },
                                }}
                            >
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Divider sx={{ marginTop: '10px' }} />

                    <Button style={{ color: '#00398f', borderColor: '#00398f', marginTop: '10px' }} fullWidth variant="outlined" onClick={handleCreateNewFrame}>
                        {t('DialogPlayBuilder02')}
                    </Button>
                </>
            ) : (
                <>
                    <Typography style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '5px', color: '#00398f' }}>
                        {play.attributes.title}
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            maxWidth: '600px',
                            margin: 'auto',
                            paddingBottom: '8px', // Ajuste según tus necesidades
                        }}
                    >
                        {/* Grupo de Navegación: Arrow Back, Phase, Arrow Forward */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={() => handleSelectFrame(currentFrameIndex - 1)}
                                size="small"
                                style={{
                                    color: currentFrameIndex === 0 ? '#b0bec5' : '#00398f',
                                    width: '40px',
                                    height: '40px',
                                    padding: '8px',
                                }}
                                disabled={currentFrameIndex === 0}
                            >
                                <KeyboardArrowLeftRoundedIcon />
                            </IconButton>


                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: 'bold',
                                    margin: '0 8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'black'
                                }}
                            >
                                {t('PlayCard00')} {frames[currentFrameIndex].id}
                            </Typography>

                            <IconButton
                                onClick={() => handleSelectFrame(currentFrameIndex + 1)}
                                size="small"
                                style={{
                                    color: currentFrameIndex === frames.length - 1 ? '#b0bec5' : '#00398f',
                                    width: '40px',
                                    height: '40px',
                                    padding: '8px',
                                }}
                                disabled={currentFrameIndex === frames.length - 1}
                            >
                                <KeyboardArrowRightRoundedIcon />
                            </IconButton>
                        </div>

                        {/* Botón "Next Phase" */}
                        <Button
                            onClick={handleCreateNewFrame}
                            size="small"
                            variant="outlined"
                            sx={{
                                backgroundColor: 'white',
                                color: '#00398f',
                                borderColor: '#00398f',
                                '&:hover': {
                                    backgroundColor: '#f4f4f4',
                                    color: '#00398f',
                                    borderColor: '#00398f',
                                },
                            }}
                        >
                            {t('DialogPlayBuilder02')}
                        </Button>

                        {/* Delete frame button */}
                        <IconButton
                            onClick={() => handleDeleteFrame(currentFrameIndex)}
                            size="small"
                            sx={{
                                color: '#b0bec5',
                                width: '40px',
                                height: '40px',
                                padding: '8px',
                                marginLeft: 'auto',
                                '&:hover': {
                                    color: darken('#b0bec5', 0.3),
                                },
                            }}
                        >
                            <DeleteOutlineRoundedIcon />
                        </IconButton>
                    </Box>
                </>
            )}
        </div>
    );
}
