import { Button, Typography, Grid, Box, darken, Divider, IconButton } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { useTranslation } from 'react-i18next';
import { Player, Line, Frame } from './DialogPlayBuilder';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import PolylineRoundedIcon from '@mui/icons-material/PolylineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { BsCone } from 'react-icons/bs';

interface ActionButtonsProps {
    selectedPlayer: Player | null;
    createLine: (type: 'straight' | 'zigzag' | 'dashed' | 'bar' | 'shot') => void;
    handleCreateNewFrame: () => void;
    setIsModalOpen: (isOpen: boolean) => void;
    handleDeleteLine: () => void;
    handleToggleBall: () => void;
    handleSavePlay: () => void;
    hasConnectedLines: boolean;
    handleColorChange: (color: string) => void;
    colorOptions: { value: string }[];
    selectedLine?: Line | null;
    toggleShape: () => void;
    isDoubleBezier: boolean;
    selectedLineId: number | null;
    handleAddOffensivePlayer: (num: number) => void;
    handleAddDefender: (num: number) => void;
    handleDeletePlayer: () => void;
    currentFrame: Frame;
    selectedPlayerId: number | null;
    handleAddCone: (color: string) => void;
    handleDeleteCone: () => void;
    selectedConeId: number | null;
    handlePlayerColorChange: (color: string) => void;
}

export default function PlayBuilderActionButtons({
    selectedPlayer,
    createLine,
    handleDeleteLine,
    handleToggleBall,
    handleColorChange,
    colorOptions,
    selectedLine,
    toggleShape,
    isDoubleBezier,
    selectedLineId,
    handleAddOffensivePlayer,
    handleAddDefender,
    handleDeletePlayer,
    selectedPlayerId,
    handleAddCone,
    handleDeleteCone,
    selectedConeId,
    handlePlayerColorChange,
}: ActionButtonsProps) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();

    const coneColors = [
        { name: 'Black', value: '#000000' },
        { name: 'Blue', value: '#0000FF' },
        { name: 'Red', value: '#FF0000' },
        { name: 'Green', value: '#008000' },
    ];

    const commonButtonStyles = {
        textTransform: 'none',
        fontWeight: 'bold',
    };

    const colorBoxStyles = (selectedColor: string | undefined, currentColor: string) => ({
        backgroundColor: currentColor,
        width: 25,
        height: 25,
        border: `2px solid ${selectedColor === currentColor ? '#000' : 'transparent'}`,
        borderRadius: 2,
        cursor: 'pointer',
        '&:hover': {
            border: `2px solid ${selectedColor === currentColor ? '#000' : '#666'}`,
        },
    });

    return (
        <Box sx={{ mb: state.portrait && state.nativeApp ? 150 : 0 }}>
            {/* Selected Line Controls */}
            {selectedLineId !== null && (
                <>
                    <Typography sx={{ mb: 1, mt: 1, fontWeight: 'bold', fontSize: { xs: '18px', sm: '22px' }, color: 'black' }}>
                        {t(`ActionButtons0${selectedLine?.type === 'dashed' ? 3 : selectedLine?.type === 'zigzag' ? 2 : selectedLine?.type === 'bar' ? 4 : selectedLine?.type === 'shot' ? 5 : 1}`)}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                        {colorOptions.map((color) => (
                            <Box
                                key={color.value}
                                component="button"
                                onClick={() => handleColorChange(color.value)}
                                sx={colorBoxStyles(selectedLine?.color, color.value)}
                            />
                        ))}
                        <IconButton
                            onClick={toggleShape}
                            sx={{ ml: 'auto', width: 30, height: 30, border: '1px solid #ccc', borderRadius: 1 }}
                        >
                            {isDoubleBezier ? <TimelineRoundedIcon style={{ color: 'gray' }} /> : <PolylineRoundedIcon style={{ color: 'gray' }} />}
                        </IconButton>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleDeleteLine}
                            startIcon={<DeleteRoundedIcon />}
                            sx={{ ...commonButtonStyles, height: 30, minWidth: { sm: 100 } }}
                        >
                            {state.portrait ? t('ActionButtons08') : `${t('ExerciseCreate09')} ${t(`ActionButtons0${selectedLine?.type === 'dashed' ? 3 : selectedLine?.type === 'zigzag' ? 2 : selectedLine?.type === 'bar' ? 4 : 1}`)}`}
                        </Button>
                    </Box>
                    <Divider sx={{ my: 3, display: { xs: 'none', sm: 'block' } }} />
                </>
            )}

            {/* Player Color Selection */}
            {selectedPlayerId !== null && (
                <>
                    <Typography sx={{ mb: 1, mt: 1, fontWeight: 'bold', fontSize: { xs: '18px', sm: '22px' }, color: 'black' }}>
                        {t('ActionButtons13')} {selectedPlayer?.isDefender ? `x${selectedPlayer.number}` : selectedPlayer?.number}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                        {colorOptions.map((color) => (
                            <Box
                                key={color.value}
                                component="button"
                                onClick={() => handlePlayerColorChange(color.value)}
                                sx={colorBoxStyles(selectedPlayer?.color, color.value)}
                            />
                        ))}
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleDeletePlayer}
                            startIcon={<DeleteRoundedIcon />}
                            sx={{ ...commonButtonStyles, ml: 'auto', height: 30, minWidth: { sm: 100 }, mt: { xs: 0.5, sm: 0 } }}
                        >
                            {t('ExerciseCreate09')}
                        </Button>
                    </Box>
                    <Divider sx={{ my: 3, display: { xs: 'none', sm: 'block' } }} />
                </>
            )}

            {/* Cone Selection */}
            {selectedConeId !== null && (
                <>
                    <Typography sx={{ mb: 1, mt: 1, fontWeight: 'bold', fontSize: { xs: '18px', sm: '22px' }, color: 'black' }}>
                        {t('ActionButtons12')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleDeleteCone}
                            startIcon={<DeleteRoundedIcon />}
                            sx={{ ...commonButtonStyles, height: 30, minWidth: { sm: 100 } }}
                        >
                            {t('ExerciseCreate09')}
                        </Button>
                    </Box>
                    <Divider sx={{ my: 3, display: { xs: 'none', sm: 'block' } }} />
                </>
            )}

            {/* Action Buttons */}
            <Box sx={{ mb: 1, mt: state.portrait ? 1 : 0 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16, mb: 1, display: { xs: 'none', sm: 'block' }, color: 'black' }}>
                    {t('ActionButtons00')}
                </Typography>
                <Grid container spacing={1}>
                    {[
                        { type: 'straight', label: '01', icon: '/images/playbuilder/straightLight.svg' },
                        { type: 'zigzag', label: '02', icon: '/images/playbuilder/zigzagLight.svg' },
                        { type: 'dashed', label: '03', icon: '/images/playbuilder/dashedLight.svg' },
                        { type: 'bar', label: '04', icon: '/images/playbuilder/screenLight.svg' },
                    ].map(({ type, label, icon }) => (
                        <Grid item xs={6} key={type}>
                            <Button
                                variant="contained"
                                size={state.portrait ? 'small' : 'medium'}
                                fullWidth
                                onClick={() => createLine(type as any)}
                                sx={{
                                    ...commonButtonStyles,
                                    bgcolor: '#00398f',
                                    color: 'white',
                                    '&:hover': { bgcolor: darken('#00398f', 0.1) },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={icon} alt={`${type} Line`} style={{ width: 40, marginRight: 10 }} />
                                {t(`ActionButtons${label}`)}
                            </Button>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                size={state.portrait ? 'small' : 'medium'}
                                onClick={() => createLine('shot')}
                                sx={{
                                    ...commonButtonStyles,
                                    flex: '0 0 40%', // 40% width
                                    bgcolor: '#DD620f',
                                    color: 'white',
                                    '&:hover': { bgcolor: darken('#DD620f', 0.1) },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {t('ActionButtons11')}
                            </Button>
                            <Button
                                variant="outlined"
                                size={state.portrait ? 'small' : 'medium'}
                                onClick={handleToggleBall}
                                sx={{
                                    ...commonButtonStyles,
                                    flex: '0 0 60%', // 60% width
                                    color: '#DD620f',
                                    bgcolor: 'white',
                                    borderColor: '#DD620f',
                                    '&:hover': { bgcolor: '#f9f9f9' },
                                }}
                                startIcon={<SportsBasketballRoundedIcon />}
                            >
                                {selectedPlayer?.hasBall ? t('ActionButtons06') : t('ActionButtons05')}
                            </Button>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                    </Grid>
                </Grid>

                {/* Elements Section */}
                <Box sx={{ mt: { xs: 0, sm: 2 }, ml: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, mb: 1, letterSpacing: '0.5px', color: 'black' }}>
                        {t('ActionButtons14')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {[...Array(6)].map((_, i) => i + 1).map((num) => (
                            <Button
                                key={num}
                                variant="outlined"
                                draggable
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('type', 'offensive');
                                    e.dataTransfer.setData('number', num.toString());
                                }}
                                onClick={() => handleAddOffensivePlayer(num)}
                                sx={{
                                    ...commonButtonStyles,
                                    width: 30,
                                    height: 30,
                                    minWidth: 0,
                                    p: 0,
                                    borderRadius: 1,
                                    bgcolor: 'white',
                                    color: '#00398f',
                                    borderColor: '#f1f1f1',
                                    '&:hover': { bgcolor: '#f4f4f4' },
                                    fontSize: 16,
                                }}
                            >
                                {num}
                            </Button>
                        ))}
                        <Button
                            variant="outlined"
                            draggable
                            onDragStart={(e) => {
                                e.dataTransfer.setData('type', 'offensive');
                                e.dataTransfer.setData('number', '0');
                            }}
                            onClick={() => handleAddOffensivePlayer(0)}
                            sx={{
                                ...commonButtonStyles,
                                width: 30,
                                height: 30,
                                minWidth: 0,
                                p: 0,
                                borderRadius: 1,
                                bgcolor: 'white',
                                color: '#00398f',
                                borderColor: '#f1f1f1',
                                '&:hover': { bgcolor: '#f4f4f4' },
                                fontSize: 16,
                            }}
                        >
                            C
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                        {[...Array(6)].map((_, i) => i + 1).map((num) => (
                            <Button
                                key={num}
                                variant="outlined"
                                draggable
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('type', 'defender');
                                    e.dataTransfer.setData('number', num.toString());
                                }}
                                onClick={() => handleAddDefender(num)}
                                sx={{
                                    ...commonButtonStyles,
                                    width: 30,
                                    height: 30,
                                    minWidth: 0,
                                    p: 0,
                                    borderRadius: 1,
                                    bgcolor: 'white',
                                    color: 'red',
                                    borderColor: '#f1f1f1',
                                    '&:hover': { bgcolor: '#f4f4f4' },
                                    fontSize: 16,
                                }}
                            >
                                {`x${num}`}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                        {coneColors.map((cone) => (
                            <Button
                                key={cone.value}
                                variant="outlined"
                                draggable
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('type', 'cone');
                                    e.dataTransfer.setData('color', cone.value);
                                }}
                                onClick={() => handleAddCone(cone.value)}
                                sx={{
                                    ...commonButtonStyles,
                                    width: 30,
                                    height: 30,
                                    minWidth: 0,
                                    p: 0,
                                    borderRadius: 1,
                                    bgcolor: 'white',
                                    borderColor: cone.value,
                                    '&:hover': { borderColor: darken(cone.value, 0.1), bgcolor: '#f4f4f4' },
                                }}
                            >
                                <BsCone size={24} color={cone.value} />
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}