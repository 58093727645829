import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ThemeContextProvider } from './theme/ThemeContextProvider.tsx'

// // used in conjuction with VitePWA, registerType: 'autoUpdate'
// import { registerSW } from 'virtual:pwa-register'
// registerSW({ immediate: true })

import { registerSW } from 'virtual:pwa-register'

// Register the service worker and handle updates
// 1. if on login screen on last version and refresh then you get latest version
// 2. start browser and start app, login show prev. version, login updates automatically and show latest version
// if on login screen on last version and refresh then you get latest version
const updateSW = registerSW({
  onNeedRefresh() {
    // Force update immediately
    updateSW(true)
    // console.log('onNeedRefresh(), tps, apache conf update to prevent cache of sw.js mk vers 10,11')
    alert('getting new version . . .')
  },
  onOfflineReady() {
    // Optional: show a toast or message that the app is ready offline
    console.log('onOfflineReady()')
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeContextProvider>
    {/* <React.StrictMode> */}
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </ThemeContextProvider>
)
