import { useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { OperationMode, PlaybookURLAndCredentials, TypeContext } from "../misc/Types";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DialogPlaybook from "./DialogPlaybook";
import AddBUttonAboveLists from "./AddButtonAboveList";

export default function MyPlaybooks() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    log.debug('Playbooks');
    const theme = useTheme();

    const [rows, setRows] = useState(getRows()) // get rows from back end initailly
    const [openDialogPlaybook, setOpenDialogPlaybook] = useState(false);

    // get rows from back end initailly
    useEffect(() => {
        setRows(getRows())
    }, [state.allPlaybooks])

    // get rows from back end
    function getRows() {
        return state.allPlaybooks.map((item) => {
            const field_url_and_credentials: PlaybookURLAndCredentials = JSON.parse(item.attributes.field_url_and_credentials)
            // let urlWithCredentials = ''
            // if (field_url_and_credentials) {
            //     // Build URL with htaccess userid and password for easy access
            //     const BACKENDSplit = BACKEND.split('https://')
            //     urlWithCredentials = `https://${field_url_and_credentials.htaccess_uid}:${field_url_and_credentials.htaccess_pwd}@${BACKENDSplit[1]}/${field_url_and_credentials.url}`
            // }
            return {
                id: item.id,
                playbookName: item.attributes.title,
                profileUrl: field_url_and_credentials ? t('MyPlaybooks02') : t('MyPlaybooks03') // For now we don't use the calculated urlWithCredentials
            }
        })
    }

    function columns(): GridColDef[] {
        return [
            { field: 'id', headerName: 'ID', width: 10 },
            { field: 'playbookName', headerName: t('MyPlaybooks00'), editable: false, flex: 1 },
            { field: 'profileUrl', headerName: t('MyPlaybooks04'), editable: false, flex: 1 },
            // code to have links in columns
            // {
            //     field: 'profileUrl',
            //     headerName: t('Published Playbook'),
            //     flex: 1,
            //     renderCell: (params) => {
            //         if (params.value === t('Not published'))
            //             return (
            //                 <Fragment>{t('Not published')}</Fragment>
            //             )
            //         else
            //             return (
            //                 <Link href={params.value} target="_blank" rel="noopener noreferrer">
            //                     {params.value}
            //                 </Link>
            //             )
            //     },
            // },
        ]
    }

    // row clicked. Set global playbook and show playbook builder
    const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
        // navigate('/playbook')
        dispatch({ type: 'setPlaybook', playbook: state.allPlaybooks.find(x => x.id === params.row.id)! });
        // dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
        // dispatch({ type: 'showPracticeProgram', show: true });
        // navigate('/icbplays');
        setOpenDialogPlaybook(true)
    };

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('MyPlaybooks01')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* Create play button */}
            {/* <Button
                variant="outlined"
                size="small"
                sx={{ marginBottom: 3 }}
                onClick={() => {
                    dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
                    dispatch({ type: 'showPracticeProgram', show: true });
                    navigate('/icbplays');
                }}
            >
                {t('ICBAppBar14')}
            </Button> */}
            <AddBUttonAboveLists
                buttonText={t('ICBAppBar14')}
                addCode={() => {
                    dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
                    dispatch({ type: 'showPracticeProgram', show: true });
                    navigate('/icbplays');
                }}
            />

            <DataGrid
                rows={rows}
                columns={columns()}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                onRowClick={handleRowClick}
                rowHeight={30}
                sx={{ marginTop: 3 }}
            />
            <DialogPlaybook
                open={openDialogPlaybook}
                onClose={() => setOpenDialogPlaybook(false)}
            />
        </Box>
    )
}