import Box from '@mui/material/Box';
import { Context } from '../App';
import { BACKEND } from '../misc/Constants';
import { DrupalEntity, PracticeBetter, TypeContext, UserType } from '../misc/Types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { getActionSetConfirm, getFileICBLogo, getPracticeTex, icbControllerGenerel02, mapPractice, mondaySunday, randomId } from '../misc/Functions';
import log from "../misc/Logger";
import UserPictureAndName from './UserPictureAndName';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';
import lescape from 'escape-latex'
import DialogMyContacts from './DialogMyContacts';
import DateFromDateTo from './DateFromDateTo';
import AttendanceReport from './AttendanceReport';

// Data we hold in form
interface TypeFormGetPracticeReport {
    uid: Number[],              // list of uids to include in practice report
    date_from: Date,            // string,
    date_to: Date,              // string,
    fileName: string,           // unique file name generated by the client. Name of file to download
    exerciseTable: boolean,     // include table with exercises in practice report
    deliveryMethod: string,     // email or download
    reportType: string,         // time or practices - type of report to generate
}

// expand type DrupalEntity with a field selected type boolean
type DrupalEntitySelected = DrupalEntity & {
    selected: boolean;
}

export default function FormGetPracticeReport() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    log.debug('FormGetPracticeReport');
    const theme = useTheme();

    const [openDialogMyContacts, setOpenDialogMyContacts] = useState(false)
    const [selectAll, setSelectAll] = useState(true);
    const [coachesCheckBox, setCoachesCheckBox] = useState(Array<DrupalEntitySelected>);

    const [monday, sunday] = mondaySunday()

    // Run when we know number of coaches in club
    useEffect(() => {
        // For clubadmin - list of all coaches in club including clubadmin. For other user types just a list of logged on user.
        // In the first case all elements are not selected. In the second case the single element is selected.
        setCoachesCheckBox(
            state.allUsers.filter(x => state.user.data.relationships.field_club_coaches.data.map((y: DrupalEntity) => y.id).includes(x.id) || x.id === state.user.data.id)
                .map((coach) => ({ ...coach, selected: state.user.data.attributes.field_user_type !== UserType.clubadmin })));
    }, [state.user.data])

    // create list of all practices read by the user at app start and decode practices. Filter by selected coaches and once decoded then filter by start and end date
    function getPracticesForReport(): Array<PracticeBetter> {
        const practicesForReport: Array<PracticeBetter> = state.allPractices
            // convert to PracticeBetter structure
            .map(x => mapPractice(state, x))
            // filter on date interval and selected coaches
            .filter(y => new Date(y.date) >= new Date(formData.date_from)
                && new Date(y.date) <= new Date(formData.date_to)
                && coachesCheckBox.filter(w => w.selected).map(z => z.id).includes(y.uid))
            .sort(compareFn)
        return practicesForReport
    }

    // function endOfDay(day: Date): Date {
    //     day.setHours(23, 59, 59)
    //     return day
    // }

    // Init form data
    let formDataObj: TypeFormGetPracticeReport = JSON.parse(JSON.stringify({
        date_from: monday,
        date_to: sunday,
        fileName: randomId(),
        exerciseTable: false,
        deliveryMethod: 'mail',
        reportType: 'practices'
    }));
    const [formData, setFormData] = useState(formDataObj)
    useEffect(() => {
        setFormData(formDataObj);
    }, [state.user.data.attributes.drupal_internal__uid])

    const handleChange = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value })
    };

    // Add or remove coach from list of coaches to get practice reports for
    function handleClickCoach(index: number) {
        let coachesCheckBoxLocal: any[] = JSON.parse(JSON.stringify(coachesCheckBox));
        coachesCheckBoxLocal[index].selected = !coachesCheckBoxLocal[index].selected;
        setCoachesCheckBox(coachesCheckBoxLocal);
    }

    function selectAllCoaches(select: boolean) {
        if (select) {
            setCoachesCheckBox(coachesCheckBox.map((coach) => ({ ...coach, selected: true })))
        } else {
            setCoachesCheckBox(coachesCheckBox.map((coach) => ({ ...coach, selected: false })))
        }
    }

    // Show fields only relevant for the club admin
    function clubAdmin() {
        if (state.user.data.attributes.field_user_type === 'clubadmin')
            return (
                <Grid style={{ width: '100%', maxWidth: '700px', margin: 'auto', marginTop: 15 }}>
                    <Typography sx={{ marginTop: 2, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.primary.main }}>
                        {t('FormGetPracticeReport13')}
                    </Typography>

                    <Fragment>
                        <Grid2 container marginTop={2}>
                            <Fragment>
                                <Button
                                    style={{ textTransform: 'none', fontSize: '12px' }}
                                    onClick={() => {
                                        // select or deselect all coaches
                                        selectAllCoaches(selectAll)
                                        setSelectAll(!selectAll);
                                    }}>
                                    {selectAll ? t('FormGetPracticeReport01') : t('FormGetPracticeReport02')}
                                </Button>
                                {
                                    coachesCheckBox.map((coach, index) => (
                                        <Grid2 xs={12} key={index}>
                                            <Divider />
                                            <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft={1}>
                                                <UserPictureAndName entity={coach} tagFacesColor="primary" />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox style={{}}
                                                            checked={coach.selected}
                                                            onChange={() => { handleClickCoach(index) }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </Box>

                                        </Grid2>
                                    ))
                                }
                            </Fragment>
                        </Grid2>
                    </Fragment>
                </Grid>
            )
    }

    // Sort practices for practice report
    function compareFn(a: PracticeBetter, b: PracticeBetter) {
        const aNameCoach = a.display_name || ''
        const bNameCoach = b.display_name || ''
        const aNameTeam = a.team?.attributes.title
        const bNameTeam = b.team?.attributes.title
        const aDate = a.date
        const bDate = b.date

        if (aNameCoach < bNameCoach) {
            return -1;
        } else if (aNameCoach > bNameCoach) {
            return 1;
        } else if (aNameTeam < bNameTeam) {
            return -1;
        } else if (aNameTeam > bNameTeam) {
            return 1;
        } else if (aDate < bDate) {
            return -1
        } else if (aDate > bDate) {
            return 1
        }
        return 0;
    }

    // Generate tex for practice report
    function report_practices(): [string, Array<string>] {
        let tex = ''
        let images: Array<string> = []
        let practiceNum = 0
        let coachPrev = ''
        // let teamPrev = ''

        // loop list of sorted practices
        const practicesForReport = getPracticesForReport()
        for (const practice of practicesForReport) {
            // limit report for test purposes
            // if (practiceNum > 9)
            //     continue
            practiceNum += 1

            // Control new page in PDF
            // let sectionNew = false

            // Values to put in tex
            const field_team_name = lescape(practice.team?.attributes.title || 'No Name').trim()
            const pdate = new Date(practice.date)
            const field_practice_date = `${pdate.toLocaleDateString()} ${pdate.toLocaleTimeString()}`

            // Section header with coach name
            if (practice.display_name !== coachPrev) {
                tex += `\\newpage\\section{${lescape((practice.display_name || '').trim())}}\n`
                // teamPrev = ''
                // sectionNew = true
            }
            coachPrev = practice.display_name || ''

            // Section subheader
            const subsection = `${field_team_name}, ${field_practice_date}`
            // if (subsection !== teamPrev) {
            //     if (!sectionNew)
            //         tex += '\\newpage'
            //     tex += `\\subsection{${subsection}}\n`
            // }
            // teamPrev = subsection
            tex += `\\subsection{${subsection}}\n`

            const [practiceTex, practiceImages] = getPracticeTex(state, practice)
            tex += practiceTex
            tex += '\\newpage\n'
            images = images.concat(practiceImages)
        }
        return [tex, images]
    }

    // Generate tex for time distribution report
    function report_time(): [string, Array<string>] {
        let tex = ""

        const practicesForReport = getPracticesForReport()
        const coaches = [...new Set(practicesForReport.map(x => x.display_name || ''))].sort()

        let coachCurrent = ''
        for (const coach of coaches) {
            const _practicesCoach = practicesForReport.filter(y => y.display_name === coach)
            const teams = [...new Set(_practicesCoach.map(x => x.team?.attributes.title))].sort()

            for (const team of teams) {
                const _practicesTeams = _practicesCoach.filter(x => x.team?.attributes.title === team)

                // sum up time spent in each exercise group
                let timeSpentInExerciseGroup = {}

                for (const practiceExt of _practicesTeams) {
                    for (const selectedExercise of practiceExt.selectedExercises) {
                        // sum up time spent for coach/team/exercise-group
                        const exercise = state.allExercises.find(x => x.attributes.drupal_internal__nid === selectedExercise.drupal_internal__nid)
                        const groupID = exercise?.relationships.field_group.data.id as keyof typeof timeSpentInExerciseGroup
                        timeSpentInExerciseGroup = { ...timeSpentInExerciseGroup, [groupID]: (timeSpentInExerciseGroup[groupID] || 0) + selectedExercise.durationMinutes }
                        // timeSpentInExerciseGroup = { ...timeSpentInExerciseGroup, [groupID]: selectedExercise.durationMinutes + timeSpentInExerciseGroup[groupID] || 0 }
                    }
                }

                // For coach/team we have a list of groups and minutes spent in exercise group. Create tex
                // First, add header for coach and subheader for team
                if (coach !== coachCurrent)
                    tex += `\\section{${lescape(coach)}}\n`
                coachCurrent = coach
                tex += `\\subsection{${lescape(team)}}\n`

                // Create list of groups and time spent in each group and time total
                let groups = []
                let timeSpentCoordinates = ''
                let timeSpentTotal = 0
                for (const group of state.allGroups) {
                    const groupID = group.id as keyof typeof timeSpentInExerciseGroup
                    if (timeSpentInExerciseGroup[groupID]) {
                        groups.push(group.attributes.title)
                        timeSpentCoordinates += `(${timeSpentInExerciseGroup[groupID]},${lescape(group.attributes.title)})\n`
                        timeSpentTotal += timeSpentInExerciseGroup[groupID]
                    }
                }

                tex += `
\\begin{tikzpicture}
\\begin{axis}[
    visualization depends on=rawx \\as \\myx,
    xbar,
    y axis line style = { opacity = 0 },
    axis x line       = none,
    tickwidth         = 0pt,
    symbolic y coords = {${lescape(groups.toString())}},
    point meta={x*100/${timeSpentTotal}},
    nodes near coords={\\pgfmathprintnumber\\myx~(\\pgfmathprintnumber\\pgfplotspointmeta\\%)},
    nodes near coords align={horizontal},
    ytick=data,
    y=1cm, % Adjust spacing between bars
]
\\addplot coordinates {
${timeSpentCoordinates}
};
\\end{axis};
\\end{tikzpicture}
\\vspace{1cm}\n`
            }
        }

        return [tex, []]
    }

    // Generate report for download
    async function submit() {
        dispatch({ type: 'setBackdrop', diff: 1 })
        if (coachesCheckBox.filter(x => x.selected).length === 0 && state.user.data.attributes.field_user_type === UserType.clubadmin) {
            dispatch(getActionSetConfirm('Select coaches for report'));
        } else if (formData.date_from >= formData.date_to) {
            dispatch(getActionSetConfirm('Select a period'));
        } else {
            const [tex, images] = formData.reportType === 'time' ? report_time() : report_practices()
            getFileICBLogo(state, true) && images.push(getFileICBLogo(state, false))

            const file = `${randomId()}.pdf`
            // Download report
            const resp = await icbControllerGenerel02(state, {
                opr: 'get_pdf',
                images: [...new Set(images)],
                document: tex,
                file: file,
                fileICBLogo: getFileICBLogo(state, true),
                maketitle: true,
                tableofcontents: true,
            })
            if (!resp.ok) {
                dispatch(getActionSetConfirm(resp.error))
            } else {
                navigate('/practicereport', { state: `${BACKEND}/sites/default/files/icb_pdf/${file}` })
            }
        }
        dispatch({ type: 'setBackdrop', diff: 0 })
    }

    // Close dialog to do email selection, create report for email
    function onCloseDialogMyContacts(ok: boolean) {
        setOpenDialogMyContacts(false)

        // Get and reset selections from CRUDList
        const emailReceivers = state.CRUDListSelectedValues?.join(' ')
        dispatch({ type: 'setCRUDListSelectedValues', selectedValues: [] })

        if (ok) {
            if (!emailReceivers) {
                dispatch(getActionSetConfirm(t('MyContacts05')))
            } else {
                // Send report to emailReceivers

                // Generate tex for selected report we want to mail
                const [tex, images] = formData.reportType === 'time' ? report_time() : report_practices()
                getFileICBLogo(state, true) && images.push(getFileICBLogo(state, false))

                // Send report. Generate PDF document with report and email to list of receivers
                const file = `${randomId()}.pdf`
                icbControllerGenerel02(state, {
                    opr: 'get_pdf',
                    images: [...new Set(images)],
                    document: tex,
                    file: file,
                    fileICBLogo: getFileICBLogo(state, true),
                    maketitle: true,
                    tableofcontents: true,
                    deliver: "mail",
                    receivers: emailReceivers,
                    emailBody00: t('emailBody00'),
                    emailBody01: t('emailBody01'),
                    emailSubject: t('emailSubject00'),
                })
                    .then((resp) => {
                        if (!resp.ok) {
                            dispatch(getActionSetConfirm(resp.error))
                        }
                    })
                dispatch(getActionSetConfirm(t('MyContacts06')))
            }
        }
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Box >
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('FormGetPracticeReport09')}
                </Typography>
                <Divider />

                <Typography sx={{ marginTop: 3, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('FormGetPracticeReport14')}
                </Typography>

                <Grid container justifyContent="center" style={{ width: '100%' }}>
                    <FormControl style={{ width: '100%', maxWidth: '500px' }}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1, width: '100%', p: 1 }}>
                            {['practices', 'time'].map((type) => (
                                <Button
                                    key={type}
                                    variant="outlined"
                                    sx={{
                                        borderColor: 'lightgrey',
                                        color: formData.reportType === type ? 'white' : theme.palette.text.secondary,
                                        backgroundColor: formData.reportType === type ? '#DD6F20' : 'transparent',
                                        fontSize: '12px',
                                        fontWeight: formData.reportType === type ? 'bold' : 'normal',
                                        fontFamily: 'PT Sans, sans-serif',
                                        textTransform: 'none',
                                        width: '49%',
                                        '&:hover': {
                                            borderColor: 'lightgrey',
                                            backgroundColor: formData.reportType === type ? '#C65D17' : 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                    onClick={() => handleChange('reportType', type)}
                                    aria-label={type === 'time' ? t('FormGetPracticeReport10') : t('FormGetPracticeReport11')}
                                >
                                    {type === 'time' ? t('FormGetPracticeReport10') : t('FormGetPracticeReport11')}
                                </Button>
                            ))}
                        </Box>
                    </FormControl>
                </Grid>

                {/* <Divider sx={{ marginTop: 2 }} /> */}

                <Typography sx={{ marginTop: 2, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('FormGetPracticeReport15')}
                </Typography>

                <DateFromDateTo
                    handleChange={(name, value) => {
                        handleChange(name, value)
                    }}
                    date_from={new Date(formData.date_from)}
                    date_to={new Date(formData.date_to)}
                />

                {/* <Divider sx={{ marginTop: 2 }} /> */}

                {/* SELECT THE COACHES */}
                <Box>
                    {clubAdmin()}
                </Box>
            </Box>

            {/* Delivery method. mail or download */}

            {!state.portrait &&
                < Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                    <Typography sx={{ paddingRight: 2, paddingLeft: 2, color: theme.palette.text.secondary, fontSize: { xs: 11, sm: 13 } }}>
                        {t("FormGetPracticeReport16")}
                    </Typography>
                </Box>
            }

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: 2 }}>
                {/* send as email - only for paying users */}
                {
                    <Button
                        variant='contained'
                        style={{ textTransform: 'none', fontSize: '12px', fontFamily: 'PT Sans, sans-serif' }}
                        onClick={() => {
                            if (state.user.data.attributes.field_user_type === UserType.free) {
                                dispatch(getActionSetConfirm(t('AlertMsg39'), t('ICBAppBar04'), () => navigate('/setsubscription')));
                            } else {
                                setOpenDialogMyContacts(true)
                            }
                        }}
                    >
                        {t('FormGetPracticeReport12')}
                    </Button>
                }

                {!state.portrait &&
                    // Download
                    <Button
                        variant='outlined'
                        style={{ textTransform: 'none', fontSize: '12px', fontFamily: 'PT Sans, sans-serif' }}
                        onClick={submit}
                    >
                        {t('FormGetPracticeReport08')}
                    </Button>
                }

            </Box>

            <Divider sx={{ marginTop: 2 }} />

            <AttendanceReport />

            <DialogMyContacts
                open={openDialogMyContacts}
                onClose={onCloseDialogMyContacts}
            />

        </Box >
    );
}