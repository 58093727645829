import { Box, Button, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetPlaybook, ActionSetSelectedExercisePool, ActionSetSelectedGroup, CRUD, DrupalEntity, JSONAPITypeId, OperationMode, TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { getActionSetConfirm, getAllContentEntities, getDD, getNodeWithRemovedRelationship, getPlaybookSharable, icbControllerGenerel02, nodeCRUD } from '../misc/Functions';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogPlayBuilder, { PlayDetails } from './DialogPlayBuilder';
import { BACKEND, DRUPALENTITYINIT, PLAYDETAILS } from '../misc/Constants';
import PlayIllustrate from './PlayIllustrate';

export default function CreatePlay() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const play: DrupalEntity = location.state?.play || DRUPALENTITYINIT
    log.debug('CreatePlay');

    const [disabled, setDisabled] = useState(false)
    const [openDialogPlayBuilder, setOpenDialogPlayBuilder] = useState(false)
    const [playDetails, setPlayDetails] = useState<PlayDetails | null>(
        // if we have a play then use play details from that play. Otherwise, use initials play details
        play.id
            ? JSON.parse(play.attributes.field_play_details)
            : PLAYDETAILS
    )

    const validationSchema = yup.object({
        title: yup
            .string()
            .required(t('CreatePlay00'))
            // allow save if no other node with same title or node with title has same id as the one we try to save
            .test('new-play', t("CreatePlay10"), (value) => {
                const rc = state.allPlays.find(x => x.attributes.title === value) === undefined
                    || state.allPlays.find(x => x.attributes.title === value)?.id === play.id
                return rc
            }),
        field_play_description: yup
            .string(),
        field_play_group: yup
            .string()
            .required(t('CreatePlay01')),
    });

    const formik = useFormik({
        initialValues: {
            title: (play.attributes?.title || '') as string,
            field_play_description: (play.attributes?.field_play_description || '') as string,
            field_play_group: (play.relationships?.field_play_group?.data.id || '') as string,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (disabled)
                return
            setDisabled(true)
            // set the play we want to create/update
            let node: DrupalEntity = {
                type: 'node--play',
                attributes: {
                    title: values.title,
                    field_play_description: values.field_play_description,
                    field_play_details: JSON.stringify(playDetails),
                },
                relationships: {
                    field_play_group: {
                        data: {
                            type: 'node--play',
                            id: values.field_play_group
                        }
                    }
                }
            }

            // creatre or update?
            let CRUDOperation: CRUD = CRUD.Create // assume create new play
            if (play.id) {
                CRUDOperation = CRUD.Update // no, update play
                node['id'] = play.id
            }

            // free user is only allowed to create a certain number of plays
            if (state.user.data.attributes.field_user_type === UserType.free
                && CRUDOperation === CRUD.Create
                && state.allPlays.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= (state.configuration[0].attributes.field_max_plays_free_user || 3)) {
                dispatch(getActionSetConfirm(t('AlertMsg41'), t('ICBAppBar04'), () => navigate('/setsubscription')));
                setDisabled(false)
                return
            }

            // update back end
            nodeCRUD(state, dispatch, CRUDOperation, node)
                .then((resp) => {
                    if (resp.data) {
                        // if clubuser creates play then clone to clubadmin and get clone
                        if (CRUDOperation === CRUD.Create
                            && state.user.data.attributes.field_user_type === UserType.club
                            && state.user.data.attributes.field_club_admin_accept) {
                            getDD(state, dispatch, `${BACKEND}/icb-node/clone-node-to-clubadmin/${resp.data.attributes.drupal_internal__nid}`, 'GET')
                                .then((resp) => {
                                    getAllContentEntities(state, dispatch, 'node--play', `?filter[nid]=${resp.nid_dest}`, false) // fetch cloned play
                                })
                        }

                        // Find published playbooks that holds the updated play 
                        const playbooksRepublish = state.allPlaybooks.filter(x => CRUDOperation === CRUD.Update
                            && x.attributes.field_url_and_credentials
                            && x.relationships.field_plays.data.map((x: JSONAPITypeId) => x.id).includes(play.id))
                        playbookRepublish(playbooksRepublish, resp.data, false)
                        gotoPlay(values.field_play_group)
                    } else {
                        dispatch(getActionSetConfirm(resp));
                        setDisabled(false)
                    }
                })
        }
    });

    // Try to go where new/edited play is and show play to user!
    function gotoPlay(groupID: string) {
        let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = {
            type: 'setSelectedExercisePool',
            pool: state.user.data.attributes.field_user_type === UserType.clubadmin ? 'club' : 'mine'
        }
        dispatch(actionSetSelectedExercisePool);
        let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === groupID)! };
        dispatch(actionSetSelectedGroup);
        // window.scrollTo(0, 0);
        navigate('/icbplays');
        // Make sure full width display is available to show plays
        if (state.showPracticeProgram && state.portrait) {
            let action = { type: 'showPracticeProgram', show: false };
            dispatch(action);
        }
    }

    /*
    when a play is updated or deleted we must handle the situation where the play is in a playbook. If the play is in playbooks
    that only holds plays by the user we unconditionally republish the playbooks. The the play is in playbooks that hold plays
    by ohter users, ie clubadmin or icb 7, then we give option to republish playbooks. All this because published plays by the 
    user SHOULD be approved by the user and we risk that a play by anohter user has been updated and then we can not unconditionally
    republish playbooks.
    If we call getDD() below to republish a playbook then we pass in an updated play or a playbook with reduced list of plays depending
    on playDeleted. This is because getDD() works on global state and global state has not been uddated yet.
    republishCandidates: the list of playbooks in question
    play: updated or deleted play
    playDeleted: play is deleted. Otherwise, play is updated
    */
    function playbookRepublish(republishCandidates: DrupalEntity[], play: DrupalEntity, playDeleted: boolean) {
        // The list of playbooks we want to update - does it contain plays from other authors than current user?
        const playbooksRepublishOtherAuthors = republishCandidates.filter(
            x => x.relationships.field_plays.data.map((x: JSONAPITypeId) => x.id) // get list of playIDs in playbook
                .find((x: string) => state.allPlays
                    .find(y => y.id === x && y.relationships.uid.data.id !== state.user.data.id))) // based on playID find play that is NOT by current user
        if (playbooksRepublishOtherAuthors.length > 0) {
            // confirm republish
            // user must accept replublish of paybooks because playbooks contains plays user does not control and these plays might have been updated
            // and the user should not have plays in playbooks he has not checked

            const msg = t('CreatePlay11')
            // const msg = `These playbooks will be republished: ${republishCandidates.map(item => item.attributes.title)}. These playbooks contain plays you dont control: ${playbooksRepublishOtherAuthors.map(item => item.attributes.title)}. Go ahead and republish playbooks?`

            dispatch(getActionSetConfirm(msg, 'ok', () => {
                // Republish these playbooks
                republishCandidates.forEach(x => {
                    log.info('republish playbook because play has been updated/deleted. Play:', play.attributes.title, 'Playbook:', x.attributes.title)
                    if (playDeleted)
                        getPlaybookSharable(state, getNodeWithRemovedRelationship(x, 'field_plays', play.id!))
                            .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                    else
                        getPlaybookSharable(state, x, play)
                            .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                })
            }))
        } else if (republishCandidates.length > 0) {
            // republish unconditionally
            republishCandidates.forEach(x => {
                log.info('republish playbook because play has been updated/edited. Play:', play.attributes.title, 'Playbook:', x.attributes.title)
                if (playDeleted)
                    getPlaybookSharable(state, getNodeWithRemovedRelationship(x, 'field_plays', play.id!))
                        .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                else
                    getPlaybookSharable(state, x, play)
                        .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
            })
        }
    }

    // delete play and remove play from playbooks if needed. Updated playbooks are republished
    function handleDelete() {
        dispatch(getActionSetConfirm(t('CreatePlay12'), 'ok', () => {
            // delete play and remove play from playbooks. Return list of updated playbooks
            icbControllerGenerel02(state, {
                opr: "delete_play",
                playID: play.id
            })
                .then((resp) => {
                    if (resp.ok) {
                        // we got a list of playbooks that were updated because of the deleted play
                        // Find published playbooks that holds the updated play
                        const playbooksRepublish = state.allPlaybooks.filter(x => resp.playbooksUpdated.includes(x.attributes.drupal_internal__nid.toString())) // toString() because that is what we get from the back end
                        playbookRepublish(playbooksRepublish, play, true)

                        // Remove play from list of read plays now that play has been deleted in the back end
                        // dispatch({ type: 'delContentEntity', contentType: 'node--play', id: play.id })
                        getAllContentEntities(state, dispatch, 'node--play')

                        // If back end call to opr: "delete_play" updated playbooks then reread playbooks
                        if (resp.playbooksUpdated.length > 0)
                            getAllContentEntities(state, dispatch, 'node--playbook')

                        // delete play from current playbook if needed as we might show current playbook
                        if (state.curPlaybook.relationships.field_plays?.data.find((x: JSONAPITypeId) => x.id === play.id)) {
                            const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithRemovedRelationship(state.curPlaybook, 'field_plays', play.id!) }
                            dispatch(action)
                        }

                        // gotoPlay(play.relationships.field_play_group.data.id)
                        navigate(-1)
                    } else {
                        dispatch(getActionSetConfirm(resp.error))
                    }
                })
        }));
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('CreatePlay02')}
            </Typography>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label={t('CreatePlay03')}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    size="small"
                    sx={{ marginTop: 3 }}
                    inputProps={{ maxLength: 45 }}
                />
                <TextField
                    fullWidth
                    multiline
                    id="field_play_description"
                    name="field_play_description"
                    label={t('CreatePlay04')}
                    type="field_play_description"
                    value={formik.values.field_play_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.field_play_description && Boolean(formik.errors.field_play_description)}
                    helperText={formik.touched.field_play_description && formik.errors.field_play_description}
                    size="small"
                    sx={{ marginTop: 2 }}
                    inputProps={{ maxLength: 600 }}
                />
                <TextField
                    fullWidth
                    select
                    id="field_play_group"
                    name="field_play_group"
                    label={t('CreatePlay05')}
                    type="field_play_group"
                    value={state.allGroups.length === 0 ? '' : formik.values.field_play_group} // don't use default value until select has default value
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.field_play_group && Boolean(formik.errors.field_play_group)}
                    helperText={formik.touched.field_play_group && formik.errors.field_play_group}
                    size="small"
                    sx={{ marginTop: 2 }}
                >
                    {/* get list of all 'play' groups */}
                    {
                        state.allGroups.filter(x => x.attributes.field_group_type === OperationMode.play).map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                >
                                    {item.attributes.title}
                                </MenuItem>
                            )
                        })
                    }
                </TextField>


                {/* Create/Edit play button */}
                {/* IN THIS ONE WE IMPORT THE OPEN MODAL FUNCTION FROM PLAYBUILDER */}
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setOpenDialogPlayBuilder(true)}
                    sx={{
                        textTransform: 'none',
                        marginTop: 2,
                        justifyContent: 'flex-start' // Align icon and text to the left
                    }}
                >
                    <BorderColorRoundedIcon sx={{ marginRight: 1 }} /> {/* Add space between icon and text */}
                    {
                        play.id ? t('CreatePlay06') : t('CreatePlay07')
                    }
                </Button>

                {/* illustrate play if play is not initial */}
                {
                    JSON.stringify(playDetails) !== JSON.stringify(PLAYDETAILS) && <PlayIllustrate play={DRUPALENTITYINIT} playDetails={playDetails!} />
                }

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                    <Button sx={{ textTransform: 'none', fontWeight: 'bold' }} size="small" color="primary" variant="contained" type="submit">
                        {t('CreatePlay08')}
                    </Button>
                    {
                        // show delete button if current play has an id. Otherwise, we are creating a new play
                        play.id &&
                        <Box sx={{ display: 'flex', marginLeft: 1 }}>
                            <Button sx={{ textTransform: 'none' }} color="primary" variant="outlined" size="small" onClick={handleDelete}>
                                {t('CreatePlay09')}
                            </Button>
                        </Box>
                    }
                </Box>

                <DialogPlayBuilder
                    play={play}
                    open={openDialogPlayBuilder}
                    onClose={(playDetails: PlayDetails | null) => {
                        if (playDetails) {
                            setPlayDetails(playDetails)
                        }
                        setOpenDialogPlayBuilder(false)
                    }}
                />
            </form>
        </Box>

    );
}
