import { Line, Player, Cone } from "../components/DialogPlayBuilder";
import BasketballHalfCourt from "../components/BasketballHalfCourt";
import BasketballFullCourt from "../components/BasketballFullCourt";

export const courtDimensions = {
    halfcourt: { width: 542, height: 455 },
    fullcourt: { width: 910, height: 550 },
};

export const approximateBezierLength = (from: { x: number; y: number }, control: { x: number; y: number }, to: { x: number; y: number }) => {
    const steps = 10;
    let length = 0, prev = from;
    for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const point = {
            x: (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x,
            y: (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y,
        };
        length += Math.hypot(point.x - prev.x, point.y - prev.y);
        prev = point;
    }
    return length;
};

export const createZigzagPath = (
    from: { x: number; y: number },
    control: { x: number; y: number },
    to: { x: number; y: number },
    amplitude = 3,
    oscillationsPer100px = 16,
    finalSegmentLength = 16,
    includeFinalSegment = true
) => {
    const length = approximateBezierLength(from, control, to);
    const totalOsc = Math.max(2, Math.round((length / 100) * oscillationsPer100px));
    const steps = 1000, dt = 1 / steps;
    let totalLength = 0, prev = from;
    const cumulative = [0];
    for (let i = 1; i <= steps; i++) {
        const t = i * dt;
        const point = {
            x: (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x,
            y: (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y,
        };
        totalLength += Math.hypot(point.x - prev.x, point.y - prev.y);
        cumulative.push(totalLength);
        prev = point;
    }
    const path = [`M ${from.x} ${from.y}`];
    for (let i = 1; i <= steps; i++) {
        const s = (i / steps) * totalLength;
        let j = cumulative.findIndex((cl) => cl >= s) || 1;
        const t0 = (j - 1) * dt, t1 = j * dt, s0 = cumulative[j - 1], s1 = cumulative[j];
        const t = t0 + ((s - s0) / (s1 - s0)) * (t1 - t0);
        const point = {
            x: (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x,
            y: (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y,
        };
        const dxdt = 2 * (1 - t) * (control.x - from.x) + 2 * t * (to.x - control.x),
            dydt = 2 * (1 - t) * (control.y - from.y) + 2 * t * (to.y - control.y),
            len = Math.hypot(dxdt, dydt),
            nx = -dydt / len, ny = dxdt / len,
            frequency = (totalOsc * Math.PI) / totalLength,
            offset = amplitude * Math.sin(s * frequency);
        path.push(`L ${point.x + offset * nx} ${point.y + offset * ny}`);
    }
    if (includeFinalSegment) {
        const theta = Math.atan2(to.y - control.y, to.x - control.x);
        path.push(`L ${to.x + finalSegmentLength * Math.cos(theta)} ${to.y + finalSegmentLength * Math.sin(theta)}`);
    } else {
        path.push(`L ${to.x} ${to.y}`);
    }
    return path.join(' ');
};

export const SvgDefs: React.FC<{ frameId?: number; lineId: number; color: string; playNid?: number; markerSize?: number }> = ({ frameId, lineId, color, playNid, markerSize = 25 }) => {
    // Build the prefix based on provided props
    const prefixParts: string[] = [];
    if (playNid !== undefined) {
        prefixParts.push(String(playNid));
    }
    if (frameId !== undefined) {
        prefixParts.push(String(frameId));
    }
    const prefix = prefixParts.join('-');

    // Construct marker IDs
    const arrowheadId = prefix ? `arrowhead-${prefix}-${lineId}` : `arrowhead-${lineId}`;
    const barEndId = prefix ? `barEnd-${prefix}-${lineId}` : `barEnd-${lineId}`;

    return (
        <defs>
            <marker
                id={arrowheadId}
                markerWidth={markerSize}
                markerHeight={markerSize * 0.7}
                refX={markerSize * 0.9}
                refY={markerSize * 0.35}
                orient="auto"
                markerUnits="userSpaceOnUse"
            >
                <polygon
                    points={`${markerSize * 0.9} ${markerSize * 0.35}, ${markerSize * 0.2} ${markerSize * 0.1}, ${markerSize * 0.4} ${markerSize * 0.35}, ${markerSize * 0.2} ${markerSize * 0.6}`}
                    fill={color}
                    stroke={color}
                    strokeWidth="1"
                />
            </marker>
            <marker
                id={barEndId}
                markerWidth="1"
                markerHeight={markerSize * 0.8}
                refX="0.25"
                refY={(markerSize * 0.8) / 2}
                orient="auto"
            >
                <rect x="0" y="0" width="1" height={markerSize * 0.8} fill={color} />
            </marker>
        </defs>
    );
};

export const getPlayerLinesInOrder = (lines: any[], playerId: number) =>
    lines.filter((l) => l.fromId === playerId).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

export const getOffsetPoint = (x1: number, y1: number, x2: number, y2: number, offset: number) => {
    const dx = x2 - x1, dy = y2 - y1, len = Math.hypot(dx, dy);
    return len ? { x: x1 + dx * (offset / len), y: y1 + dy * (offset / len) } : { x: x1, y: y1 };
};

export const generatePathD = (line: Line, adjustedFrom: { x: number; y: number }, adjustedTo: { x: number; y: number }, allLines: Line[]) => {
    // Get all lines for the player and sort them by order
    const playerLines = getPlayerLinesInOrder(allLines, line.fromId);
    const isFirstLine = playerLines.length > 0 && playerLines[0].id === line.id;

    // Apply offset only if it's the first line
    const fromOffset = (line.type === 'bar' || line.type === 'straight') && isFirstLine ? 10 : isFirstLine ? 15 : 0;
    const start = fromOffset > 0
        ? getOffsetPoint(adjustedFrom.x, adjustedFrom.y, line.controlX, line.controlY, fromOffset)
        : { x: adjustedFrom.x, y: adjustedFrom.y };

    const endOffset = (line.type === 'bar' || line.type === 'straight') ? 0 : 15;
    const end = getOffsetPoint(
        adjustedTo.x,
        adjustedTo.y,
        line.midX && line.controlX2 ? line.controlX2 : line.controlX,
        line.midY && line.controlY2 ? line.controlY2 : line.controlY,
        endOffset
    );

    // Handle double bezier lines
    if (line.midX !== undefined && line.midY !== undefined && line.controlX2 !== undefined && line.controlY2 !== undefined) {
        const mid = { x: line.midX, y: line.midY };
        if (line.type === 'zigzag') {
            // First zigzag segment: start to mid, no final segment
            const firstSegment = createZigzagPath(start, { x: line.controlX, y: line.controlY }, mid, 3, 16, 16, false);
            // Second zigzag segment: mid to end, with final segment
            const secondSegment = createZigzagPath(mid, { x: line.controlX2, y: line.controlY2 }, end, 3, 16, 16, true).replace('M', '');
            return `${firstSegment} ${secondSegment}`;
        } else {
            // Non-zigzag duplicated lines use double Bézier
            return `M ${start.x} ${start.y} Q ${line.controlX} ${line.controlY} ${mid.x} ${mid.y} Q ${line.controlX2} ${line.controlY2} ${end.x} ${end.y}`;
        }
    }
    // Single-segment lines
    else if (line.type === 'zigzag') {
        return createZigzagPath(start, { x: line.controlX, y: line.controlY }, end);
    }
    else {
        return `M ${start.x} ${start.y} Q ${line.controlX} ${line.controlY} ${end.x} ${end.y}`;
    }
};


// Function to render a single player as an SVG group
// Function to render a single player as an SVG group
export const renderPlayerSVG = (
    player: Player,
    options: {
        fontSize?: number;
        ballRadius?: number;
        textFill?: string;
        ballStroke?: string;
    } = {}
) => {
    const {
        fontSize = player.isDefender ? 20 : 23,
        ballRadius = 15,
        textFill = player.color || (player.isDefender ? "red" : "black"),
        ballStroke = player.color || (player.isDefender ? "red" : "black"),
    } = options;

    const displayNumber = player.isCoach ? "C" : player.isDefender ? `x${player.number}` : player.number;

    return (
        <g key={player.id}>
            {player.hasBall && (
                <circle
                    cx={player.x}
                    cy={player.y}
                    r={ballRadius}
                    stroke={ballStroke}
                    strokeWidth={2}
                    fill="none"
                />
            )}
            <text
                x={player.x}
                y={player.y}
                textAnchor="middle"
                alignmentBaseline="central"
                fontSize={fontSize}
                fontWeight="bold"
                fill={textFill}
                style={{ fontFamily: 'Arial, sans-serif' }}
            >
                {displayNumber}
            </text>
        </g>
    );
};

// Function to render a single line as an SVG group
export const renderLineSVG = (
    line: Line,
    players: Player[],
    allLines: Line[],
    playNid?: number,
    options: {
        strokeWidth?: number;
        markerSize?: number;
    } = {}
) => {
    const {
        strokeWidth = 2,
        markerSize = 25,
    } = options;

    const fromPlayer = players.find((p) => p.id === line.fromId);
    if (!fromPlayer) return null;

    const isDefenderLine = fromPlayer.isDefender || false;
    const lineColor = line.color || (isDefenderLine ? "red" : "black");

    const playerLines = getPlayerLinesInOrder(allLines, line.fromId);
    const lineIndex = playerLines.findIndex((l) => l.id === line.id);

    let adjustedFrom = { x: fromPlayer.x, y: fromPlayer.y };
    if (lineIndex > 0) {
        const prevLine = playerLines[lineIndex - 1];
        if (prevLine.type !== 'dashed' && prevLine.type !== 'shot') {
            adjustedFrom = { x: prevLine.toX, y: prevLine.toY };
        } else if (prevLine.type === 'shot') {
            adjustedFrom = { x: prevLine.fromX, y: prevLine.fromY }; // Start from where the shot was taken
        }
    }

    const adjustedTo = { x: line.toX, y: line.toY };
    const pathD = generatePathD(line, adjustedFrom, adjustedTo, allLines);

    const markerPrefix = playNid !== undefined ? `${playNid}-` : '';
    const arrowheadId = `arrowhead-${markerPrefix}${line.id}`;
    const barEndId = `barEnd-${markerPrefix}${line.id}`;

    return (
        <g key={line.id}>
            <SvgDefs
                lineId={line.id}
                color={lineColor}
                playNid={playNid}
                markerSize={isDefenderLine ? markerSize * 0.72 : markerSize}
            />
            <path
                d={pathD}
                stroke={lineColor}
                strokeWidth={isDefenderLine ? strokeWidth * 0.5 : strokeWidth}
                fill="none"
                strokeDasharray={line.type === "dashed" || line.type === "shot" ? "5,5" : undefined}
                markerEnd={
                    line.type === "bar"
                        ? `url(#${barEndId})`
                        : line.type === "shot"
                            ? undefined
                            : `url(#${arrowheadId})`
                }
            />
            {line.type === "shot" && (
                <circle
                    cx={line.toX}
                    cy={line.toY}
                    r={8}
                    fill="none"
                    stroke={lineColor}
                    strokeWidth={2}
                />
            )}
        </g>
    );
};

// Function to render a single cone as an SVG group
export const renderConeSVG = (
    cone: Cone,
    options: {
        scale?: number;
    } = {}
) => {
    const { scale = 1 } = options; // Default scale of 2 to match the example
    const baseSize = 24; // Base size from the example
    const scaledSize = baseSize * scale;
    const x = cone.x - scaledSize / 2;
    const y = cone.y - scaledSize / 2;

    return (
        <g key={cone.id} id={`cone-${cone.id}`}>
            <path
                d={`M${x + 6 * scale},${y + 15 * scale} 
                    L${x + 9 * scale},${y + 2 * scale} 
                    Q${x + 10 * scale},${y + 1 * scale},${x + 11 * scale},${y + 2 * scale} 
                    L${x + 14 * scale},${y + 15 * scale} 
                    L${x + 6 * scale},${y + 15 * scale}Z 
                    M${x + 4 * scale},${y + 15 * scale} 
                    Q${x + 3 * scale},${y + 15 * scale},${x + 3 * scale},${y + 15.5 * scale} 
                    L${x + 3 * scale},${y + 16 * scale} 
                    L${x + 17 * scale},${y + 16 * scale} 
                    L${x + 17 * scale},${y + 15.5 * scale} 
                    Q${x + 17 * scale},${y + 15 * scale},${x + 16 * scale},${y + 15 * scale}`}
                fill={cone.color || "orange"}
            />
        </g>
    );
};

// Function to render all elements for a frame
export const renderFrameSVG = (
    frame: { players: Player[]; lines: Line[]; cones: Cone[] },
    courtType: "halfcourt" | "fullcourt",
    playNid?: number,
    options: {
        playerFontSize?: number;
        playerBallRadius?: number;
        lineStrokeWidth?: number;
        lineMarkerSize?: number;
        coneScale?: number;
        courtRenderMode?: 'whiteBackground' | 'woodBackground'; // New option to choose rendering mode
    } = {}
) => {
    const {
        playerFontSize,
        playerBallRadius,
        lineStrokeWidth,
        lineMarkerSize,
        coneScale,
        courtRenderMode = 'static', // Default to static images
    } = options;

    const courtWidth = courtDimensions[courtType].width;
    const courtHeight = courtDimensions[courtType].height;

    return (
        <svg
            width={courtWidth}
            height={courtHeight}
            viewBox={`0 0 ${courtWidth} ${courtHeight}`}
        >
            {/* Background court */}
            {courtRenderMode === 'static' ? (
                <image
                    xlinkHref={courtType === "halfcourt" ? "/images/halfcourt.svg" : "/images/fullcourt.svg"}
                    x="0"
                    y="0"
                    width={courtWidth}
                    height={courtHeight}
                    preserveAspectRatio="none"
                />
            ) : (
                courtType === "halfcourt" ? <BasketballHalfCourt /> : <BasketballFullCourt />
            )}

            {/* Render cones */}
            {(frame.cones || []).map((cone) =>
                renderConeSVG(cone, { scale: coneScale })
            )}

            {/* Render lines */}
            {frame.lines.map((line) =>
                renderLineSVG(line, frame.players, frame.lines, playNid, {
                    strokeWidth: lineStrokeWidth,
                    markerSize: lineMarkerSize,
                })
            )}

            {/* Render players */}
            {frame.players.map((player) =>
                renderPlayerSVG(player, {
                    fontSize: playerFontSize,
                    ballRadius: playerBallRadius,
                })
            )}
        </svg>
    );
};