import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, } from '@mui/material';
import { useContext } from 'react';
import { TypeContext, } from '../misc/Types';
import { Context } from '../App';
import log from "../misc/Logger";
import { getActionSetConfirm } from '../misc/Functions';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

export default function DialogConfirm() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DialogConfirm');

    // Close confirm dialog
    function close() {
        dispatch(getActionSetConfirm());
    }

    return (
        <Dialog
            open={state.confirm.text !== undefined}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: 16,
                },
            }}
        >
            <DialogTitle id="alert-dialog-description" fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {t('Confirm00')}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    edge="end"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {/* Divider */}
            <div style={{ height: '1px', backgroundColor: '#ccc', width: '90%', margin: '0 auto' }}></div>
            <DialogContent>
                {state.confirm && state.confirm.text}
            </DialogContent>
            <DialogActions>
                <Button
                    aria-label='ok_dialogconfirm'
                    onClick={() => {
                        // run optional code on button click
                        // run code before close because state of this dialog, open/close, determines enabled/disabled state of buttons in calling environment
                        // TO DO if no state.confirm.code() has been specified will state.confirm.code() the default to () => { } and is that save to call?
                        state.confirm.code();
                        close();
                    }}
                    autoFocus
                    variant="contained"
                    sx={{ borderRadius: 6, marginBottom: 1.5, marginRight: 2, fontWeight: 'bold' }}
                >
                    {state.confirm?.buttonText || t('DialogConfirm01')}
                </Button>

            </DialogActions>
        </Dialog>
    );
}