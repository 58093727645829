import { Box, TextField } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { Fragment } from "react/jsx-runtime"
import { useTranslation } from "react-i18next"
import log from "loglevel"

interface PropsDateFromDateTo {
    handleChange: (name: string, value: any) => any,
    date_from: Date,
    date_to: Date,
}

export default function DateFromDateTo(props: PropsDateFromDateTo) {
    // const { state, } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DateFromDateTo');

    const commonStyles = {
        fontFamily: '"PT Sans", sans-serif',
        fontWeight: 'bold',

        height: '40px', // Set your desired height here
        '& .MuiInputBase-root': {
            height: '100%',
            paddingTop: 0,
            paddingBottom: 0,
            fontFamily: '"PT Sans", sans-serif',
            color: 'grey', // Text color
            '& .MuiOutlinedInput-notchedOutline': {
                // borderColor: 'grey', // Border color
                borderWidth: '1px', // Normal state border thickness
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey', // Border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                // borderColor: 'grey', // Border color when focused
                borderWidth: '2px', // Focused state border thickness
            },
            '& .MuiSvgIcon-root': {
                // color: 'grey', // Icon color
            },
        },
        '& .MuiInputLabel-root': {
            fontFamily: '"PT Sans", sans-serif',
            // fontWeight: 'bold',
            // color: 'grey', // Consistent label color
        },
    };

    return (
        <Fragment>

            <Grid2 style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, width: '100%', maxWidth: '450px' }}>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={state.user.locale.substring(0, 2)}> */}
                    <Grid2 xs={6}>
                        <TextField
                            label={t('FormGetPracticeReport06')}
                            type="date"
                            // value={formData.date.toISOString().slice(0,16)}
                            value={props.date_from.toISOString().slice(0,10)}
                            onChange={(e) => props.handleChange('date_from', e.target.value)}
                            sx={{ ...commonStyles, width: '100%' }}
                        />
                    </Grid2>
                    <Grid2 xs={6} sx={{ paddingLeft: 1 }}>
                        <TextField
                            label={t('FormGetPracticeReport07')}
                            type="date"
                            // value={formData.date.toISOString().slice(0,16)}
                            value={props.date_to.toISOString().slice(0,10)}
                            onChange={(e) => props.handleChange('date_to', e.target.value)}
                            sx={{ ...commonStyles, width: '100%' }}
                        />
                    </Grid2>
                    {/* </LocalizationProvider> */}
                </Box>
            </Grid2>
        </Fragment>
    )
}