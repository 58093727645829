import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Context } from "../App";
import { ActionSetPractice, Attend, DrupalEntity, JSONAPITypeId, TypeContext } from "../misc/Types";
import log from "../misc/Logger";
import DialogMyPlayers from "./DialogMyPlayers";
import { getActionSetConfirm, savePractice } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useTheme } from "@mui/material/styles";
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ICBDialogTitle from "./ICBDialogTitle";

interface PropsDialogAttendanceListForPractice {
    open: boolean,
    onClose: () => void,
}

export default function DialogAttendanceListForPractice(props: PropsDialogAttendanceListForPractice) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('DialogAttendanceListForPractice');

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)
    /*
    Attendance list is dirty if:
    team player list is changed in DialogMyPlayers
    checkbox for any player is changed
    */
    const [dirty, setDirty] = useState(false)
    const didMountRef = useRef(false); // make sure certain useEffect is NOT run on first render

    // Current list of attendance for current practice
    const [attendanceList, setAttendanceList] = useState<Array<Attend>>([])
    const [openDialogMyPlayers, setOpenDialogMyPlayers] = useState(false)

    function attendance(playerID: string, present: boolean): Attend {
        const player = state.allPlayers.find(x => x.id === playerID)
        const attendance: Attend = {
            playerID: playerID,
            name: player ? `${player.attributes.field_player_first_name} ${player.attributes.field_player_last_name}` : t('AttendanceReport00'),
            present: present
        }
        return attendance
    }

    // Set attendance for practice. If no attendance info then default to all players present
    useEffect(() => {
        // if (USEBUNDLES_attencance_and_team_player) {
        //     const attendanceLocal = state.allTeamPlayers
        //         .filter(x => x.relationships.field_team_player_team.data.id === state.curPractice.team.id)
        //         .map(x => {
        //             const player = state.allPlayers.find(y => y.id === x.relationships.field_team_player_player.data.id)
        //             const attend: Attend = {
        //                 playerID: player?.id || '',
        //                 name: `${player?.attributes.field_player_first_name} ${player?.attributes.field_player_last_name}`,
        //                 present: state.allAttendances.find(y => y.relationships.field_attendance_player.data.id === player?.id && y.relationships.field_attendance_practice.data.id === state.curPractice.practiceID) !== undefined,
        //             }
        //             return attend
        //         })
        //     setAttendanceList(attendanceLocal)
        // } else
        if (state.curPractice.attendance.length === 0) {
            const defaultAttendanceList = state.curPractice.team.relationships.field_players.data.map((x: JSONAPITypeId) => attendance(x.id, true))
            setAttendanceList(defaultAttendanceList)
            // we have no attendance info and  we set the attendance list based on players on the team then the show attenance list is dirty
            setDirty(defaultAttendanceList.length > 0)
        } else
            setAttendanceList(state.curPractice.attendance.map((item) => attendance(item.playerID, item.present)))
    }, [state.curPractice]) // rerender when new practice is saved so we start with attendance list with all players seleccted

    // if user changes the team player list then attendance list is dirty because adding a player to the team
    // indicates that the new player is present at the practice and deleting a player indicates that the 
    // player is not present at the practice
    useEffect(() => {
        // don't run on first render
        if (didMountRef.current)
            setDirty(true)
        didMountRef.current = true
    }, [state.allTeams])

    // // Change of team players. If player deleted in MyPlayers then remove player from attendance list
    // useEffect(() => {
    //     // Don't run initially because that would empty the attendance list
    //     if (attendanceList.length !== 0)
    //         setAttendanceList(attendanceList.filter(x => state.allPlayers.find(y => y.id === x.playerID)))
    // }, [state.allPlayers, state.allTeamPlayers])

    // Close dialog with player selection where players and team was updated and set but not save new attendance list for current practice
    async function onCloseDialogMyPlayers() {
        setOpenDialogMyPlayers(false)

        // Get new player list that was set in DialogMyPlayers
        const newPlayerList: DrupalEntity[] = state.allTeams
            .find(x => x.id === state.curPractice.team.id)!.relationships.field_players.data
            .map((x: JSONAPITypeId) => state.allPlayers.find(y => y.id === x.id))

        // Get all players that are in attendance list and newTeamList
        let attendanceListLocal = attendanceList.filter(x => newPlayerList.find(y => y.id === x.playerID))
        // Get all new players and set their attendance to true
        attendanceListLocal = attendanceListLocal.concat(newPlayerList
            .filter(x => !attendanceList.find(y => y.playerID === x.id))
            .map((z) => attendance(z.id!, true)))
        // make sure current practice reflects the updated team
        const action: ActionSetPractice = {
            type: 'setPractice', practice: {
                ...state.curPractice,
                // team: state.curPractice.team, //resp.data,
                attendance: attendanceListLocal,
            }
        }
        dispatch(action)
        setAttendanceList(attendanceListLocal.map(x => attendance(x.playerID, x.present))) // possible new player names
    }

    // // Are we showing default unsaved attendance with all players on the team present?
    // function defaultUnsavedAttendance() {
    //     return (state.user.data.id === state.curPractice.uid && state.curPractice.attendance.length === 0)
    // }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                PaperProps={{
                    style: {
                        borderRadius: 16,
                        width: '95%',
                        maxWidth: '400px'
                    },
                }}
            >

                <ICBDialogTitle onClose={props.onClose} title={t("AttendanceReport11")} />

                <Divider style={{ width: '90%', margin: 'auto' }} />

                {/* case 1: default unsaved attendance with players present */}
                {
                    // defaultUnsavedAttendance() && attendanceList.length > 0
                    // JSON.stringify(state.curPractice.attendance) !== JSON.stringify(attendanceList)
                    dirty
                    && <Typography fontSize="14px" sx={{ color: theme.palette.text.secondary, width: '90%', margin: 'auto' }}>
                        {t("DialogAttendanceListForPractice04")}
                    </Typography>
                }

                <DialogContent>
                    <Grid2 container marginLeft={0} marginBottom={0} alignItems="center">
                        {
                            attendanceList.length > 0 ? (
                                attendanceList.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Grid2 xs={9}>
                                                <Typography fontSize="14px">
                                                    {item.name}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={3} container justifyContent="flex-end">
                                                <Checkbox
                                                    style={{}}
                                                    checked={item.present}
                                                    onChange={() => {
                                                        var localAttendanceList = [...attendanceList]
                                                        localAttendanceList[index].present = !localAttendanceList[index].present
                                                        setAttendanceList(localAttendanceList)
                                                        setDirty(true)
                                                    }}
                                                    // Unexpectedly I need the styling below. Otherwise, the checkbox is not visible when disabled
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: "grey.500", // Use appropriate color from your theme
                                                        },
                                                    }}
                                                    disabled={state.user.data.id !== state.curPractice.uid}
                                                />
                                            </Grid2>
                                            {index < attendanceList.length - 1 && <Divider style={{ width: '100%' }} />}
                                        </Fragment>
                                    )
                                })
                            ) : (
                                <>
                                    {state.user.data.id === state.curPractice.uid ?
                                        <Button
                                            startIcon={<GroupAddOutlinedIcon />}
                                            variant="outlined"
                                            sx={{ textTransform: 'none', fontWeight: 'bold' }}
                                            onClick={() => {
                                                setOpenDialogMyPlayers(true)
                                            }}
                                        >
                                            {t('DialogMyPlayers00')}
                                        </Button>
                                        :
                                        <Typography
                                            fontSize="small"
                                            textAlign="center"
                                            sx={{ color: theme.palette.text.secondary }}
                                        >
                                            {t('DialogAttendanceListForPractice03')}
                                        </Typography>
                                    }
                                </>
                            )
                        }
                    </Grid2>
                </DialogContent>

                {
                    // Only show dialog acitons if the practice belongs to the current user - that might not be the case for clubadmins
                    state.user.data.id === state.curPractice.uid && attendanceList.length > 0
                    && <DialogActions sx={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: 10 }}>
                        {/* Save attendance list */}
                        <Button
                            color='primary'
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                            onClick={async () => {
                                // Warn that save of practice will delete info about use of a now deleted player
                                if (attendanceList.find(x => !state.allPlayers.find(y => y.id === x.playerID))) {
                                    dispatch(getActionSetConfirm(
                                        t('when you save you loose information about players that were at the practice but since were deleted'),
                                        t('OK'),
                                        async () => {
                                            const resp = await savePractice(state, dispatch, { ...state.curPractice, attendance: attendanceList })
                                            if (resp) {
                                                // error saving practice
                                                log.error(resp);
                                                dispatch(getActionSetConfirm(resp));
                                            } else {
                                                setDirty(false)
                                                props.onClose()
                                            }
                                        }));
                                } else {
                                    const resp = await savePractice(state, dispatch, { ...state.curPractice, attendance: attendanceList })
                                    if (resp) {
                                        // error saving practice
                                        log.error(resp);
                                        dispatch(getActionSetConfirm(resp));
                                    } else {
                                        setDirty(false)
                                        props.onClose()
                                        // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                                        setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 2500)
                                    }
                                }
                            }}
                        >
                            {t('DialogAttendanceListForPractice00')}
                        </Button>

                        {/* Open list of players to change list of players on the team */}
                        <Button
                            //variant='outlined'
                            sx={{ position: 'absolute', right: 15, height: '30px' }}
                            onClick={() => {
                                // Make player list come up with a selection of the players on the team
                                // Remember that some of the players on the team might be deleted and we don't want those players selected
                                // in the CRUDList. CRUDList will delete those IDs at delete row and/or select row!
                                // dispatch({ type: 'setCRUDListSelectedValues', selectedValues: attendanceList.filter(x => state.allPlayers.find(y => y.id === x.playerID)).map((item) => item.playerID) })
                                setOpenDialogMyPlayers(true)
                            }}
                        >
                            <GroupAddOutlinedIcon />
                        </Button>

                    </DialogActions>
                }

            </Dialog>

            {/* Include dialog to change list of players on team */}
            <DialogMyPlayers
                open={openDialogMyPlayers}
                onClose={onCloseDialogMyPlayers}
                team={state.curPractice.team}
            />

            <SnackbarMessages
                message={t('DialogAttendanceListForPractice02')}
                open={openSnackbarMessage}
                icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />
        </>
    )
}