import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Context } from '../App';
import { TypeContext, UserType } from '../misc/Types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Chip, Divider, Grid, Typography, lighten, useTheme } from '@mui/material';
import {
    create_checkout_session, getActionSetConfirm, getClub,
    gotoStripePaymentPortal,
    inAppPaymentApple, updateSubscriptionInDrupal,
} from '../misc/Functions';
import log from "../misc/Logger";
import { useTranslation } from 'react-i18next';
import JoinClub from './JoinClub';
import { CheckRounded } from '@mui/icons-material';


interface TypeFormSubscriptionAdjust {
    field_user_type: string,                    // new user type thereby updating the subscription
    field_club_name: string,                    // club admin must set name of new club he creates
    field_my_club_admin_hash: string,           // club user enter club code he/she got from club admin
}

export default function SetSubscription() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('SetSubscription');
    const theme = useTheme();

    // const [updateSubscriptionAfterStripe, setUpdateSubscriptionAfterStripe] = useState(true);
    const [disabled, setDisabled] = useState(false);

    // disable buttons in this component if confirm dialog is shown
    useEffect(() => {
        setDisabled(state.confirm.text !== undefined)
    }, [state.confirm.text])

    // enable buttons if user cancels buy process on iPhone
    useEffect(() => {
        setDisabled(false)
    }, [state.fromApp])

    const formDataInit: TypeFormSubscriptionAdjust = {
        // make sure form field values are not undefinded. If form field value is undefindes then corresponding control is 'uncontrolled' We want a controls to be 'controlled'
        field_user_type: state.user.data.attributes.field_user_type || '',
        field_club_name: state.user.data.attributes.field_club_name || '',
        field_my_club_admin_hash: state.clubInvite || '',
    }

    // State of data in form
    const [formData, setFormData] = useState<TypeFormSubscriptionAdjust>(formDataInit)

    // Set correct usertype on F5/reload
    useEffect(() => {
        setFormData(formDataInit);
    }, [state.user.data.attributes]);

    // Update local state when value in form changes
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        let { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,  // shallow copy all previous values
            [name]: value, // update specific key/value
        }));
    };

    // Press button to change subscription
    async function submitForm(formDataLocal: TypeFormSubscriptionAdjust) {
        // log.debug(`${'SetSubscription()},submitForm(): ${formDataLocal}`)

        // Data passed to the backend when user changes subscription. 
        // Data is passed to backend below OR after payment and restart of app in useEffect above!
        let body_set_subscription = {
            ...formDataLocal,
            "opr": "set_subscription",
        }

        const fromTo = `${state.user.data.attributes.field_user_type}->${body_set_subscription.field_user_type}`

        if (state.nativeApp && !['free->pro', 'free->club'].includes(fromTo)) {
            // If iOS app then only allow free->pro, free->club
            dispatch(getActionSetConfirm(t('AlertMsg34')));
        } else if (['club->pro', 'club->clubadmin'].includes(fromTo)) {
            // A club user should leave club before becomming pro/clubadmin. Otherwise, data for his clubadmin is not updated
            dispatch(getActionSetConfirm(t('AlertMsg40')));
        } else if (!state.nativeApp && state.user.data.attributes.field_app_store_original_transac) {
            // Webapp users who created subscription in App Store should NOT manage subscription from webapp!
            dispatch(getActionSetConfirm(t('AlertMsg34')));
        } else if (formDataLocal.field_user_type === 'club' && formDataLocal.field_my_club_admin_hash.trim() === '') {
            // be club user and no club admin
            dispatch(getActionSetConfirm(t('AlertMsg14')));
        } else if (formDataLocal.field_user_type === 'clubadmin' && formDataLocal.field_club_name.trim() === '') {
            // be clubadmin and no club name
            dispatch(getActionSetConfirm(t('AlertMsg15')));
        } else if (formDataLocal.field_user_type === state.user.data.attributes.field_user_type) {
            // new user type not selected
            dispatch(getActionSetConfirm(t('AlertMsg17')));
        } else {
            // Change subscription from/to
            switch (state.user.data.attributes.field_user_type) {
                case 'free':
                    switch (formDataLocal.field_user_type) {
                        case 'pro':
                            if (state.nativeApp) {
                                // Show payment wall in iOS app
                                inAppPaymentApple(state, dispatch, "1")
                            } else {
                                await create_checkout_session(state, dispatch, body_set_subscription);
                            }
                            break;
                        case 'club':
                            await updateSubscriptionInDrupal(state, dispatch, body_set_subscription);
                            setDisabled(false)
                            break;
                        case 'clubadmin':
                            await create_checkout_session(state, dispatch, body_set_subscription);
                            break;
                    }
                    break;
                case 'pro':
                    switch (formDataLocal.field_user_type) {
                        case 'free':
                            dispatch(getActionSetConfirm('select portal and cancel subscription'))
                            break;
                        case 'club':
                            dispatch(getActionSetConfirm(t('SetSubscription13'), 'ok', async () => await updateSubscriptionInDrupal(state, dispatch, body_set_subscription)));
                            break;
                        case 'clubadmin':
                            dispatch(getActionSetConfirm(t('SetSubscription14'), 'ok', async () => await updateSubscriptionInDrupal(state, dispatch, body_set_subscription)));
                            break;
                    }
                    break;
                case 'club':
                    switch (formDataLocal.field_user_type) {
                        case 'free':
                            dispatch(getActionSetConfirm(t('SetSubscription15'), 'ok', async () => await updateSubscriptionInDrupal(state, dispatch, body_set_subscription)));
                            break;
                        case 'pro':
                            dispatch(getActionSetConfirm(t('SetSubscription16'), 'ok', async () => await create_checkout_session(state, dispatch, body_set_subscription)));
                            break;
                        case 'clubadmin':
                            dispatch(getActionSetConfirm(t('SetSubscription17'), 'ok', async () => await create_checkout_session(state, dispatch, body_set_subscription)));
                            break;
                    }
                    break;
                case 'clubadmin':
                    switch (formDataLocal.field_user_type) {
                        case 'free':
                            dispatch(getActionSetConfirm('select portal and cancel subscription. Remember that all your coaches are turned into free users as well'))
                            break;
                        case 'pro':
                            dispatch(getActionSetConfirm(t('SetSubscription19'), 'ok', async () => await updateSubscriptionInDrupal(state, dispatch, body_set_subscription)));
                            break;
                        case 'club':
                            dispatch(getActionSetConfirm(t('SetSubscription20'), 'ok', async () => await updateSubscriptionInDrupal(state, dispatch, body_set_subscription)));
                            break;
                    }
                    break;
            }
        }
    }

    // Design and action for the cards
    const pricingOptions = [
        {
            // Pro
            userType: UserType.pro,
            title: t('SubscriptionCards00'),
            price: (
                <Grid container justifyContent="left" alignItems="center" sx={{ flexDirection: 'row' }}>
                    <Typography
                        component="span"
                        color={'#DD6F20'}
                        sx={{
                            fontWeight: 'bold',
                            fontSize: {
                                xs: '19px',
                                sm: '30px',
                            },
                        }}
                    >
                        {/* Don't show pricing if web app. Users will see the price once they click subscripe.
                            Furhtermore, I am a bit confused about seeting the price in $ in App Store Connect and the 
                            wish we have to show price in EUR in the card 
                            
                            It seems Apple requires that the price is listet along the subscription benefits!
                            
                            */}
                        {state.nativeApp ? t('SubscriptionCards01a') : t('SubscriptionCards01')}
                    </Typography>
                    {
                        !state.nativeApp &&
                        <Typography
                            sx={{
                                color: '#98a7b0',
                                fontFamily: 'Arial',
                                textAlign: 'center',
                                fontSize: {
                                    xs: '12px',
                                    sm: '15px'
                                },
                                marginLeft: 1 // Añade un pequeño margen para separar el precio de la descripción
                            }}
                        >
                            {!state.nativeApp && t('SubscriptionCards02')}
                        </Typography>
                    }
                </Grid>
            ),
            features: [
                t('PricingCard06Feature00'),
                t('PricingCard06Feature01'),
                t('PricingCard06Feature02'),
                t('PricingCard06Feature03'),
                t('PricingCard06Feature04'),
                t('PricingCard06Feature05'),
                t('DrawerLandscape18'),
                t('PricingCard06Feature10'),
            ],
            backgroundColor: 'white', // Add this line
            fontColor: theme.palette.primary.main, // Add this line
            buttonAction: () => {
                submitForm({ ...formData, field_user_type: 'pro' })
            },
            showChip: state.user.data.attributes.field_user_type === 'pro',
            isVisible: true,
        },
        {
            // Club Admin
            userType: UserType.clubadmin,
            title: t('SubscriptionCards05'),
            price: (
                <>

                    <Grid container justifyContent="left" alignItems="center" sx={{ flexDirection: 'row' }}>
                        <Typography
                            component="span"
                            color={theme.palette.secondary.main}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '19px',
                                    sm: '30px',
                                },
                            }}
                        >
                            {t('SubscriptionCards06')}
                        </Typography>

                        <Typography
                            sx={{
                                color: '#98a7b0',
                                fontFamily: 'Arial',
                                textAlign: 'center',
                                fontSize: {
                                    xs: '12px',
                                    sm: '15px'
                                },
                                marginLeft: 1 // Añade un pequeño margen para separar el precio de la descripción
                            }}
                        >
                            {t('SubscriptionCards07')}
                        </Typography>
                    </Grid>
                </>
            ),
            features: [
                t('PricingCard06Feature00'),
                t('PricingCard06Feature01'),
                t('PricingCard06Feature02'),
                t('PricingCard06Feature03'),
                t('PricingCard06Feature04'),
                t('PricingCard06Feature05'),
                t('PricingCard06Feature06'),
                t('PricingCard06Feature07'),
                t('PricingCard06Feature08'),
                t('PricingCard06Feature09'),
                t('DrawerLandscape18'),
            ],
            backgroundColor: 'white', // Add this line
            fontColor: theme.palette.primary.main, // Add this line
            buttonAction: () => {
                submitForm({ ...formData, field_user_type: 'clubadmin' })
            },
            showChip: state.user.data.attributes.field_user_type === 'clubadmin',
            isVisible: !state.nativeApp,
        },
    ];

    const showPortalLink =
        (['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type) &&
            state.user.data.attributes.field_payment_valid_until) ||
        state.user.data.attributes.field_user_type === 'club';

    const platformPaymentInformation = () =>
        <Fragment>
            <Typography style={{ width: '300px', margin: 'auto', marginTop: 10, fontSize: '12px', color: 'darkgrey' }}>
                {t('AlertMsg34')}
            </Typography>
        </Fragment>

    return (
        < Box >
            {
                state.nativeApp && ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type) ?
                    // Don't show subscription information for subscriptions that can not be created in the native app
                    platformPaymentInformation()
                    : <Fragment>

                        <Box sx={{ width: '90%', maxWidth: '800px', margin: 'auto', paddingTop: 2 }}>
                            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                                {t('ICBAppBar02')}
                            </Typography>
                            <Divider />

                            {state.user.data.attributes.field_user_type === 'free' &&
                                <Typography sx={{ marginTop: 3, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: theme.palette.primary.main }}>
                                    {t('SetSubscription24')}
                                </Typography>
                            }
                            <Grid container spacing={2} marginTop={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
                                {pricingOptions.filter(x => x.isVisible).map((option) => (
                                    <Grid item xs={12} md={6} key={option.userType} sx={{ display: 'flex' }}>
                                        <Card
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: 1,
                                                padding: 1,
                                                backgroundColor: '#00398F',

                                                border: `1px solid ${lighten('#00398F', 0.2)}`,
                                            }}>
                                            <CardContent>
                                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: 5 }}>
                                                    {
                                                        option.showChip && (
                                                            <Chip
                                                                size="small" variant="filled" label={t('SubscriptionCards15')}
                                                                style={{
                                                                    backgroundColor: '#DD6F20', // Hex code for the background color
                                                                    color: 'white', // Font color
                                                                    textTransform: 'none',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            />)
                                                    }
                                                </div>

                                                <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif', textAlign: 'left', fontSize: { xs: '17px', sm: '25px' } }}>
                                                    <span style={{ color: 'white' }}>{option.title}</span>
                                                </Typography>

                                                <Grid item>
                                                    <Typography variant="h6" align="center" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: { xs: '19px', sm: '30px' } }}>
                                                        {option.price}
                                                    </Typography>
                                                </Grid>

                                                {/* Divider */}
                                                <div style={{ height: '1px', backgroundColor: '#98a7b0', margin: '10px 0' }}></div>

                                                {option.features.map((feature, index) => (
                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                                                        <CheckRounded style={{ color: '#DD6F20' }} />
                                                        <Typography sx={{ color: '#dce4ec', marginLeft: 2 }}>
                                                            {feature}
                                                        </Typography>
                                                    </div>
                                                ))}

                                                {option.userType === UserType.clubadmin && (
                                                    // Add textfield to enter new clubname
                                                    <Grid sx={{ width: '95%', margin: 'auto', marginTop: 4 }}>

                                                        {state.user.data.attributes.field_user_type !== 'clubadmin' && (
                                                            <TextField
                                                                id="field_club_name"
                                                                name="field_club_name"
                                                                variant="outlined"
                                                                onChange={handleChange}
                                                                label={t('SubscriptionCards16')}
                                                                value={formData.field_club_name}
                                                                helperText={t('SetSubscription25')}
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#dce4ec', // Cambia el color del contorno a blanco
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#dce4ec', // Cambia el color del contorno a blanco al pasar el mouse
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#dce4ec', // Cambia el color del contorno a blanco cuando está enfocado
                                                                        },
                                                                        color: '#dce4ec', // Cambia el color del texto dentro del input
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: '#dce4ec', // Cambia el color del texto de la etiqueta
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        color: '#dce4ec', // Mantiene el color de la etiqueta en blanco al enfocar
                                                                    },
                                                                    '& .MuiFormHelperText-root': {
                                                                        color: '#dce4ec', // Cambia el color del texto de ayuda a blanco
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>

                                                )}

                                                {option.userType === UserType.club && (
                                                    // Add textfield to enter new clubname
                                                    <Grid container justifyContent="center" style={{ marginTop: 15 }}> {/* Add this line */}
                                                        <TextField
                                                            id="field_my_club_admin_hash"
                                                            name="field_my_club_admin_hash"
                                                            onChange={handleChange}
                                                            label={t('SetSubscription01')}
                                                            variant="outlined"
                                                            value={formData.field_my_club_admin_hash}
                                                            inputProps={{ style: { color: 'grey' } }} // Changes text color
                                                            InputLabelProps={{ style: { color: 'grey' } }} // Changes label color
                                                            InputProps={{
                                                                style: {
                                                                    borderColor: 'white' // Changes outline color
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </CardContent>

                                            {/* Change subscription */}
                                            <Grid container justifyContent="center" alignItems="center" marginTop={1} marginBottom={1}>
                                                <Grid item>
                                                    {
                                                        !option.showChip && (
                                                            <Button
                                                                aria-label={`setsubscription_start_${option.userType}`}
                                                                variant='contained'
                                                                color="secondary"
                                                                onClick={() => {
                                                                    // prevent double click
                                                                    setDisabled(true)
                                                                    handleChange({ target: { name: 'field_user_type', value: option.userType } })
                                                                    setFormData({ ...formData, field_user_type: option.userType })
                                                                    option.buttonAction()
                                                                }}
                                                                disabled={disabled}
                                                                style={{
                                                                    textTransform: 'none',
                                                                    fontWeight: 'bold',
                                                                    minWidth: 200
                                                                }}
                                                            >
                                                                {t('SubscriptionCards03')}
                                                            </Button>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>


                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                            <Divider sx={{ marginTop: 4 }} />

                            <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                                {t('SetSubscription22')}
                            </Typography>

                            {state.user.data.attributes.field_user_type !== 'club' &&
                                < Box style={{ margin: 'auto' }}>
                                    <JoinClub />
                                </Box>
                            }

                            {state.user.data.attributes.field_user_type === 'club' &&
                                <Typography sx={{ paddingTop: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
                                    {t('SetSubscription23')}
                                </Typography>
                            }



                            {
                                // users from the trial period, i.e. 2023, can be !== free and still have no field_payment_subscription_id
                                // These users should not be able to se the portal
                                // App users should never see the Stripe portal!
                                showPortalLink && !state.nativeApp && !state.user.data.attributes.field_app_store_original_transac && (
                                    // Show button to call portal so user can see/cancel his/her subscription

                                    <Fragment>
                                        <Divider sx={{ marginTop: 4 }} />

                                        <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                                            {t('SubscriptionCards17')}
                                        </Typography>

                                        <Button
                                            aria-label={`setsubscription_manage_subscription`}
                                            variant="text"
                                            sx={{
                                                textDecoration: 'none',
                                                textTransform: 'none',
                                                marginTop: 2,

                                            }}
                                            onClick={async () => {
                                                // Option to end subscription at current period end
                                                {
                                                    if ((state.user.data.attributes.field_payment_valid_until &&
                                                        new Date(state.user.data.attributes.field_payment_valid_until).getFullYear() === 2345) ||
                                                        state.user.data.attributes.field_user_type === 'club') {
                                                        // A user with valid until year = 2345 is a user who has been created while payment is not active.
                                                        // This user was able to became a paying user, ie. user type 'pro' or 'clubadmin' without paying.
                                                        // If this user wants to become a 'free' user then we don't end subscription with Stripe. We
                                                        // simply turn user into a user type 'free'
                                                        // 
                                                        // A club user should always be able to leave the club!
                                                        // executeIfConfirmed(state, dispatch,
                                                        //     state.user.data.attributes.field_user_type === 'club' ?
                                                        //         t('SetSubscription21') + getClub(state) :
                                                        //         t('SetSubscription08'),
                                                        //     'ok',
                                                        //     async () => {
                                                        //         await updateSubscriptionInDrupal(state, dispatch, {
                                                        //             "field_user_type": "free",
                                                        //             "opr": "set_subscription",
                                                        //             // environment: getEnvironment(),
                                                        //         });
                                                        //     }
                                                        // );
                                                        dispatch(getActionSetConfirm(state.user.data.attributes.field_user_type === 'club' ?
                                                            t('SetSubscription21') + getClub(state) : t('SetSubscription08'), 'ok', async () => {
                                                                await updateSubscriptionInDrupal(state, dispatch, {
                                                                    "field_user_type": "free",
                                                                    "opr": "set_subscription",
                                                                });
                                                            }));
                                                    } else {
                                                        // Get Stripe Payment Portal
                                                        gotoStripePaymentPortal(state, dispatch)
                                                    }
                                                }
                                            }}
                                        >
                                            {state.user.data.attributes.field_user_type === UserType.club ? t('SubscriptionCards19') : t('Profile24')}
                                        </Button>
                                    </Fragment>
                                )
                            }
                        </Box>

                        {
                            // App users with pro subscription from Stripe should see device warning
                            state.nativeApp
                            && state.user.data.attributes.field_user_type === 'pro'
                            && !state.user.data.attributes.field_app_store_original_transac
                            && platformPaymentInformation()
                        }
                        {
                            // Webapp users with a Apple subscription should see device warning
                            !state.nativeApp
                            && state.user.data.attributes.field_app_store_original_transac
                            && platformPaymentInformation()
                        }
                    </Fragment>
            }
        </Box >
    );
}