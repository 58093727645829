import { Fragment, useContext, useEffect, useState } from "react";
import { PropsExerciseCardSelected, TypeContext, DrupalEntity, JSONAPITypeId } from "../misc/Types";
import { Context } from "../App";
import { Box, Divider, useTheme, Typography, Button } from "@mui/material";
import log from "../misc/Logger";
import { exerciseImage } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import { DialogExerciseDetails } from "./DialogExerciseDetails";
import { DialogShowVideo } from "./DialogShowVideo";

export default function ExerciseCardPrint(props: PropsExerciseCardSelected) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('ExerciseCardPrint', '1', props.exerciseSelected.exercise.id, '2', props.exerciseSelected.exercise.attributes.drupal_internal__nid);
    const theme = useTheme();

    const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false);
    const [exercise, setExercise] = useState(props.exerciseSelected.exercise);
    const [conceptFileID, setConceptFileID] = useState(''); // Controls the opening of the video dialog
    const [conceptTitle, setConceptTitle] = useState(''); // Title for the video dialog

    // find the exercise to show based on the drupal_internal__nid that is saved on the practice entity.
    // if we come here fast after app load we might not have retrived the corresponding state.allExercises
    useEffect(() => {
        const exerciseLocal = state.allExercises.find(x => x.attributes.drupal_internal__nid === props.exerciseSelected.exercise.attributes.drupal_internal__nid);
        if (exerciseLocal)
            setExercise(exerciseLocal);
    }, [state.allExercises, exercise.attributes.drupal_internal__nid]);

    // Protect against "You no longer have access to this exercise"
    const fileIDImage = exercise?.relationships?.field_exercise_board_image?.data?.id;

    return (
        <Fragment>
            <Divider sx={{ borderColor: theme.palette.divider }} />

            {/* Only include DialogExerciseDetails if we have an exercise */}
            {
                exercise &&
                <DialogExerciseDetails
                    open={openDialogExerciseDetails}
                    onClose={() => setOpenDialogExerciseDetails(false)}
                    exercise={exercise}
                    addOption={false}
                />
            }

            {/* Dialog to show the concept video */}
            <DialogShowVideo
                open={conceptFileID !== ''}
                onClose={() => setConceptFileID('')}
                fileIDVideo={conceptFileID}
                concept={conceptTitle}
            />

            <Box display="flex" justifyContent={'space-between'} mt={2} mb={1}>
                <Typography sx={{ fontWeight: 'bold', fontSize: state.portrait ? '13px' : '13px', textTransform: 'uppercase' }}>
                    {props.index! + 1}
                    . {exercise?.attributes.title || t('Generel21')}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: state.portrait ? '13px' : '13px', fontWeight: 'bold' }}>
                    {props.startAndStopTime}
                </Typography>
            </Box>

            <Fragment>
                {
                    // Only show the exercise image if it is NOT 'boardBlank.webp'
                    fileIDImage &&
                    <img
                        src={exerciseImage(state, fileIDImage, exercise.id)}
                        onClick={() => setOpenDialogExerciseDetails(true)}
                        style={{
                            float: 'left',
                            height: 'auto',
                            maxWidth: '48%',
                            borderRadius: '6px',
                            marginRight: '5px'
                        }}
                        alt={t('ExerciseImageAlt')}
                    />
                }

                <Box>
                    
                    {/* Show the exercise description */}
                    {
                        state.uiSettings.showDialogPracticeProgramPrintDescription
                        && <Fragment>
                            <Typography sx={{ fontSize: state.portrait ? '11px' : '11px', fontWeight: 'bold', textDecoration: 'underline' }}>
                                {t('ExerciseCardPrint00')}
                            </Typography>
                            <Typography sx={{ fontSize: '11px', marginTop: '1px', lineHeight: '1.3', whiteSpace: 'pre-wrap', color: theme.palette.text.secondary }}>
                                {exercise?.attributes.field_description}
                            </Typography>
                        </Fragment>
                    }

                    {/* Coach notes if they exist */}
                    {state.uiSettings.showDialogPracticeProgramPrintNote && props.exerciseSelected?.coachNote
                        && <Fragment>
                            <Typography sx={{ fontSize: '11px', marginTop: 1, fontWeight: 'bold', textDecoration: 'underline' }}>
                                {t('ExerciseCardPrint01')}
                            </Typography>
                            <Typography sx={{ fontSize: '11px', marginTop: '1px', lineHeight: '1.3', whiteSpace: 'pre-wrap', color: theme.palette.text.secondary }}>
                                {props.exerciseSelected?.coachNote}
                            </Typography>
                        </Fragment>
                    }

                    {/* List of concepts if they exist */}
                    {
                        state.uiSettings.showDialogPracticeProgramPrintTeachingPoints
                        && exercise?.relationships?.field_concepts?.data.length > 0 && (
                            <Fragment>
                                <Typography sx={{ fontSize: '11px', marginTop: 1, fontWeight: 'bold', textDecoration: 'underline' }}>
                                    {t('ExerciseCardPrint02')}
                                </Typography>
                                <Box display="flex" flexWrap="wrap" gap={0.5} mt={0.5}>
                                    {
                                        exercise.relationships.field_concepts.data.map((conceptRef: JSONAPITypeId) =>
                                            state.allConcepts.find(x => x.id === conceptRef.id)
                                        ).map((concept: DrupalEntity) => (

                                            <Button
                                                key={concept.id}
                                                variant='text'
                                                sx={{
                                                    fontSize: { xs: 9, sm: 10 },
                                                    textTransform: 'none',
                                                    fontWeight: 'bold',
                                                    borderRadius: '20px',
                                                    cursor: 'pointer',
                                                    padding: '1px 4px',
                                                    color: theme.palette.text.primary,
                                                    border: '1px solid lightgray',
                                                }}
                                                onClick={() => {
                                                    if (concept.relationships.field_concept_video?.data?.id) {
                                                        setConceptFileID(concept.relationships.field_concept_video.data.id);
                                                        setConceptTitle(concept.attributes.title);
                                                        log.info(`DialogShowVideo() - ${concept.attributes.title}`);
                                                    } else {
                                                        log.info(`No video available for the concept: ${concept.attributes.title}`);
                                                    }
                                                }}
                                            >
                                                {concept.attributes.title}
                                            </Button>

                                        ))
                                    }
                                </Box>
                            </Fragment>
                        )
                    }
                </Box>
            </Fragment>
        </Fragment >
    );
}