import { Box, Button, Tooltip, IconButton } from "@mui/material";
import log from "loglevel";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';

export interface PropsAddBUttonAboveLists {
    buttonText: string,                 // text on add button
    addCode: () => any                  // code to execute on add button press
    tooltip?: string,                   // optional text on information tool tip on the far right
}

export default function AddBUttonAboveLists(props: PropsAddBUttonAboveLists) {
    log.debug('AddBUttonAboveLists');

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%' // Asegura que el Box ocupe todo el ancho disponible
            }}
        >

            {/* Add button */}
            <Button
                // disabled={disabled}
                variant="outlined"
                startIcon={<AddIcon />}
                size="small"
                onClick={() => props.addCode()}
                sx={{ textTransform: 'none' }} // Eliminado marginTop para alinear verticalmente
            >
                {props.buttonText}
            </Button>

            {/* Optional tooltip on the far right */}
            {
                props.tooltip
                && <Tooltip title={props.tooltip}>
                    {/* <IconButton sx={{ color: theme.palette.text.secondary }}> */}
                    <IconButton>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            }
        </Box>
    )
}