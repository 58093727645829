import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import log from "../misc/Logger";
import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { getActionSetConfirm } from '../misc/Functions';
import { useTranslation } from 'react-i18next';

interface PropsDialogGetSingleLineOfText {
    open: boolean,
    initText?: string,
    onClose: (ok: boolean, value: string) => void;
    title: string,
    contentLine1?: string,
    checkBoxLabel?: string,
    label: string,
    button: string,
}

export default function DialogGetSingleLineOfText(props: PropsDialogGetSingleLineOfText) {
    const [text, setText] = useState(props.initText || '')
    const { t } = useTranslation();
    log.debug('DialogGetSingleLineOfText');

    const { state, dispatch } = useContext(Context) as TypeContext;
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const theme = useTheme();

    const handleOK = () => {
        setDisabled(true)
        if (props.checkBoxLabel) {
            if (checkboxChecked)
                props.onClose(true, text || '')
            else
                dispatch(getActionSetConfirm(t('DialogSingleLineOfText00')));
        } else {
            props.onClose(true, text || '')
        }
        setDisabled(false);
    };

    return (
        <Dialog
            onClose={() => props.onClose(false, text || '')}
            open={props.open}
            disableRestoreFocus={true}
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    width: '95%',
                    maxWidth: '500px',
                    position: state.nativeApp ? 'absolute' : 'static',
                    top: state.nativeApp ? '5%' : undefined,
                },
            }}>
            <DialogTitle fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'PT Sans, sans-serif' }}>
                {props.title}
                <IconButton onClick={() => props.onClose(false, text || '')} edge="end" aria-label='close_dialoggetsinglelineoftext'>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {/*Divider*/}
            <div style={{ height: '1px', backgroundColor: '#ccc', width: '90%', margin: '0 auto' }}></div>

            <DialogContent sx={{}}>
                {props.contentLine1 && (
                    <DialogContentText
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: '15px',
                            margin: '0 auto 16px',
                            textAlign: 'center',
                        }}
                    >
                        {props.contentLine1}
                    </DialogContentText>
                )}

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                    <TextField
                        value={text}
                        color='primary'
                        margin="dense"
                        label={props.label}
                        size="small"
                        inputProps={{ maxLength: 60 }}
                        autoFocus
                        sx={{ width: '90%', maxWidth: '350px' }}
                        onChange={(e) => { setText(e.target.value) }}
                    />
                </div>

                {
                    props.checkBoxLabel
                    && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxChecked}
                                    onChange={(e) => setCheckboxChecked(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={props.checkBoxLabel}
                            sx={{
                                marginTop: 1,
                                color: theme.palette.text.secondary,
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '12px',
                                },
                            }}
                        />
                    </div>
                }

            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    aria-label='ok_dialoggetsinglelineoftext'
                    onClick={handleOK}
                    disabled={disabled}
                    variant='contained'
                    size="small"
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    style={{ marginBottom: 20 }}>
                    {props.button}
                </Button>
            </div>
        </Dialog>
    );
}