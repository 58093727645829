import { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { DrupalEntity, TypeContext } from '../misc/Types';
import { Context } from '../App';
import log from "../misc/Logger";
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import MyPlayers from './MyPlayers';

interface PropsDialogMyPlayers {
    open: boolean,
    onClose: () => void, // call with true if dialog action is to be performed. Call with false if dialog action is cancelled
    team: DrupalEntity,
}

export default function DialogMyPlayers(props: PropsDialogMyPlayers) {
    const { state } = useContext(Context) as TypeContext;
    log.debug('DialogMyPlayers');
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.open}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '750px',
                    borderRadius: 10,
                    position: state.nativeApp ? 'absolute' : 'static', // Aplica posición absoluta solo si el TextField está enfocado y es una app nativa
                    top: state.nativeApp ? '5%' : undefined, // Aplica el top solo si el TextField está enfocado y es una app nativa
                },
            }}
        >

            <DialogTitle fontWeight={'bold'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {t('DialogMyPlayers00')}
                <IconButton onClick={() => props.onClose()} edge="end" aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <MyPlayers
                onClose={props.onClose}
                team={props.team}
            />
        </Dialog>
    );
}