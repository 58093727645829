import log from 'loglevel';                     // logging, basesystem
import remote from 'loglevel-plugin-remote';    // logging, remote plugin
import prefix from 'loglevel-plugin-prefix';    // logging, prefix plugin
import { BACKEND } from './Constants';

// Log to Drupal backend 
remote.apply(log, {url: `${BACKEND}/logger`, timestamp: () => '',});
// Prefix for log lines. Default to Anonymous. Change when we know who logs on
prefix.reg(log)
prefix.apply(log, {template: 'Anonymous (0)'}) // start of app

// Default log level
if (window.location.pathname === '/debug') 
    log.setLevel('debug');
else if (window.location.pathname === '/trace') 
    log.setLevel('trace');
else 
    log.setLevel('info');

export default log;
