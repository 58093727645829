import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { ActionSetSelectedExercises, DrupalEntity, OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import log from "../misc/Logger";
import SnackbarMessages from './SnackbarMessages';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface PropsDialogExerciseDurationAndFocus {
    open: boolean,
    onClose: (ok: boolean) => void, // return true if exercise was added. Otherwise, return false. This enables parent to close or not
    exercise: DrupalEntity,
}

export default function DialogExerciseDurationAndFocus(props: PropsDialogExerciseDurationAndFocus) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DialogExerciseDurationAndFocus');

    const [duration, setDuration] = useState(15)
    const [coachNote, setCoachNote] = useState('')
    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

    return (
        <>
            <Dialog
                open={props.open}
                PaperProps={{
                    style: {
                        borderRadius: 16,
                        position: state.nativeApp ? 'absolute' : 'static', // Aplica posición absoluta solo si el TextField está enfocado y es una app nativa
                        top: state.nativeApp ? '5%' : undefined, // Aplica el top solo si el TextField está enfocado y es una app nativa
                    },
                }}
            >

                <DialogTitle fontWeight={'bold'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {t('ExerciseCard01')}
                    <IconButton onClick={() => props.onClose(false)} edge="end" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {/* Divider */}
                <div style={{ height: '1px', backgroundColor: '#ccc', width: '90%', margin: '0 auto' }}></div>

                <DialogContent>

                    <DialogContentText style={{ fontFamily: 'PT Sans, sans-serif', fontSize: 17, marginLeft: 10 }}>
                        {t('ExerciseCard02')}
                    </DialogContentText>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                        <FormControl size="small" style={{ marginBottom: '8px' }}>
                            <InputLabel id="duration-label">{t('Generel02')}</InputLabel>
                            <Select
                                labelId="duration-label"
                                id="duration"
                                value={duration}
                                onChange={(e) => setDuration(Number(e.target.value))}
                                label={t('Generel02')}
                            >
                                {Array.from({ length: 31 }, (_, i) => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            color='secondary'
                            margin="dense"
                            id="name"
                            label={t('Generel08')}
                            size="small"
                            multiline
                            value={coachNote}
                            inputProps={{ maxLength: 300 }}
                            onChange={(e) => {
                                setCoachNote(e.target.value);
                            }}
                        />
                    </div>
                </DialogContent>

                <DialogActions>

                    {/* Add exercise to state.curPractice.selectedExercises or to state.selectedExercisesForPracticeDraft */}
                    <Button onClick={() => {
                        props.onClose(true)
                        const curList = state.operationMode === OperationMode.exercise
                            ? state.curPractice.selectedExercises
                            : state.curPreplannedPractice.selectedExercises
                        const action: ActionSetSelectedExercises = {
                            type: 'setSelectedExercises',
                            selectedExercises: [
                                ...curList,
                                {
                                    exercise: props.exercise,
                                    coachNote: coachNote,
                                    // uuidListElement: '', // value will be set in reducer
                                    durationMinutes: duration,
                                    drupal_internal__nid: props.exercise.attributes.drupal_internal__nid,
                                }
                            ]
                        }
                        dispatch(action);
                        dispatch({ type: 'setPracticeProgramDirty', dirty: true })

                        //Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                        setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)

                    }}
                        autoFocus variant="contained" style={{ borderRadius: 20, marginRight: 10, marginBottom: 10 }}>
                        {t('ExerciseCard04')}
                    </Button>

                </DialogActions>
            </Dialog>

            <SnackbarMessages
                message={t('DialogExerciseDurationAndFocus00')}
                open={openSnackbarMessage}
                icon={<CheckCircleRoundedIcon fontSize="small" sx={{ color: '#DD6F20' }} />}
                color={'#DD6F20'}
                landscapeVerticalPosition='bottom'
                landscapeHorizontalPosition='right'
            />

        </>
    );
}