import ReactDOMServer from "react-dom/server";
import { Frame } from "./DialogPlayBuilder";
import BasketballFullCourt from "./BasketballFullCourt";
import BasketballHalfCourt from "./BasketballHalfCourt";
import i18next from "./i18n";
import { DrupalEntity } from "../misc/Types";

// SVG CONTENT
export function generateSVGContent(framesData: Frame[], courtType: string) {
  const courtSVG = ReactDOMServer.renderToStaticMarkup(
    courtType === "fullcourt" ? <BasketballFullCourt /> : <BasketballHalfCourt />
  );
  const [courtWidth, courtHeight] =
    courtType === "fullcourt" ? [1820, 1100] : [1084, 908];

  const allPlayers = framesData.flatMap((f) => f.players);
  const allCones = framesData.flatMap((f) => f.cones || []);

  // Players SVG (unchanged, uses IDs)
  const playersSVG = Array.from(new Set(allPlayers.map((p) => p.id)))
    .map((id) => {
      const player = allPlayers.find((p) => p.id === id);
      if (!player) return "";
      const textColor = player.isDefender ? "red" : "black";
      const circleStroke = player.isDefender ? "red" : "black";
      return `
      <g id="player-${player.id}" data-is-defender="${player.isDefender ? 'true' : 'false'}" style="display: none;">
        ${player.hasBall
          ? `<circle cx="${player.x * 2}" cy="${player.y * 2}" r="30" stroke="${circleStroke}" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"/>`
          : ""}
        <text 
          x="${player.x * 2}" 
          y="${player.y * 2}" 
          text-anchor="middle" 
          alignment-baseline="middle" 
          font-family="Roboto, sans-serif" 
          font-size="44" 
          font-weight="bold" 
          fill="${textColor}"
          text-rendering="geometricPrecision"
          paint-order="stroke"
          stroke="#FFFFFF"
          stroke-width="2"
        >
          ${player.isCoach ? "C" : player.isDefender ? `x${player.number}` : player.number}
        </text>
      </g>
    `;
    })
    .join("");

  // Lines SVG grouped by frame, no IDs
  const linesSVG = framesData
    .map((frame, frameIndex) => {
      const frameLines = frame.lines
        .map((line) => {
          const fromPlayer = allPlayers.find((p) => p.id === line.fromId);
          const lineColor = (fromPlayer && fromPlayer.isDefender) ? "red" : "black";
          if (line.type === "zigzag" && line.midX !== undefined && line.midY !== undefined) {
            return `
              <path 
                class="line-part1" 
                d="M0 0" 
                stroke="${lineColor}" 
                stroke-width="4" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                style="display: none;"
              />
              <path 
                class="line-part2" 
                d="M0 0" 
                stroke="${lineColor}" 
                stroke-width="4" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                style="display: none;"
              />
            `;
          } else {
            return `
              <path 
                d="M0 0" 
                stroke="${lineColor}" 
                stroke-width="4" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                style="display: none;"
              />
            `;
          }
        })
        .join("");
      return `<g class="frame-lines" data-frame="${frameIndex}" style="display: none;">${frameLines}</g>`;
    })
    .join("");

  // Cones SVG (unchanged, uses IDs)
  const conesSVG = allCones.length > 0
    ? Array.from(new Set(allCones.map((c) => c.id)))
      .map((id) => {
        const cone = allCones.find((c) => c.id === id);
        if (!cone) return "";
        const scale = (24 / 16) * 2;
        const x = cone.x * 2 - (24 * 2) / 2;
        const y = cone.y * 2 - (24 * 2) / 2;
        return `
          <g id="cone-${cone.id}" style="display: none;">
            <path
              d="M${x + 6 * scale},${y + 15 * scale}
              L${x + 9 * scale},${y + 2 * scale}
              Q${x + 10 * scale},${y + 1 * scale},${x + 11 * scale},${y + 2 * scale}
              L${x + 14 * scale},${y + 15 * scale}
              L${x + 6 * scale},${y + 15 * scale}Z 
              M${x + 4 * scale},${y + 15 * scale}
              Q${x + 3 * scale},${y + 15 * scale},${x + 3 * scale},${y + 15.5 * scale}
              L${x + 3 * scale},${y + 16 * scale}
              L${x + 17 * scale},${y + 16 * scale}
              L${x + 17 * scale},${y + 15.5 * scale}
              Q${x + 17 * scale},${y + 15 * scale},${x + 16 * scale},${y + 15 * scale}"
              fill="${cone.color}"
            />
          </g>
        `;
      })
      .join("")
    : "";

  const ressvg = `
    <svg 
      id="animation-svg" 
      viewBox="0 0 ${courtWidth} ${courtHeight}"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
    >
      <g id="courtSVG">
        ${courtSVG}
      </g>
      <g id="playersSVGlinesSVG">
        ${playersSVG}
        ${linesSVG}
        ${conesSVG}
      </g>
    </svg>
  `;
  return ressvg;
}

// GENERATE ANIMATION SCRIPT
export const generateAnimationScript = (framesData: Frame[], play: DrupalEntity, autoDownload: boolean = false) => {
  const DRAW_SPEED_PX_PER_MS = 0.5;
  const ZIGZAG_DRAW_SPEED_PX_PER_MS = 0.8;
  const MARKER_DELAY_SECONDS = 0.5;
  const MOVEMENT_SPEED_PX_PER_MS = 0.6;
  const FPS = 75;
  const END_PAUSE_MS = 50;
  const DOWNLOAD_DELAY_MS = 50;

  type GroupMeta = {
    groupTimeMs: number;
    drawTimeMs: number;
    markerTimeMs: number;
    moveTimeMs: number;
    lines: any[];
    combinedPaths?: { [playerId: string]: string };
  };

  const framesMeta = framesData.map((frame) => {
    const linesOrdered = [...frame.lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    const groups: GroupMeta[] = [];
    let currentGroup: any[] = [];

    for (let i = 0; i < linesOrdered.length; i++) {
      const ln = linesOrdered[i];
      if (i === 0 || !ln.sameMomentAsPrevious) {
        currentGroup = [ln];
        groups.push({
          groupTimeMs: 0,
          drawTimeMs: 0,
          markerTimeMs: 0,
          moveTimeMs: 0,
          lines: currentGroup,
          combinedPaths: {},
        });
      } else {
        currentGroup.push(ln);
      }
    }

    return {
      frame,
      groups,
      frameTimeMs: 0,
    };
  });

  const script = `
    const framesData = ${JSON.stringify(framesData)};
    let framesMeta = ${JSON.stringify(framesMeta)};
    const DRAW_SPEED_PX_PER_MS = ${DRAW_SPEED_PX_PER_MS};
    const ZIGZAG_DRAW_SPEED_PX_PER_MS = ${ZIGZAG_DRAW_SPEED_PX_PER_MS};
    const MARKER_DELAY_SECONDS = ${MARKER_DELAY_SECONDS};
    const MOVEMENT_SPEED_PX_PER_MS = ${MOVEMENT_SPEED_PX_PER_MS};
    const FPS = ${FPS};
    const END_PAUSE_MS = ${END_PAUSE_MS};
    const DOWNLOAD_DELAY_MS = ${DOWNLOAD_DELAY_MS};
    const SCALE_FACTOR = 2;

    let totalDuration = 0;
    let cumulativeFrameDurations = [];
    let isPlaying = false;
    let currentTime = 0;
    let lastTimestamp = null;
    let isRecording = false;

    const playBtn = document.getElementById("play-pause");
    const progressBar = document.getElementById("progress-bar");
    const downloadBtn = document.getElementById("download-video");
    const svgRoot = document.getElementById("animation-svg");
    const courtSVG = document.getElementById("courtSVG");
    const playersSVGlinesSVG = document.getElementById("playersSVGlinesSVG");

    const players = {};
    const cones = {};
    const movingElements = {};
    const endElements = {};
    const currentPositions = {};

    // Create overlay elements
    const overlay = document.createElement("div");
    overlay.id = "recording-overlay";
    overlay.style.cssText = "position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); display: none; z-index: 1000; flex-direction: column; justify-content: center; align-items: center;";
    const overlayText = document.createElement("div");
    overlayText.textContent = "${i18next.t('PlayAnimationHTMLDoc02')}";
    overlayText.style.cssText = "color: white; font-size: 18px; font-family: sans-serif;";
    const timerText = document.createElement("div");
    timerText.style.cssText = "color: white; font-size: 14px; font-family: sans-serif; margin-top: 20px;";
    overlay.appendChild(overlayText);
    overlay.appendChild(timerText);
    document.body.appendChild(overlay);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d", { alpha: false, desynchronized: true });
    const dpr = window.devicePixelRatio || 1;
    const [baseWidth, baseHeight] = svgRoot?.getAttribute("viewBox")?.split(" ").slice(2).map(Number) || [1820, 1100];
    canvas.width = baseWidth * dpr;
    canvas.height = baseHeight * dpr;
    canvas.style.width = baseWidth + "px";
    canvas.style.height = baseHeight + "px";
    ctx.scale(dpr, dpr);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    const stream = canvas.captureStream(FPS);
    const videoMimeType = MediaRecorder.isTypeSupported("video/mp4; codecs=avc1") 
      ? "video/mp4; codecs=avc1" 
      : "video/webm";
    const recorder = new MediaRecorder(stream, { 
      mimeType: videoMimeType,
      videoBitsPerSecond: 10000000 // quality
    });

    const chunks = [];

    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = () => {
      const extension = videoMimeType.includes("mp4") ? "mp4" : "webm";
      const blob = new Blob(chunks, { type: videoMimeType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "${play.attributes.title}";
      a.click();
      URL.revokeObjectURL(url);
      if (!videoMimeType.includes("mp4")) {
        alert("Saved as WebM due to browser limitations. For best compatibility, convert to MP4 using VLC or similar software.");
      }
      isRecording = false;
      overlay.style.display = "none";
      enableControls();
    };

    window.onload = () => {
      framesData.forEach(f => {
        f.players.forEach(p => {
          const pe = document.getElementById("player-" + p.id);
          if (pe) players[p.id] = pe;
        });
        (f.cones || []).forEach(c => {
          const ce = document.getElementById("cone-" + c.id);
          if (ce) cones[c.id] = ce;
        });
      });
      measureAndComputeTimings();
      updateAnimation(0);

      if (${autoDownload})
        downloadBtn.click();
    };

    playBtn.onclick = () => {
      if (isRecording) return;
      isPlaying = !isPlaying;
      playBtn.textContent = isPlaying ? "⏸" : "▶";
      if (isPlaying) {
        lastTimestamp = null;
        requestAnimationFrame(animate);
      }
    };

    progressBar.oninput = (e) => {
      if (isRecording) return;
      const value = parseFloat(e.target.value);
      currentTime = (value / 1000) * totalDuration;
      lastTimestamp = null;
      updateAnimation(currentTime);
    };

    downloadBtn.onclick = () => {
      if (!MediaRecorder.isTypeSupported(videoMimeType)) {
        alert("Video recording is not supported in this browser.");
        return;
      }
      if (isRecording) return;

      isRecording = true;
      disableControls();
      overlay.style.display = "flex";
      chunks.length = 0;
      currentTime = 0;
      updateAnimation(0);
      renderToCanvas();
      
      setTimeout(() => {
        recorder.start();
        isPlaying = true;
        playBtn.textContent = "⏸";
        lastTimestamp = null;
        requestAnimationFrame(recordAnimation);
      }, DOWNLOAD_DELAY_MS);
    };

    //Illustrate download time while recording
    function updateTimer() {
      if (!isRecording) return;
      const timeLeft = Math.max(0, totalDuration - currentTime);
      const secondsLeft = (timeLeft / 1000).toFixed(1);
      timerText.textContent = \`${i18next.t('PlayAnimationHTMLDoc01')} \${secondsLeft}s\`;
    }

    function disableControls() {
      playBtn.disabled = true;
      downloadBtn.disabled = true;
    }

    function enableControls() {
      playBtn.disabled = false;
      downloadBtn.disabled = false;
    }

    async function animate(timestamp) {
      if (!isPlaying) return;
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;
      lastTimestamp = timestamp;
      currentTime += delta;

      if (currentTime > totalDuration) {
        currentTime = totalDuration;
        isPlaying = false;
        playBtn.textContent = "▶";
      }
      if (currentTime <= totalDuration - END_PAUSE_MS) {
        updateAnimation(currentTime);
      }
      requestAnimationFrame(animate);
    }

    async function recordAnimation(timestamp) {
      if (!isPlaying) {
        recorder.stop();
        return;
      }
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;
      lastTimestamp = timestamp;
      currentTime += delta;

      updateTimer();

      if (currentTime > totalDuration) {
        currentTime = totalDuration;
        isPlaying = false;
        playBtn.textContent = "▶";
        recorder.stop();
      } else {
        if (currentTime <= totalDuration - END_PAUSE_MS) {
          updateAnimation(currentTime);
        }
        renderToCanvas();
        requestAnimationFrame(recordAnimation);
      }
    }

    function renderToCanvas() {
      const svgData = new XMLSerializer().serializeToString(svgRoot);
      const img = new Image();
      img.onload = () => {
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, baseWidth, baseHeight);
      };
      img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
    }

    function updateAnimation(time) {
      time = Math.min(time, totalDuration);
      if (totalDuration <= 0) return;

      progressBar.value = (time / totalDuration) * 1000;

      let frameIndex = cumulativeFrameDurations.findIndex(dur => time < dur);
      if (frameIndex === -1) frameIndex = framesMeta.length - 1;

      resetAll();

      const frameStart = frameIndex === 0 ? 0 : cumulativeFrameDurations[frameIndex - 1];
      const timeInFrame = time - frameStart;

      const fm = framesMeta[frameIndex];
      if (!fm) return;

      const { frame, groups } = fm;

      const frameGroups = document.querySelectorAll(".frame-lines");
      frameGroups.forEach((group, idx) => {
        group.style.display = idx === frameIndex ? "" : "none";
      });
      const currentFrameGroup = frameGroups[frameIndex];
      const frameLines = currentFrameGroup.querySelectorAll("path");

      frame.players.forEach(p => {
        currentPositions[p.id] = { x: p.x * SCALE_FACTOR, y: p.y * SCALE_FACTOR };
      });

      frame.players.forEach(p => {
        const pe = players[p.id];
        if (pe) {
          pe.style.display = "";
          setPlayerPos(pe, currentPositions[p.id].x, currentPositions[p.id].y);
          toggleBall(pe, p.hasBall);
        }
      });

      frame.cones?.forEach(c => {
        const ce = cones[c.id];
        if (ce) ce.style.display = "";
      });

      let currentBallHolders = new Set(frame.players.filter(p => p.hasBall).map(p => p.id));
      let groupStartMs = 0;
      let lineIndexOffset = 0;

      for (let gIdx = 0; gIdx < groups.length; gIdx++) {
        const g = groups[gIdx];
        const groupEndMs = groupStartMs + g.groupTimeMs;
        let localTime = timeInFrame - groupStartMs;

        if (localTime < 0) break;
        if (localTime > g.groupTimeMs) localTime = g.groupTimeMs;

        const { drawTimeMs, markerTimeMs, moveTimeMs, combinedPaths = {} } = g;
        const phase2Start = drawTimeMs;
        const phase2End = drawTimeMs + markerTimeMs;

        let groupLineIndex = lineIndexOffset; // Start index for this group

        g.lines.forEach((ln, lnIdx) => {
          const isDoubleZigzag = ln.type === "zigzag" && ln.midX !== undefined && ln.midY !== undefined;
          const lineIndex = groupLineIndex; // Index for this line's first path

          const lineEl = isDoubleZigzag ? frameLines[lineIndex] : frameLines[lineIndex];
          if (!lineEl) return;

          setPathD(lineEl, ln, frame.players, frameLines, lineIndex);

          if (localTime <= phase2Start) {
            const partialMs = localTime;
            drawLineSegment(lineEl, ln, partialMs, drawTimeMs, frameLines, lineIndex);
          } else if (localTime <= phase2End) {
            fullyDrawLine(lineEl, ln, frameLines, lineIndex);
          } else {
            if (isDoubleZigzag) {
              const lineElPart1 = frameLines[lineIndex];
              const lineElPart2 = frameLines[lineIndex + 1];
              if (lineElPart1) lineElPart1.style.display = "none";
              if (lineElPart2) lineElPart2.style.display = "none";
            } else {
              lineEl.style.display = "none";
            }
            removeEndElement(ln.id);

            const fromP = frame.players.find(p => p.id === ln.fromId);
            const toP = ln.toId ? frame.players.find(p => p.id === ln.toId) : null;

            const timeIntoPhase3 = localTime - phase2End;
            const moveFrac = moveTimeMs > 0 ? (timeIntoPhase3 / moveTimeMs) : 1;

            if (ln.type === "dashed" && fromP && currentBallHolders.has(fromP.id)) {
              currentBallHolders.delete(fromP.id);
              removePlayerBall(fromP.id);
              animateBallAlongPath(lineEl, ln, moveFrac, fromP, toP);
              if (moveFrac >= 1 && toP) {
                currentBallHolders.add(toP.id);
                toggleBall(players[toP.id], true);
                removeMovingElement(ln.id);
              }
            } else if (ln.type === "zigzag" && fromP && currentBallHolders.has(fromP.id)) {
              animatePlayerWithBall(lineEl, ln, moveFrac, fromP, combinedPaths);
            } else if (ln.type === "shot" && fromP && currentBallHolders.has(fromP.id)) {
              currentBallHolders.delete(fromP.id);
              removePlayerBall(fromP.id);
              animateShotAlongPath(lineEl, ln, moveFrac, fromP);
              if (moveFrac >= 1) {
                removeMovingElement(ln.id);
              }
            } else if (fromP && (ln.type === "straight" || ln.type === "bar")) {
              animateAlongPath(lineEl, ln, moveFrac, fromP, combinedPaths);
            }
          }

          // Increment groupLineIndex based on line type
          groupLineIndex += isDoubleZigzag ? 2 : 1;
        });

        lineIndexOffset = groupLineIndex; // Update offset for next group
        groupStartMs = groupEndMs;
      }

      frame.players.forEach(p => {
        const pe = players[p.id];
        if (pe) {
          setPlayerPos(pe, currentPositions[p.id].x, currentPositions[p.id].y);
          toggleBall(pe, currentBallHolders.has(p.id));
        }
      });
    }

    function measureAndComputeTimings() {
      totalDuration = 0;
      cumulativeFrameDurations = [];

      for (let fIdx = 0; fIdx < framesMeta.length; fIdx++) {
        const fm = framesMeta[fIdx];
        let frameTime = 0;
        const frameLines = document.querySelector(\`.frame-lines[data-frame="\${fIdx}"]\`).querySelectorAll("path");
        let lineIndexOffset = 0;

        fm.groups.forEach(g => {
          let maxDrawTime = 0;
          let maxMoveTime = 0;
          const playerLines = {};
          const combinedPaths = {};

          g.lines.forEach(ln => {
            if (!playerLines[ln.fromId]) playerLines[ln.fromId] = [];
            playerLines[ln.fromId].push(ln);
          });

          Object.keys(playerLines).forEach(playerId => {
            const lines = playerLines[playerId];
            if (lines.length > 1) {
              let combinedD = "M " + (lines[0].fromX * SCALE_FACTOR) + " " + (lines[0].fromY * SCALE_FACTOR);
              lines.forEach(ln => {
                if (ln.midX !== undefined && ln.midY !== undefined && ln.controlX2 !== undefined && ln.controlY2 !== undefined) {
                  combinedD += " Q " + (ln.controlX * SCALE_FACTOR) + " " + (ln.controlY * SCALE_FACTOR) + " " + (ln.midX * SCALE_FACTOR) + " " + (ln.midY * SCALE_FACTOR) + " Q " + (ln.controlX2 * SCALE_FACTOR) + " " + (ln.controlY2 * SCALE_FACTOR) + " " + (ln.toX * SCALE_FACTOR) + " " + (ln.toY * SCALE_FACTOR);
                } else {
                  combinedD += " Q " + (ln.controlX * SCALE_FACTOR) + " " + (ln.controlY * SCALE_FACTOR) + " " + (ln.toX * SCALE_FACTOR) + " " + (ln.toY * SCALE_FACTOR);
                }
              });
              combinedPaths[playerId] = combinedD;
            }
          });

          g.combinedPaths = combinedPaths;

          let groupLineIndex = lineIndexOffset;

          g.lines.forEach((ln) => {
            const isDoubleZigzag = ln.type === "zigzag" && ln.midX !== undefined && ln.midY !== undefined;
            const lineIndex = groupLineIndex;
            let drawLength = 0;
            let moveLength = 0;
            let drawSpeed = DRAW_SPEED_PX_PER_MS;

            if (isDoubleZigzag) {
              drawSpeed = ZIGZAG_DRAW_SPEED_PX_PER_MS;
              const lineElPart1 = frameLines[lineIndex];
              const lineElPart2 = frameLines[lineIndex + 1];
              if (lineElPart1 && lineElPart2) {
                setPathD(lineElPart1, ln, fm.frame.players, frameLines, lineIndex);
                setPathD(lineElPart2, ln, fm.frame.players, frameLines, lineIndex);
                drawLength = lineElPart1.getTotalLength() + lineElPart2.getTotalLength();
              }
              const fromX = ln.fromX * SCALE_FACTOR;
              const fromY = ln.fromY * SCALE_FACTOR;
              const ctrlX = ln.controlX * SCALE_FACTOR;
              const ctrlY = ln.controlY * SCALE_FACTOR;
              const midX = ln.midX * SCALE_FACTOR;
              const midY = ln.midY * SCALE_FACTOR;
              const ctrlX2 = ln.controlX2 * SCALE_FACTOR;
              const ctrlY2 = ln.controlY2 * SCALE_FACTOR;
              const toX = ln.toX * SCALE_FACTOR;
              const toY = ln.toY * SCALE_FACTOR;
              const smoothD = \`M \${fromX} \${fromY} Q \${ctrlX} \${ctrlY} \${midX} \${midY} Q \${ctrlX2} \${ctrlY2} \${toX} \${toY}\`;
              const tempPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
              tempPath.setAttribute("d", smoothD);
              svgRoot.appendChild(tempPath);
              moveLength = tempPath.getTotalLength();
              svgRoot.removeChild(tempPath);
            } else {
              const lineEl = frameLines[lineIndex];
              if (lineEl) {
                setPathD(lineEl, ln, fm.frame.players, frameLines, lineIndex);
                drawLength = lineEl.getTotalLength();
                moveLength = drawLength;
              }
            }

            const individualDrawTime = drawLength / drawSpeed;
            const individualMoveTime = moveLength / MOVEMENT_SPEED_PX_PER_MS;

            if (individualDrawTime > maxDrawTime) maxDrawTime = individualDrawTime;
            if (individualMoveTime > maxMoveTime) maxMoveTime = individualMoveTime;

            groupLineIndex += isDoubleZigzag ? 2 : 1;
          });

          const drawTimeMs = maxDrawTime;
          const markerTimeMs = MARKER_DELAY_SECONDS * 1000;
          const moveTimeMs = maxMoveTime;
          const groupTimeMs = drawTimeMs + markerTimeMs + moveTimeMs;

          g.drawTimeMs = drawTimeMs;
          g.markerTimeMs = markerTimeMs;
          g.moveTimeMs = moveTimeMs;
          g.groupTimeMs = groupTimeMs;

          frameTime += groupTimeMs;
          lineIndexOffset = groupLineIndex;
        });

        fm.frameTimeMs = frameTime;
        totalDuration += frameTime;
        cumulativeFrameDurations.push(totalDuration);
      }

      totalDuration += END_PAUSE_MS;
      cumulativeFrameDurations[cumulativeFrameDurations.length - 1] = totalDuration;
    }

    function getLineColor(ln) {
      const fromPlayerEl = players[ln.fromId];
      return (fromPlayerEl && fromPlayerEl.getAttribute("data-is-defender") === "true") ? "red" : "black";
    }

    function drawLineSegment(lineEl, ln, partialMs, totalDrawMs, frameLines, baseIndex) {
      lineEl.setAttribute("stroke", getLineColor(ln));

      if (ln.type === "zigzag" && ln.midX !== undefined && ln.midY !== undefined) {
        const lineElPart1 = frameLines[baseIndex];
        const lineElPart2 = frameLines[baseIndex + 1];
        if (!lineElPart1 || !lineElPart2) return;

        lineElPart1.setAttribute("stroke", getLineColor(ln));
        lineElPart2.setAttribute("stroke", getLineColor(ln));

        const length1 = lineElPart1.getTotalLength();
        const length2 = lineElPart2.getTotalLength();
        const totalLength = length1 + length2;

        const minDrawTimeMs = totalLength / ZIGZAG_DRAW_SPEED_PX_PER_MS;
        const effectiveDrawTimeMs = Math.max(totalDrawMs, minDrawTimeMs);

        const drawTimePart1 = (length1 / totalLength) * effectiveDrawTimeMs;
        const drawTimePart2 = (length2 / totalLength) * effectiveDrawTimeMs;

        if (partialMs <= drawTimePart1) {
          const frac1 = partialMs / drawTimePart1;
          const pxDrawn1 = length1 * Math.min(frac1, 1);
          lineElPart1.style.display = "";
          lineElPart1.setAttribute("stroke-dasharray", length1);
          lineElPart1.setAttribute("stroke-dashoffset", length1 - pxDrawn1);
          lineElPart2.style.display = "none";
          removeEndElement(ln.id);
        } else {
          lineElPart1.style.display = "";
          lineElPart1.setAttribute("stroke-dasharray", length1);
          lineElPart1.setAttribute("stroke-dashoffset", 0);

          const timeIntoPart2 = partialMs - drawTimePart1;
          const frac2 = timeIntoPart2 / drawTimePart2;
          const pxDrawn2 = length2 * Math.min(frac2, 1);
          lineElPart2.style.display = "";
          lineElPart2.setAttribute("stroke-dasharray", length2);
          lineElPart2.setAttribute("stroke-dashoffset", length2 - pxDrawn2);

          if (frac2 >= 1) {
            fullyDrawLine(lineElPart2, ln, frameLines, baseIndex);
          } else {
            removeEndElement(ln.id);
          }
        }
      } else {
        const length = lineEl.getTotalLength();
        const frac = totalDrawMs > 0 ? partialMs / totalDrawMs : 1;
        const pxDrawn = length * frac;
        if (pxDrawn <= 0) {
          lineEl.style.display = "none";
        } else if (pxDrawn >= length) {
          fullyDrawLine(lineEl, ln, frameLines, baseIndex);
        } else {
          lineEl.style.display = "";
          lineEl.setAttribute("stroke-dasharray", length);
          lineEl.setAttribute("stroke-dashoffset", length - pxDrawn);
          removeEndElement(ln.id);
        }
      }
    }

    function fullyDrawLine(lineEl, ln, frameLines, baseIndex) {
      if (ln.type === "zigzag" && ln.midX !== undefined && ln.midY !== undefined) {
        const lineElPart1 = frameLines[baseIndex];
        const lineElPart2 = frameLines[baseIndex + 1];
        if (lineElPart1) {
          lineElPart1.style.display = "";
          lineElPart1.setAttribute("stroke-dasharray", lineElPart1.getTotalLength());
          lineElPart1.setAttribute("stroke-dashoffset", 0);
          lineElPart1.setAttribute("stroke", getLineColor(ln));
        }
        if (lineElPart2) {
          lineElPart2.style.display = "";
          lineElPart2.setAttribute("stroke-dasharray", lineElPart2.getTotalLength());
          lineElPart2.setAttribute("stroke-dashoffset", 0);
          lineElPart2.setAttribute("stroke", getLineColor(ln));
          ensureEndElement(ln, lineElPart2);
        }
      } else {
        lineEl.style.display = "";
        const length = lineEl.getTotalLength();
        lineEl.setAttribute("stroke-dasharray", length);
        lineEl.setAttribute("stroke-dashoffset", 0);
        if (ln.type === "dashed" || ln.type === "shot") {
          lineEl.setAttribute("stroke-dasharray", "10,10");
        }
        lineEl.setAttribute("stroke", getLineColor(ln));
        ensureEndElement(ln, lineEl);
      }
    }

    function animateBallAlongPath(pathEl, ln, fraction, fromP, toP) {
      const offsetD = pathEl.getAttribute("data-offset-d");
      const fullD = pathEl.getAttribute("data-full-d");
      if (!fullD) return;

      pathEl.setAttribute("d", fullD);
      const len = pathEl.getTotalLength();
      const point = pathEl.getPointAtLength(len * fraction);
      pathEl.setAttribute("d", offsetD);

      if (!movingElements[ln.id]) {
        const ball = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        ball.setAttribute("r", "30");
        ball.setAttribute("stroke", "black");
        ball.setAttribute("stroke-width", "4");
        ball.setAttribute("fill", "none");
        ball.setAttribute("stroke-linecap", "round");
        ball.setAttribute("stroke-linejoin", "round");
        svgRoot.appendChild(ball);
        movingElements[ln.id] = ball;
      }
      movingElements[ln.id].setAttribute("cx", point.x);
      movingElements[ln.id].setAttribute("cy", point.y);

      if (fraction >= 1 && toP) {
        removeMovingElement(ln.id);
        toggleBall(players[toP.id], true);
      }
    }

    function animatePlayerWithBall(pathEl, ln, fraction, fromP, combinedPaths) {
      const offsetD = pathEl.getAttribute("data-offset-d");
      const fullD = pathEl.getAttribute("data-full-d");
      if (!fullD) return;

      let smoothPathEl;
      if (ln.type === "zigzag" && ln.midX !== undefined && ln.midY !== undefined) {
        const fromX = ln.fromX * SCALE_FACTOR;
        const fromY = ln.fromY * SCALE_FACTOR;
        const ctrlX = ln.controlX * SCALE_FACTOR;
        const ctrlY = ln.controlY * SCALE_FACTOR;
        const midX = ln.midX * SCALE_FACTOR;
        const midY = ln.midY * SCALE_FACTOR;
        const ctrlX2 = ln.controlX2 * SCALE_FACTOR;
        const ctrlY2 = ln.controlY2 * SCALE_FACTOR;
        const toX = ln.toX * SCALE_FACTOR;
        const toY = ln.toY * SCALE_FACTOR;
        smoothPathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
        smoothPathEl.setAttribute("d", \`M \${fromX} \${fromY} Q \${ctrlX} \${ctrlY} \${midX} \${midY} Q \${ctrlX2} \${ctrlY2} \${toX} \${toY}\`);
      } else {
        smoothPathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
        smoothPathEl.setAttribute("d", fullD);
      }

      const playerId = fromP.id;
      if (combinedPaths && playerId && combinedPaths[playerId]) {
        smoothPathEl.setAttribute("d", combinedPaths[playerId]);
      }

      svgRoot.appendChild(smoothPathEl);
      const len = smoothPathEl.getTotalLength();
      const point = smoothPathEl.getPointAtLength(len * fraction);
      svgRoot.removeChild(smoothPathEl);

      const playerEl = players[fromP.id];
      if (playerEl) {
        setPlayerPos(playerEl, point.x, point.y);
        toggleBall(playerEl, true);
        currentPositions[fromP.id] = { x: point.x, y: point.y };
      }
    }

    function animateAlongPath(pathEl, ln, fraction, fromP, combinedPaths) {
      const offsetD = pathEl.getAttribute("data-offset-d");
      const fullD = pathEl.getAttribute("data-full-d");
      if (!fullD) return;

      let pathToUse = fullD;
      const playerId = fromP.id;
      if (combinedPaths && playerId && combinedPaths[playerId]) {
        pathToUse = combinedPaths[playerId];
      }

      pathEl.setAttribute("d", pathToUse);
      const len = pathEl.getTotalLength();
      const point = pathEl.getPointAtLength(len * fraction);
      pathEl.setAttribute("d", offsetD);

      const playerEl = players[fromP.id];
      if (playerEl) {
        setPlayerPos(playerEl, point.x, point.y);
        currentPositions[fromP.id] = { x: point.x, y: point.y };
      }
    }

    function animateShotAlongPath(pathEl, ln, fraction, fromP) {
      const offsetD = pathEl.getAttribute("data-offset-d");
      const fullD = pathEl.getAttribute("data-full-d");
      if (!fullD) return;

      pathEl.setAttribute("d", fullD);
      const len = pathEl.getTotalLength();
      const point = pathEl.getPointAtLength(len * fraction);
      pathEl.setAttribute("d", offsetD);

      if (!movingElements[ln.id]) {
        const shotCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        shotCircle.setAttribute("r", "16");
        shotCircle.setAttribute("stroke", getLineColor(ln));
        shotCircle.setAttribute("stroke-width", "4");
        shotCircle.setAttribute("fill", "none");
        svgRoot.appendChild(shotCircle);
        movingElements[ln.id] = shotCircle;
      }
      movingElements[ln.id].setAttribute("cx", point.x);
      movingElements[ln.id].setAttribute("cy", point.y);

      if (fraction >= 1) {
        removeMovingElement(ln.id);
      }
    }

    function setPathD(lineEl, ln, framePlayers, frameLines, baseIndex) {
      const pMap = {};
      framePlayers.forEach(p => { pMap[p.id] = p; });

      const fromP = pMap[ln.fromId];
      if (!fromP) return;

      const fromPos = currentPositions[ln.fromId] || { x: ln.fromX * SCALE_FACTOR, y: ln.fromY * SCALE_FACTOR };
      const fromFull = { x: fromPos.x, y: fromPos.y };
      const toFull = { x: ln.toX * SCALE_FACTOR, y: ln.toY * SCALE_FACTOR };
      const controlPoint = { x: ln.controlX * SCALE_FACTOR, y: ln.controlY * SCALE_FACTOR };
      const OFFSET_RADIUS = 30;

      let fromOffset = OFFSET_RADIUS;
      let toOffset = OFFSET_RADIUS;

      if (ln.type === "bar" || ln.type === "straight") {
        fromOffset = 20;
      }

      if (ln.type === "dashed") {
        toOffset = 56;
      }

      let dValueFull = "";
      let dValueOffset = "";

      if (ln.midX !== undefined && ln.midY !== undefined && ln.controlX2 !== undefined && ln.controlY2 !== undefined) {
        const midFull = { x: ln.midX * SCALE_FACTOR, y: ln.midY * SCALE_FACTOR };
        const controlPoint1 = { x: ln.controlX * SCALE_FACTOR, y: ln.controlY * SCALE_FACTOR };
        const controlPoint2 = { x: ln.controlX2 * SCALE_FACTOR, y: ln.controlY2 * SCALE_FACTOR };

        const startOffset = getOffsetPoint(fromFull.x, fromFull.y, controlPoint1.x, controlPoint1.y, fromOffset);
        const endOffset = getOffsetPoint(toFull.x, toFull.y, controlPoint2.x, controlPoint2.y, toOffset);

        if (ln.type === "zigzag") {
          const lineElPart1 = frameLines[baseIndex];
          const lineElPart2 = frameLines[baseIndex + 1];

          const length1 = approximateBezierLength(startOffset, controlPoint1, midFull);
          const length2 = approximateBezierLength(midFull, controlPoint2, endOffset);
          const totalLength = length1 + length2;
          const totalOsc = Math.max(2, Math.round((totalLength / 100) * 8));
          const freq = (totalOsc * Math.PI) / totalLength;

          const zigzag1 = createZigzagPath(startOffset, controlPoint1, midFull, 7, freq, 0);
          const zigzag2 = createZigzagPath(midFull, controlPoint2, endOffset, 7, freq, length1, true);

          dValueFull = "M " + fromFull.x + " " + fromFull.y + " Q " + controlPoint1.x + " " + controlPoint1.y + " " + midFull.x + " " + midFull.y + " Q " + controlPoint2.x + " " + controlPoint2.y + " " + toFull.x + " " + toFull.y;
          lineElPart1.setAttribute("d", zigzag1.d);
          lineElPart2.setAttribute("d", zigzag2.d);
          lineElPart1.setAttribute("data-full-d", "M " + fromFull.x + " " + fromFull.y + " Q " + controlPoint1.x + " " + controlPoint1.y + " " + midFull.x + " " + midFull.y);
          lineElPart2.setAttribute("data-full-d", "M " + midFull.x + " " + midFull.y + " Q " + controlPoint2.x + " " + controlPoint2.y + " " + toFull.x + " " + toFull.y);
          lineElPart1.setAttribute("data-offset-d", zigzag1.d);
          lineElPart2.setAttribute("data-offset-d", zigzag2.d);
          lineElPart1.setAttribute("data-zigzag-length", zigzag1.totalLength.toString());
          lineElPart2.setAttribute("data-zigzag-length", zigzag2.totalLength.toString());
        } else {
          dValueFull = "M " + fromFull.x + " " + fromFull.y + " Q " + controlPoint1.x + " " + controlPoint1.y + " " + midFull.x + " " + midFull.y + " Q " + controlPoint2.x + " " + controlPoint2.y + " " + toFull.x + " " + toFull.y;
          dValueOffset = "M " + startOffset.x + " " + startOffset.y + " Q " + controlPoint1.x + " " + controlPoint1.y + " " + midFull.x + " " + midFull.y + " Q " + controlPoint2.x + " " + controlPoint2.y + " " + endOffset.x + " " + endOffset.y;
          lineEl.setAttribute("d", dValueOffset);
          lineEl.setAttribute("data-full-d", dValueFull);
          lineEl.setAttribute("data-offset-d", dValueOffset);
        }
      } else {
        const startOffset = getOffsetPoint(fromFull.x, fromFull.y, controlPoint.x, controlPoint.y, fromOffset);
        const endOffset = getOffsetPoint(toFull.x, toFull.y, controlPoint.x, controlPoint.y, toOffset);

        if (ln.type === "zigzag") {
          const length = approximateBezierLength(startOffset, controlPoint, endOffset);
          const totalOsc = Math.max(2, Math.round((length / 100) * 8));
          const freq = (totalOsc * Math.PI) / length;
          const zigzagPath = createZigzagPath(startOffset, controlPoint, endOffset, 7, freq, 0, true);
          dValueOffset = zigzagPath.d;
          dValueFull = "M " + fromFull.x + " " + fromFull.y + " Q " + Math.round(controlPoint.x) + " " + Math.round(controlPoint.y) + " " + Math.round(toFull.x) + " " + Math.round(toFull.y);
          lineEl.setAttribute("d", dValueOffset);
          lineEl.setAttribute("data-full-d", dValueFull);
          lineEl.setAttribute("data-offset-d", dValueOffset);
        } else {
          dValueFull = "M " + Math.round(fromFull.x) + " " + Math.round(fromFull.y) + " Q " + Math.round(controlPoint.x) + " " + Math.round(controlPoint.y) + " " + Math.round(toFull.x) + " " + Math.round(toFull.y);
          dValueOffset = "M " + Math.round(startOffset.x) + " " + Math.round(startOffset.y) + " Q " + Math.round(controlPoint.x) + " " + Math.round(controlPoint.y) + " " + Math.round(endOffset.x) + " " + Math.round(endOffset.y);
          lineEl.setAttribute("d", dValueOffset);
          lineEl.setAttribute("data-full-d", dValueFull);
          lineEl.setAttribute("data-offset-d", dValueOffset);
        }
      }
    }

    function approximateBezierLength(from, ctrl, to) {
      let length = 0, steps = 20, prev = from;
      for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2*to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2*to.y;
        length += Math.hypot(x - prev.x, y - prev.y);
        prev = { x, y };
      }
      return length;
    }

    function createZigzagPath(from, ctrl, to, amp=7, freq, cumulativeOffset=0, isSecondHalf=false) {
      const steps = 1000;
      const dt = 1 / steps;
      const path = [];
      let totalLen = 0;
      let prevX = from.x, prevY = from.y;
      const cumsum = [0];

      for (let i = 0; i <= steps; i++) {
        const t = i * dt;
        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2 * to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2 * to.y;
        if (i > 0) totalLen += Math.hypot(x - prevX, y - prevY);
        cumsum.push(totalLen);
        prevX = x;
        prevY = y;
      }

      prevX = from.x;
      prevY = from.y;
      path.push("M " + Math.round(from.x) + " " + Math.round(from.y));
      for (let i = 1; i <= steps; i++) {
        const t = i * dt;
        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2 * to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2 * to.y;
        const dxdt = 2*(1 - t)*(ctrl.x - from.x) + 2*t*(to.x - ctrl.x);
        const dydt = 2*(1 - t)*(ctrl.y - from.y) + 2*t*(to.y - ctrl.y);
        const segLen = Math.hypot(dxdt, dydt) || 1;
        const nx = -dydt/segLen, ny = dxdt/segLen;
        const s = cumsum[i];
        const off = amp * Math.sin((cumulativeOffset + s) * freq);

        path.push("L " + Math.round(x + off*nx) + " " + Math.round(y + off*ny));
      }

      if (isSecondHalf) {
        const theta = Math.atan2(to.y - ctrl.y, to.x - ctrl.x);
        path.push("L " + Math.round(to.x + 20*Math.cos(theta)) + " " + Math.round(to.y + 20*Math.sin(theta)));
      }

      return {
        d: path.join(' '),
        totalLength: totalLen
      };
    }

    function getOffsetPoint(x1, y1, x2, y2, offset) {
      const dx = x2 - x1, dy = y2 - y1;
      const dist = Math.hypot(dx, dy);
      if (!dist) return { x: x1, y: y1 };
      return {
        x: x1 + (dx * offset / dist),
        y: y1 + (dy * offset / dist),
      };
    }

    function setPlayerPos(playerEl, x, y) {
      const txt = playerEl.querySelector("text");
      if (txt) {
        txt.setAttribute("x", x);
        txt.setAttribute("y", y);
      }
      const c = playerEl.querySelector('circle[r="30"]');
      if (c) {
        c.setAttribute("cx", x);
        c.setAttribute("cy", y);
      }
    }

    function toggleBall(playerEl, hasBall) {
      const existing = playerEl.querySelector('circle[r="30"]');
      if (hasBall && !existing) {
        const c = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        const txt = playerEl.querySelector("text");
        c.setAttribute("cx", txt?.getAttribute("x") || "0");
        c.setAttribute("cy", txt?.getAttribute("y") || "0");
        c.setAttribute("r", "30");
        c.setAttribute("stroke", "black");
        c.setAttribute("stroke-width", "4");
        c.setAttribute("fill", "none");
        c.setAttribute("stroke-linecap", "round");
        c.setAttribute("stroke-linejoin", "round");
        playerEl.insertBefore(c, txt);
      } else if (!hasBall && existing) {
        playerEl.removeChild(existing);
      }
    }

    function removePlayerBall(playerId) {
      const pe = players[playerId];
      if (!pe) return;
      const ballCircle = pe.querySelector('circle[r="30"]');
      if (ballCircle) pe.removeChild(ballCircle);
    }

    function ensureEndElement(ln, lineEl) {
      if (!endElements[ln.id]) {
        const fromPlayerEl = players[ln.fromId];
        const isDefender = fromPlayerEl ? (fromPlayerEl.getAttribute("data-is-defender") === "true") : false;
        const color = isDefender ? "red" : "black";

        if (ln.type === "shot") {
          const len = lineEl.getTotalLength();
          if (len < 2) return null;
          const endPoint = lineEl.getPointAtLength(len);
          const nearEndPoint = lineEl.getPointAtLength(Math.max(len - 20, 0));
          const angle = Math.atan2(endPoint.y - nearEndPoint.y, endPoint.x - nearEndPoint.x);
          const EXTEND_DISTANCE = 20;
          const extendedPoint = {
            x: endPoint.x + EXTEND_DISTANCE * Math.cos(angle),
            y: endPoint.y + EXTEND_DISTANCE * Math.sin(angle),
          };

          const shotCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          shotCircle.setAttribute("cx", extendedPoint.x);
          shotCircle.setAttribute("cy", extendedPoint.y);
          shotCircle.setAttribute("r", "16");
          shotCircle.setAttribute("stroke", color);
          shotCircle.setAttribute("stroke-width", "4");
          shotCircle.setAttribute("fill", "none");
          endElements[ln.id] = shotCircle;
        } else if (ln.type === "bar") {
          endElements[ln.id] = createBarEl(lineEl, color);
        } else {
          endElements[ln.id] = createArrowEl(lineEl, color);
        }
        if (endElements[ln.id]) svgRoot.appendChild(endElements[ln.id]);
      }
    }

    function removeEndElement(lineId) {
      if (endElements[lineId]) {
        endElements[lineId].remove?.();
        delete endElements[lineId];
      }
    }

    function removeMovingElement(lineId) {
      if (movingElements[lineId]) {
        movingElements[lineId].remove?.();
        delete movingElements[lineId];
      }
    }

    function createArrowEl(lineEl, color) {
      const len = lineEl.getTotalLength();
      if (len < 2) return null;
      const endPoint = lineEl.getPointAtLength(len);
      const startPoint = lineEl.getPointAtLength(Math.max(len - 20, 0));
      const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

      const arrowLength = 36;
      const arrowWidth = Math.PI / 9;
      const baseHeight = -10;
      const offsetLength = -24;

      const adjustedEndPoint = {
        x: endPoint.x - offsetLength * Math.cos(angle),
        y: endPoint.y - offsetLength * Math.sin(angle),
      };

      const arrowPoint1 = {
        x: adjustedEndPoint.x - arrowLength * Math.cos(angle - arrowWidth),
        y: adjustedEndPoint.y - arrowLength * Math.sin(angle - arrowWidth),
      };
      const arrowPoint2 = {
        x: adjustedEndPoint.x - arrowLength * Math.cos(angle + arrowWidth),
        y: adjustedEndPoint.y - arrowLength * Math.sin(angle + arrowWidth),
      };

      const baseMiddle = {
        x: (arrowPoint1.x + arrowPoint2.x) / 2 - baseHeight * Math.cos(angle),
        y: (arrowPoint1.y + arrowPoint2.y) / 2 - baseHeight * Math.sin(angle),
      };

      const arrowPath = "M " + adjustedEndPoint.x + " " + adjustedEndPoint.y + " L " + arrowPoint1.x + " " + arrowPoint1.y + " L " + baseMiddle.x + " " + baseMiddle.y + " L " + arrowPoint2.x + " " + arrowPoint2.y + " Z";
      const pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathEl.setAttribute("d", arrowPath);
      pathEl.setAttribute("stroke", color);
      pathEl.setAttribute("stroke-width", "4");
      pathEl.setAttribute("fill", color);
      return pathEl;
    }

    function createBarEl(lineEl, color, barWidth = 60, barLength = 80, offsetLength = 40) {
      const len = lineEl.getTotalLength();
      if (len < 2) return null;
      const endPoint = lineEl.getPointAtLength(len);
      const startPoint = lineEl.getPointAtLength(Math.max(len - 20, 0));
      const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

      const adjustedEndPoint = {
        x: endPoint.x + offsetLength * Math.cos(angle),
        y: endPoint.y + offsetLength * Math.sin(angle),
      };

      const barCenter = {
        x: adjustedEndPoint.x - (barLength / 2) * Math.cos(angle),
        y: adjustedEndPoint.y - (barLength / 2) * Math.sin(angle),
      };

      const barEnd1 = {
        x: barCenter.x + (barWidth / 2) * Math.cos(angle + Math.PI / 2),
        y: barCenter.y + (barWidth / 2) * Math.sin(angle + Math.PI / 2),
      };
      const barEnd2 = {
        x: barCenter.x - (barWidth / 2) * Math.cos(angle + Math.PI / 2),
        y: barCenter.y - (barWidth / 2) * Math.sin(angle + Math.PI / 2),
      };

      const barPath = "M " + barEnd1.x + " " + barEnd1.y + " L " + barEnd2.x + " " + barEnd2.y;
      const pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathEl.setAttribute("d", barPath);
      pathEl.setAttribute("stroke", color);
      pathEl.setAttribute("stroke-width", "4");
      pathEl.setAttribute("fill", "none");
      pathEl.setAttribute("stroke-linecap", "round");
      pathEl.setAttribute("stroke-linejoin", "round");
      return pathEl;
    }

    function resetAll() {
      Object.values(players).forEach(pe => {
        pe.style.display = "none";
      });
      document.querySelectorAll(".frame-lines path").forEach(le => {
        le.style.display = "none";
        le.removeAttribute("stroke-dasharray");
        le.removeAttribute("stroke-dashoffset");
      });
      Object.values(cones).forEach(ce => {
        ce.style.display = "none";
      });
      Object.values(movingElements).forEach(e => e?.remove?.());
      for (let k in movingElements) delete movingElements[k];
      Object.values(endElements).forEach(el => el?.remove?.());
      for (let k in endElements) delete endElements[k];
    }
  `;

  return script;
};

// FINAL HTML GENERATION
export default function PlayAnimationHTMLDoc(frames: Frame[], courtType: string, play: DrupalEntity, native: boolean, autoDownload: boolean = false) {
  let downloadButtonStyle = 'normal';
  if (native)
    downloadButtonStyle = 'none';
  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8" />
  <title>SVG Animation - High Quality</title>
  <style>
    body {
      margin: 0;
      padding: 0;
      font-family: sans-serif;
    }
    #controls {
      text-align: center;
      margin: 20px 0;
    }
    #play-pause, #download-video {
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;
      background-color: #fff;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      margin: 0 5px;
      transition: all 0.2s;
    }

    #play-pause:hover:not(:disabled), #download-video:hover:not(:disabled) {
      background-color: #f0f0f0;
      border-color: #999;
    }
    #play-pause:disabled, #download-video:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    #progress-bar {
      width: 90%;
      margin-top: 12px;
      height: 8px;
      border-radius: 4px;
    }
    svg {
      display: block;
      margin: 0 auto;
      background-color: #fff;
      border: 2px solid #ccc;
      border-radius: 6px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
  </style>
</head>
<body>
  ${generateSVGContent(frames, courtType)}

  <div id="controls">
    <button id="play-pause">▶</button>
    <button id="download-video" style="display: ${downloadButtonStyle};">${i18next.t('PlayAnimationHTMLDoc00')}</button>
    <input type="range" id="progress-bar" min="0" max="1000" value="0" />
  </div>

  <script>
    ${generateAnimationScript(frames, play, autoDownload)}
  </script>

</body>
</html>
  `;
}