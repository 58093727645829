import { Line, Player } from './DialogPlayBuilder';
import { Typography, Box, IconButton, Tooltip, Button } from '@mui/material';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMemo, useCallback, useState, useContext, Fragment } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import FeedbackIcon from '@mui/icons-material/Feedback';

interface Props {
    lines: Line[];
    players: Player[]; // Add players array to props
    onUpdateLines: (lines: Line[]) => void;
}

export default function PlayAnimationLinesOrder({
    lines,
    players,
    onUpdateLines,
}: Props) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const [showTimeline, setShowTimeline] = useState(!state.portrait);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        color: '#00398F',
    });

    const toggleShowTimeline = () => setShowTimeline((prev) => !prev);

    const showSnackbar = (message: string) => {
        setSnackbar({ open: true, message, color: '#00398F' });
        setTimeout(() => setSnackbar((s) => ({ ...s, open: false })), 3000);
    };

    const toggleConcurrentGroup = useCallback(
        (line: Line) => {
            const sortedLines = [...lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
            const currentIndex = sortedLines.findIndex((l) => l.id === line.id);

            // If toggling off concurrency, proceed without checks
            if (line.sameMomentAsPrevious) {
                const updatedLines = lines.map((l) =>
                    l.id === line.id ? { ...l, sameMomentAsPrevious: undefined } : l
                );
                onUpdateLines(updatedLines);
                return;
            }

            // If toggling on concurrency, check for conflicts
            if (currentIndex > 0) {
                const prevLine = sortedLines[currentIndex - 1];
                // Check if the previous line is from the same player
                if (prevLine.fromId === line.fromId) {
                    showSnackbar(t('PlayAnimationLinesOrder06', { playerId: line.fromId }));
                    return;
                }

                // Check the entire group of concurrent lines before this line
                let groupStartIndex = currentIndex - 1;
                while (
                    groupStartIndex > 0 &&
                    sortedLines[groupStartIndex].sameMomentAsPrevious
                ) {
                    groupStartIndex--;
                }
                const concurrentGroup = sortedLines.slice(groupStartIndex, currentIndex);
                const hasSamePlayer = concurrentGroup.some((l) => l.fromId === line.fromId);

                if (hasSamePlayer) {
                    showSnackbar(t('PlayAnimationLinesOrder06', { playerId: line.fromId }));
                    return;
                }
            }

            // If no conflicts, toggle concurrency on
            const updatedLines = lines.map((l) =>
                l.id === line.id ? { ...l, sameMomentAsPrevious: true } : l
            );
            onUpdateLines(updatedLines);
        },
        [lines, onUpdateLines, t]
    );

    const movementsText: Record<string, string> = {
        dashed: t('PlayAnimationLinesOrder00'),
        straight: t('PlayAnimationLinesOrder01'),
        zigzag: t('PlayAnimationLinesOrder02'),
        bar: t('PlayAnimationLinesOrder03'),
        shot: t('PlayAnimationLinesOrder07'),
    };

    const groupColors = ['#fff3e0', '#fffde7'];

    const concurrentLineColors = useMemo(() => {
        const sorted = [...lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        const colorMap = new Map<number | string, string>();
        let currentColor: string | null = null;
        let colorIndex = 0;

        sorted.forEach((line, index) => {
            if (line.sameMomentAsPrevious && index > 0) {
                if (!currentColor) {
                    currentColor = groupColors[colorIndex % groupColors.length];
                    colorIndex++;
                }
                const prevLine = sorted[index - 1];
                colorMap.set(prevLine.id, currentColor);
                colorMap.set(line.id, currentColor);
            } else {
                currentColor = null;
            }
        });

        return colorMap;
    }, [lines]);

    const moveLine = useCallback(
        (index: number, direction: 'up' | 'down') => {
            const sorted = [...lines].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
            const targetIndex = direction === 'up' ? index - 1 : index + 1;

            if (targetIndex < 0 || targetIndex >= sorted.length) return;

            const reordered = [...sorted];
            const [moved] = reordered.splice(index, 1);
            reordered.splice(targetIndex, 0, moved);

            // Assign new order values
            let updated = reordered.map((line, idx) => ({
                ...line,
                order: idx + 1,
            }));

            // Check for concurrency conflicts after reordering and untoggle if necessary
            for (let i = 1; i < updated.length; i++) {
                const currentLine = updated[i];
                const prevLine = updated[i - 1];

                if (
                    currentLine.sameMomentAsPrevious &&
                    currentLine.fromId === prevLine.fromId
                ) {
                    updated[i] = { ...currentLine, sameMomentAsPrevious: undefined };
                }
            }

            // Additional check around the moved line's new position
            if (direction === 'up' && targetIndex > 0) {
                const prevLine = updated[targetIndex - 1];
                const movedLine = updated[targetIndex];
                if (
                    movedLine.sameMomentAsPrevious &&
                    movedLine.fromId === prevLine.fromId
                ) {
                    updated[targetIndex] = { ...movedLine, sameMomentAsPrevious: undefined };
                }
            } else if (direction === 'down' && targetIndex < updated.length - 1) {
                const movedLine = updated[targetIndex];
                const nextLine = updated[targetIndex + 1];
                if (
                    nextLine.sameMomentAsPrevious &&
                    nextLine.fromId === movedLine.fromId
                ) {
                    updated[targetIndex + 1] = { ...nextLine, sameMomentAsPrevious: undefined };
                }
            }

            onUpdateLines(updated);
        },
        [lines, onUpdateLines]
    );

    return (
        <Fragment>
            <Button
                fullWidth
                color="success"
                variant="text"
                onClick={toggleShowTimeline}
                sx={{
                    textTransform: 'none',
                    fontWeight: 'medium',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                }}
            >
                {t('PlayAnimationLinesOrder04')}
                <KeyboardArrowDownRoundedIcon
                    sx={{
                        transform: showTimeline ? 'rotate(0deg)' : 'rotate(180deg)',
                        transition: 'transform 0.2s ease-in-out',
                    }}
                />
            </Button>

            {showTimeline && (
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        padding: '12px',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                        border: '1px solid #e0e0e0',
                        marginTop: '8px',
                    }}
                >
                    {lines
                        .slice()
                        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                        .map((line, index) => {
                            const player = players.find((p) => p.id === line.fromId);
                            const displayNumber = player
                                ? (player.isDefender ? `X${player.number}` : player.number)
                                : line.fromId; // Fallback to fromId if player not found

                            return (
                                <Box
                                    key={line.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px 12px',
                                        backgroundColor: concurrentLineColors.has(line.id)
                                            ? concurrentLineColors.get(line.id)
                                            : '#fafafa',
                                        borderRadius: '8px',
                                        border: '1px solid #eee',
                                        marginBottom: index < lines.length - 1 ? '8px' : 0,
                                        transition: 'background-color 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: concurrentLineColors.has(line.id)
                                                ? concurrentLineColors.get(line.id)
                                                : '#f0f0f0',
                                        },
                                    }}
                                >
                                    <Tooltip title={t('PlayAnimationLinesOrder05')} enterDelay={1000}>
                                        <IconButton
                                            onClick={() => toggleConcurrentGroup(line)}
                                            size="small"
                                            sx={{
                                                color: line.sameMomentAsPrevious ? '#f57c00' : '#757575',
                                                padding: '4px',
                                            }}
                                        >
                                            <RestoreRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: '#424242',
                                            flexGrow: 1,
                                            marginLeft: '8px',
                                        }}
                                    >
                                        {movementsText[line.type] || line.type.toUpperCase()} {displayNumber}
                                    </Typography>

                                    <Box sx={{ display: 'flex', gap: '4px' }}>
                                        {index > 0 && (
                                            <Tooltip title={t('CRUDList01')} enterDelay={1000}>
                                                <IconButton
                                                    onClick={() => moveLine(index, 'up')}
                                                    size="small"
                                                    sx={{
                                                        color: '#90a4ae',
                                                        padding: '4px',
                                                        '&:hover': { color: '#546e7a' },
                                                    }}
                                                >
                                                    <ArrowUpwardIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {index < lines.length - 1 && (
                                            <Tooltip title={t('CRUDList02')} enterDelay={1000}>
                                                <IconButton
                                                    onClick={() => moveLine(index, 'down')}
                                                    size="small"
                                                    sx={{
                                                        color: '#90a4ae',
                                                        padding: '4px',
                                                        '&:hover': { color: '#546e7a' },
                                                    }}
                                                >
                                                    <ArrowDownwardIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            )}

            <Snackbar
                open={snackbar.open}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FeedbackIcon sx={{ mr: 1, fontSize: '18px' }} />
                        {snackbar.message}
                    </Box>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: snackbar.color,
                        color: '#fff',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    },
                }}
            />
        </Fragment>
    );
}