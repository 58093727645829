import { Fragment } from "react/jsx-runtime";
import DialogGetSingleLineOfText from "./DialogGetSingleLineOfText";
import { useContext, useEffect, useState } from "react";
import { getActionSetConfirm, getConfigValue, getDD, setUISetting, validateEmail } from "../misc/Functions";
import log from "../misc/Logger";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";
import { useTranslation } from "react-i18next";
import { BACKEND } from "../misc/Constants";
import { Button, IconButton, Box, Dialog, DialogContent, DialogTitle, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";


interface PropsDialogPromotionMar2025 {
    open: boolean,  // start state of dialog
}

export default function DialogPromotionMar2025(props: PropsDialogPromotionMar2025) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    log.debug('DialogPromotionMar2025');

    const [open, setOpen] = useState(props.open) // when called from the menu we should start with dialog open
    const [infoOpen, setInfoOpen] = useState(false); // New state for info dialog
    useEffect(() => {
        if (!state.user.data.id)
            return
    }, [state.user])

    function closeDialog() {
        setOpen(false)
        if (location.pathname === '/promotionmar25')
            navigate(-1) // called from the menu
    }

    // Close dialog to get single email address
    async function onCloseDialogGetSingleLineOfText(ok: boolean, text: string) {
        if (ok) {
            // check valid invitee email address
            if (!validateEmail(text)) {
                dispatch(getActionSetConfirm(t('PromotionMar2025_01')));
                return
            }
            // check we dont have that email adready - your cant invite somebody who is already a user
            const resp = await getDD(state, dispatch, `${BACKEND}/icb-user/email_exists/${encodeURIComponent(text)}`)
            if (resp.ok) {
                dispatch(getActionSetConfirm(t('PromotionMar2025_02')));
                return
            }
            // start the promotion - start Brevo flow. Note, case sensitive promotion name
            const respPromote = await getDD(state, dispatch, `${BACKEND}/icb-user/promotion_start`, 'POST', {
                promotion: 'PromotionMar25',
                invitee: text,
            })
            if (!respPromote.ok)
                return
            closeDialog() // close with invitation
            dispatch(getActionSetConfirm(t('PromotionMar2025_03') + text + t('PromotionMar2025_04')));
        } else
            closeDialog() // close with no invitation
    }

    // Early exit if PromotionMar25 not enabled
    if (!getConfigValue(state, "enablePromotionMar25"))
        return (
            <Fragment>

            </Fragment>
        )

    return (
        <Fragment>

            {
                location.pathname !== '/promotionmar25'
                && <Box sx={{ width: '100%', mt: 2, display: 'flex', alignItems: 'center' }}>

                    {/* Container for the main button to invite friends and the CloseIcon */}
                    <Box sx={{ position: 'relative', flexGrow: 1, mr: 1 }}>
                        <Button
                            onClick={() => setOpen(true)}
                            sx={{
                                background: 'linear-gradient(45deg, #00398F 0%, #245EBF 100%)',
                                border: 'none',
                                borderRadius: '6px',
                                color: '#fff',
                                padding: '8px 16px',
                                fontSize: { xs: '12px', sm: '13px' },
                                fontWeight: '600',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease',
                                width: '100%',
                            }}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                            <Box
                                sx={{
                                    maxWidth: '90%',
                                    mx: 'auto',
                                    textAlign: 'center',
                                    wordWrap: 'break-word',
                                    fontSize: 11.5,
                                }}
                            >
                                {t('PromotionMar2025_00')}
                            </Box>
                        </Button>

                        {/* Hide button PromotionMar25 that opens dialog to enter ICB invitee */}
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    getActionSetConfirm(t('PromotionMar2025_09'), t('Yes00'), () => {
                                        setUISetting(
                                            state,
                                            dispatch,
                                            'showButtonToOpenInvitePromotionMar25',
                                            !state.uiSettings.showButtonToOpenInvitePromotionMar25
                                        );
                                    })
                                );
                            }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#fff',
                            }}
                        >
                            <CloseIcon sx={{ fontSize: '16px', color: 'lightgrey' }} />
                        </IconButton>
                    </Box>

                    {/* More information button */}
                    <IconButton
                        onClick={() => setInfoOpen(true)} // Open info dialog
                        sx={{
                            background: 'linear-gradient(45deg, #245EBF 0%, #5288D9 100%)',
                            borderRadius: '6px',
                            color: '#fff',
                            padding: '6px',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s ease',
                            width: 38,
                            height: 38,
                            '&:hover': {
                                background: 'linear-gradient(45deg, #245EBF 0%, #5288D9 100%)',
                                transform: 'scale(1.02)',
                            },
                        }}
                    >
                        <InfoIcon sx={{ fontSize: { xs: '18px', sm: '20px' } }} />
                    </IconButton>
                </Box>
            }

            {/* Dialogbox to add new exercise group */}
            <DialogGetSingleLineOfText
                open={open}
                onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                title={t('PromotionMar2025_05')}
                contentLine1={t('PromotionMar2025_06')}
                checkBoxLabel={t('PromotionMar2025_10')}
                label={t('PromotionMar2025_07')}
                button={t('PromotionMar2025_08')}
            />


            {/* New Info Dialog */}
            <Dialog
                open={infoOpen}
                onClose={() => setInfoOpen(false)}
                PaperProps={{
                    sx: {
                        background: 'linear-gradient(45deg, #00398F, #245EBF)',
                        color: 'white',
                        borderRadius: 2,
                        boxShadow: 3,
                        maxWidth: 400,
                        width: '90%',
                    },
                }}
            >

                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pb: 1,
                    fontWeight: 'bold',
                }}>
                    {t('PromotionMar2025_11')}
                    <IconButton
                        size="small"
                        onClick={() => setInfoOpen(false)}
                        sx={{ color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>


                <DialogContent sx={{ px: 3, pb: 3, pt: 2 }}>
                    <Divider sx={{ background: 'white', mt: 1 }} />

                    <Typography variant="body2" sx={{ pt: 2, mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
                        {t('PromotionMar2025_12')}
                        <span style={{ fontWeight: 'bold', color: '#DD6F20' }}>
                            {t('PromotionMar2025_13')}
                        </span>{' '}
                        {t('PromotionMar2025_14')}
                    </Typography>
                    {/* <Divider sx={{ background: 'white' }} /> */}

                    <List dense sx={{ pl: 1 }}>
                        {[
                            t('PromotionMar2025_15'),
                            t('PromotionMar2025_16'),
                            t('PromotionMar2025_17'),
                            t('PromotionMar2025_18'),
                            t('PromotionMar2025_19'),
                            t('PromotionMar2025_20'),
                            t('PromotionMar2025_21'),
                        ].map((item, index) => (
                            <ListItem key={index} disableGutters sx={{ py: 0.1 }}>
                                <ListItemText
                                    primary={`- ${item}`}
                                    primaryTypographyProps={{
                                        variant: 'body2',
                                        color: 'white',
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Divider sx={{ background: 'white' }} />

                    <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                        {t('PromotionMar2025_22')}
                    </Typography>
                </DialogContent>
            </Dialog>

        </Fragment>
    )

}